import React from 'react';
import {Box, FormControlLabel,
  FormLabel, Radio, RadioGroup} from '@mui/material';

export const GenderRadio = () => {
  return (
    <Box>
      <FormLabel component="legend">Gender</FormLabel>
      <RadioGroup
        aria-label="gender"
        defaultValue="any"
        name="gender"
        row
      >
        <FormControlLabel value="any" control={<Radio />} label="Any" />
        <FormControlLabel
          value="men"
          control={<Radio />}
          label="Men-only"
        />
        <FormControlLabel
          value="women"
          control={<Radio />}
          label="Women-only"
        />
      </RadioGroup>
    </Box>
  );
};
