import * as React from 'react';
import {Container} from '@mui/material';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import axios from 'axios';
import {useCookies} from 'react-cookie';
import {LeaveGameConfirmation} from '../../components/game/LeaveGameConfirmation';
import {JoinGameConfirmation} from '../../components/game/JoinGameConfirmation';
import {CancelGameConfirmation}
  from '../../components/game/CancelGameConfirmation';
import {useSelector} from 'react-redux';
import {AlertSnackBar} from '../../components/game/AlertSnackBar';
import {GameDetailsView} from '../../components/game/GameView/GameDetailsView';
import {formatTime, getShortDate} from '../../components/game/util';
import {fetchGame, fetchGamePlayers, fetchPublicProfile, fetchVenue, uploadGameMedia} from '../../helpers';
import {trackEvent} from '../../analytics';

export const Game = () => {
  const params = useParams();
  const isLoggedIn = useSelector((state) => state.isLogged);
  const prevLocation = useLocation();
  const navigate = useNavigate();

  const id = params.id;
  const [game, setGame] = useState(null);
  const [players, setPlayers] = useState([]);
  const [venue, setVenue] = useState(null);
  const [organiser, setOrganiser] = useState(null);
  const [secret, setSecret] = useState(null);
  const [joining, setJoining] = useState(false);
  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
  const [invalidSecret, setInvalidSecret] = useState(false);
  const gameUrl = `https://www.forza90.com/games/${id}`;

  const [leaveGameConfirmationOpen, setLeaveGameConfirmationOpen] =
      useState(false);
  const [leaveGameSnackBarOpen, setLeaveGameSnackBarOpen] = useState(false);

  const [joinGameConfirmationOpen, setJoinGameConfirmationOpen] =
      useState(false);
  const [joinGameSnackBarOpen, setJoinGameSnackBarOpen] = useState(false);

  const [inviteFriendsSnackBarOpen, setInviteFriendsSnackBarOpen] = useState(false);

  const [cancelGameConfirmationOpen, setCancelGameConfirmationOpen] =
      useState(false);
  const [cancelGameSnackBarOpen, setCancelGameSnackBarOpen] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const authToken = cookies.auth;

  const leaveGame = () => {
    axios
        .post(`${process.env.REACT_APP_API_URL}/games/${id}/leave`, {},
            authToken == null ? null : {headers: {'Authorization': authToken}})
        .then((res) => {
          setLeaveGameConfirmationOpen(false);
          setLeaveGameSnackBarOpen(true);
          updateGame();
        })
        .catch( (err) => {
          console.error(err.message);
          console.error(err);
        });
  };

  const onLeaveGame = () => {
    setLeaveGameConfirmationOpen(true);
  };

  const closeLeaveGameConfirmation = () => {
    setLeaveGameConfirmationOpen(false);
  };

  const createCheckoutSession = () => {
    axios
        .post(`${process.env.REACT_APP_API_URL}/stripe/checkout`,
            {
              gameId: id,
              successUrl: `${process.env.REACT_APP_APP_URL}/games/${id}/join/return?status=success`,
              cancelUrl: `${process.env.REACT_APP_APP_URL}/games/${id}/join/return?status=cancel`,
              secret: game.isPrivate? secret : null,
            },
              authToken == null? null : {headers: {'Authorization': authToken}})
        .then((res) => {
          window.location.replace(res.data);
        })
        .catch((error) => {
          setJoinGameConfirmationOpen(false);
          if (error.response) {
            if (error.response.status === 400) {
              // either wrong password or invalid username
              setErrorSnackBarOpen(true);
              setInvalidSecret(true);
            } else {
              setErrorSnackBarOpen(true);
            }
          }
          console.error(error);
          console.error(error.message);
        });
  };

  const joinGame = () => {
    setJoining(true);
    if (game.paymentMethod === 'online') {
      trackEvent(
          'Creating checkout session for game',
          {action: 'Join and Pay', gameId: id, venue: venue.name},
      );
      createCheckoutSession();
      return;
    }

    axios
        .post(`${process.env.REACT_APP_API_URL}/games/${id}/join`, {},
            {
              headers: {'Authorization': authToken},
              params: {
                secret: secret,
              },
            })
        .then((res) => {
          console.log(res);
          setJoinGameConfirmationOpen(false);
          setJoinGameSnackBarOpen(true);
          setJoining(false);
          updateGame();
        })
        .catch( (error) => {
          setJoinGameConfirmationOpen(false);
          setJoining(false);
          if (error.response) {
            if (error.response.status === 400) {
              // either wrong password or invalid username
              setErrorSnackBarOpen(true);
              setInvalidSecret(true);
            } else {
              setErrorSnackBarOpen(true);
            }
          }
          console.error(error);
          console.error(error.message);
        });
  };

  const onJoinGame = () => {
    trackEvent(
        'Join game attempt',
        {action: 'Join game', gameId: id, venue: venue.name},
    );
    if (!isLoggedIn) {
      navigate(`/signin?redirectTo=${prevLocation.pathname}`);
    } else {
      setJoinGameConfirmationOpen(true);
    }
  };

  const closeJoinGameConfirmation = () => {
    setJoinGameConfirmationOpen(false);
  };

  const cancelGame = () => {
    axios
        .post(`${process.env.REACT_APP_API_URL}/games/${id}/cancel`, {},
                authToken == null? null :
                    {headers: {'Authorization': authToken}},
        )
        .then((res) => {
          setCancelGameConfirmationOpen(false);
          setCancelGameSnackBarOpen(true);
        })
        .catch( (err) => {
          console.error(err.message);
          console.error(err);
        });
  };

  const onCancelGame = () => {
    setCancelGameConfirmationOpen(true);
  };

  const closeCancelGameConfirmation = () => {
    setCancelGameConfirmationOpen(false);
  };

  const onFileChange = async (fileObj) => {
    if (!fileObj) {
      return;
    }

    const response = await uploadGameMedia(id, fileObj, authToken);
    if (response.status === 200) {
      await updateGame();
    }
  };

  const updateGame = async () => {
    try {
      const gameResponse = await fetchGame(id, authToken);
      setGame(gameResponse);
      const playersResponse = await fetchGamePlayers(id);
      setPlayers(playersResponse);
      const venueResponse = await fetchVenue(gameResponse.venueId);
      setVenue(venueResponse);
      const organiserResponse = await fetchPublicProfile(gameResponse.organiserId);
      setOrganiser(organiserResponse);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(()=> {
    updateGame();
  }, []);

  return (
    <Container style={{padding: '0px', minHeight: '100vh'}}>
      {game && venue && players && organiser && (
        <GameDetailsView
          game={game}
          venue={venue}
          players={players}
          organiser={organiser}
          isOrganiser={game.isOrganiser}
          hasJoined={game.hasJoined}
          onJoin={onJoinGame}
          onLeave={onLeaveGame}
          onCancel={onCancelGame}
          onFileChange={onFileChange}
          onInvite={()=> {
            navigator.clipboard.writeText(gameUrl);
            setInviteFriendsSnackBarOpen(true);
          }}
        />
      )}
      <LeaveGameConfirmation
        open={leaveGameConfirmationOpen}
        onClose={closeLeaveGameConfirmation}
        onConfirm={leaveGame}
      />
      <AlertSnackBar
        message={'Left the game successfully.'}
        severity={'warning'}
        open={leaveGameSnackBarOpen}
        onClose={() => setLeaveGameSnackBarOpen(false)}
        autoHide
      />
      {game && organiser &&
        <JoinGameConfirmation
          title={`Your Booking: ${venue.name} (${getShortDate(game.dateTime)} ${formatTime(game.dateTime)})`}
          organiser={organiser}
          open={joinGameConfirmationOpen}
          onClose={closeJoinGameConfirmation}
          onConfirm={joinGame}
          paid={game.paymentMethod === 'online'}
          isPrivate={game.isPrivate}
          onSecretChange={(event)=> setSecret(event.target.value)}
          secret={secret}
          submitting={joining}
          rating={game.rating}
        />
      }

      <AlertSnackBar
        message={'Joined the game successfully.'}
        severity={'success'}
        open={joinGameSnackBarOpen}
        onClose={() => setJoinGameSnackBarOpen(false)}
        autoHide
      />
      <AlertSnackBar
        message={'Link copied to clipboard!'}
        severity={'success'}
        open={inviteFriendsSnackBarOpen}
        onClose={() => setInviteFriendsSnackBarOpen(false)}
        autoHide
      />

      <CancelGameConfirmation
        open={cancelGameConfirmationOpen}
        onClose={closeCancelGameConfirmation}
        onConfirm={cancelGame}
      />
      <AlertSnackBar
        message={'Cancelled the game successfully.'}
        severity={'warning'}
        open={cancelGameSnackBarOpen}
        onClose={() => setCancelGameSnackBarOpen(false)}
        autoHide
      />
      <AlertSnackBar
        message={
          invalidSecret?
              'Incorrect secret.' :
              'An error occurred. Please refresh the page or try again later.'
        }
        severity={'error'}
        open={errorSnackBarOpen}
        onClose={() => setErrorSnackBarOpen(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHide={false}
      />
    </Container>
  );
};
