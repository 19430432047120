import React, {createContext, useState, useEffect} from 'react';

const defaultConfig = {
  // defaultLanguage: 'en-GB',
  // country: 'uk',
  // region: 'uk',
  // language: 'en',
  defaultLanguage: 'en-GB',
  country: 'lb',
  region: 'lb',
  language: 'en',
};

const DomainContext = createContext(defaultConfig);

const DomainProvider = ({children}) => {
  const [domainConfig, setDomainConfig] = useState(null);

  useEffect(() => {
    const domain = window.location.hostname;
    console.log('this is the domain');
    const config = getConfigByDomain(domain);
    console.log('This is the config ', config);
    setDomainConfig(config);
  }, []);

  const getConfigByDomain = (domain) => {
    console.log('Getting config for domain ', domain);
    console.log(domain === 'forza90.com');
    console.log(domain === 'forza90.com');
    // if (domain === 'forza90.com') {
    //   return {
    //     defaultLanguage: 'en-GB',
    //     country: 'sa',
    //     region: 'sa',
    //     language: 'ar',
    //   };
    // }
    if (domain === 'forza90.com') {
      return {
        defaultLanguage: 'en-GB',
        country: 'lb',
        region: 'lb',
        language: 'en',
      };
    }
    return defaultConfig;
  };

  return (
    <DomainContext.Provider value={domainConfig}>
      {children}
    </DomainContext.Provider>
  );
};

export {DomainContext, DomainProvider};
