import React, {useState} from 'react';
import {Box, List, ListItem, ListItemText, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

export const PasswordTextField = ({onChange, value, autoComplete}) => {
  const {t} = useTranslation();
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState({
    long: false,
    containsUppercase: false,
    containsLowercase: false,
    containsNumber: false,
    containsSymbol: false,
  });

  const onPasswordChange = (event) => {
    const newValue = event.target.value;
    validatePassword(newValue);
    setPasswordChanged(true);
    onChange(newValue);
  };

  const passwordRequirements = {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 0,
  };

  const validatePassword = (password) => {
    const newErrors = {
      long: false,
      containsUppercase: false,
      containsLowercase: false,
      containsNumber: false,
      containsSymbol: false,
    };
    if (password.length >= passwordRequirements.minLength) {
      newErrors.long = true;
    }
    if (/[A-Z]+/.test(password)) {
      // Password doesn't contain at least one capital letter
      newErrors.containsUppercase = true;
    }
    if (/[a-z]+/.test(password)) {
      // Password doesn't contain at least one capital letter
      newErrors.containsLowercase = true;
    }
    if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)) {
      // Password doesn't contain at least one special character
      newErrors.containsSymbol = true;
    }
    if (/\d/.test(password)) {
      newErrors.containsNumber = true;
    }
    setPasswordErrors(newErrors);
  };
  return (
    <Box>
      <TextField
        required
        fullWidth
        name="password"
        label={t('common.password')}
        type="password"
        id="password"
        autoComplete= {autoComplete || 'new-password'}
        variant="outlined"
        value={value}
        error={passwordChanged && !Object.values(passwordErrors).every(Boolean)}
        onChange={onPasswordChange}
        helperText={passwordChanged &&
        !Object.values(passwordErrors).every(Boolean) ?
            t('errors.passwordRequirementsFail') : ''
        }
        color={passwordChanged &&
        Object.values(passwordErrors).every(Boolean)? 'success' : 'error'}
      />
      {passwordChanged && !Object.values(passwordErrors).every(Boolean) && (
        <List dense disablePadding>
          <ListItem disablePadding>
            <ListItemText
              primaryTypographyProps={{
                color: passwordErrors.containsUppercase? 'green' : 'error',
              }}
              primary={t('errors.oneUppercaseLetter')}
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primaryTypographyProps={{
                color: passwordErrors.containsLowercase? 'green' : 'error',
              }}
              primary={t('errors.oneLowercaseLetter')}
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primaryTypographyProps={{
                color: passwordErrors.containsNumber? 'green' : 'error',
              }}
              primary={t('errors.oneNumber')}
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primaryTypographyProps={{
                color: passwordErrors.containsSymbol? 'green' : 'error',
              }}
              primary={t('errors.oneSymbol')}
            />
          </ListItem>
          {!passwordErrors.long && (
            <ListItem disablePadding>
              <ListItemText
                primaryTypographyProps={{
                  color: passwordErrors.long? 'green' : 'error',
                }}
                primary={t('errors.shortPassword')}
              />
            </ListItem>
          )}
        </List>
      )}
    </Box>
  );
};

PasswordTextField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  autoComplete: PropTypes.string,
};
