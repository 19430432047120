import * as React from 'react';
import {
  Box,
  Button, IconButton, Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useTheme} from '@mui/material/styles';
import QRCode from 'react-qr-code';
import {GameCoverPicture} from '../game/GameCoverPicture';
import {GameInfoBox} from '../containers/GameInfoBox';
import {getRandomGameCover} from '../game/util';
import {TeamOptionsMenu} from './TeamOptionsMenu';
import {TeamPlayer} from './player/TeamPlayer';
import {useTranslation} from 'react-i18next';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {useState} from 'react';
import {AlertSnackBar} from '../game/AlertSnackBar';
import {TeamCaptain} from './player/TeamCaptain';

export const TeamDetailsView = ({team, players, isCreator, onDelete}) => {
  const {t} = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const id = params.id;
  const teamUrl = `${process.env.REACT_APP_APP_URL}/teams/${id}`;
  const [linkCopiedSnackBarOpen, setLinkCopiedSnackBarOpen] = useState(false);
  const inviteUrl=`${process.env.REACT_APP_APP_URL}/teams/${id}/join?token=${team.token}`;

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const coverPic = getRandomGameCover(id);

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Paper elevation={0}>
        <GameCoverPicture editable={false} defaultCover={coverPic}/>
        <Box display={'flex'} flexDirection={'column'} p={smallScreen? 0 : 2}>
          <Box display={'flex'} flexDirection={'column'} p={smallScreen? 1: 2}>
            <Box
              my={2}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
            >
              <Box>
                <Typography variant={smallScreen? 'h5' : 'h4'}>
                  {`⚽️ ${team.name}⚽️`}
                </Typography>
              </Box>
              {isCreator && (
                <TeamOptionsMenu
                  teamId={id}
                  onDelete={onDelete}
                />
              )}
            </Box>
          </Box>
          <Box p={2}>
            <Typography variant={'h6'}> {t('teams.captain')}</Typography>
            <Box mt={1}>
              {team.creatorId && <TeamPlayer userId={team.creatorId}/>}
            </Box>
            <Typography variant={'h6'} mt={2}> Players</Typography>
            <GameInfoBox style={{alignSelf: 'center', marginTop: '10px'}}>
              {isCreator && (
                <Button
                  fullWidth={smallScreen}
                  variant="outlined"
                  onClick={() => navigate(`/teams/${id}/manage`)}
                  color={'success'}
                >
                  {t('teams.managePlayers')}
                </Button>
              )}


              <Box>
                {players.map((userId) =>
                  <TeamPlayer
                    key={userId}
                    userId={userId}
                  />,
                )}

                {players.length === 0 && (
                  <Box
                    sx={{minHeight: '65px'}}
                    alignItems={'center'}
                    justifyContent={'center'}
                    display={'flex'}
                  >
                    <Typography alignSelf={'center'}>
                      {t('teams.noPlayers')}
                    </Typography>
                  </Box>
                )}
              </Box>
            </GameInfoBox>
            {isCreator && (
              <Box mt={2}>
                <Typography variant={'h6'}>Invite your teammates by sharing this magic link!</Typography>
                <Box display={'flex'} flexDirection={'row'}>
                  <Box sx={{border: '2px dotted grey'}} p={0.5}>
                    <Typography variant={'body2'}> {inviteUrl} </Typography>
                  </Box>
                  <IconButton
                    onClick={()=> {
                      setLinkCopiedSnackBarOpen(true);
                      navigator.clipboard.writeText(inviteUrl);
                    }}
                  >
                    <ContentCopyIcon/>
                  </IconButton>

                </Box>
              </Box>
            )}
          </Box>
          <Box display={'flex'} flexDirection={'column'} mb={3}>
            <Typography variant={'h6'} mb={2} p={smallScreen? 1: 2}>
              {t('common.qrCodeShare')}
            </Typography>
            <Box alignSelf={'center'}>
              <QRCode value={teamUrl}/>
            </Box>
          </Box>
        </Box>
      </Paper>
      <AlertSnackBar
        message={t('alerts.linkCopied')}
        severity={'success'}
        open={linkCopiedSnackBarOpen}
        onClose={() => setLinkCopiedSnackBarOpen(false)}
        autoHide
      />
    </Box>
  );
};

TeamDetailsView.propTypes = {
  team: PropTypes.object,
  isCreator: PropTypes.bool,
  players: PropTypes.array,
};
