import * as React from 'react';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DeleteIcon from '@mui/icons-material/Delete';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

export const TournamentOptionsMenu = ({id, onDelete, disabled}) => {
  const {t} = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEdit = () => {
    navigate(`/tournaments/${id}/edit`);
    handleClose();
  };

  const onManageTeams = () => {
    navigate(`/tournaments/${id}/manage`);
    handleClose();
  };

  const handleDelete = () => {
    onDelete();
    handleClose();
  };
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        disabled={disabled}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={onEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('tournament.edit')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={onManageTeams}>
          <ListItemIcon>
            <PeopleAltIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('tournament.manageTeams')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color={'error'}/>
          </ListItemIcon>
          <ListItemText color={'red'}>{t('tournament.cancel')}</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};
TournamentOptionsMenu.propTypes = {
  tournamentId: PropTypes.string,
  onDelete: PropTypes.func,
  disabled: PropTypes.bool,
};

