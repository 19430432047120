import React, {useEffect, useState} from 'react';
import {Box, List, ListItem, ListItemText, Skeleton, Stack, Typography, useMediaQuery} from '@mui/material';
import Card from '@mui/material/Card';
import {formatTime, getSimpleDate} from '../../game/util';
import moment from 'moment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {useTranslation} from 'react-i18next';
import {useTheme} from '@mui/material/styles';
import {useNavigate, useParams} from 'react-router-dom';
import {fetchTournamentRoundTeams, fetchVenue} from '../../../helpers';
import {ListItemTeam} from './ListItemTeam';

const emojis = ['🔥', '😮‍💨', '🎯', '🦾', '✨', '🌟', '😎', '🥶', '🫡',
  '😴', '💀', '☠️', '🦿', '👀', '🧠', '🥷', '💥', '⚡️', '💫', '🌪️', '🚀', '⏳', '❤️‍🔥', '🚫',
];

export const TournamentRoundCard = ({round}) => {
  const {t} = useTranslation();
  const params = useParams();
  const tournamentId = params.id;
  const theme = useTheme();
  const navigate = useNavigate();

  const [venue, setVenue] = useState(null);
  const [teams, setTeams] = useState([]);

  const updateTournamentRound = async () => {
    try {
      const roundTeams = await fetchTournamentRoundTeams(round.tournamentId, round.id);
      setTeams(roundTeams);

      const roundVenue = await fetchVenue(round.venueId);
      setVenue(roundVenue);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(()=> {
    updateTournamentRound();
  }, []);

  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Card
      elevation={4}
      sx={{borderColor: 'border.light', p: 2}}
      onClick={()=> navigate(`/tournaments/${tournamentId}/rounds/${round.id}`)}
    >
      <Stack spacing={0}>
        <Typography alignSelf={'end'} variant={'subtitle2'}>
          {`${getSimpleDate(moment(round.dateTime))} • ${formatTime(round.dateTime)}`}
        </Typography>
        <Typography
          variant={'h5'}
          color={'secondary'}
          fontWeight={'bold'}
        >
          {round.name}
        </Typography>
        <Box display={'flex'} mt={1}>
          <LocationOnIcon
            sx={{fontSize: smallScreen? 18 : 24, color: 'blue.main'}}
          />
          {venue?
              <Typography sx={{ml: 0.5}}>
                {venue.name}
              </Typography> :
              <Skeleton animation={'wave'} width={100}/>
          }

        </Box>
        <Box display={'flex'} mt={1} >
          <Typography variant={'subtitle1'} fontWeight={'bold'} ml={0.5}>
            {t('common.teams')}
          </Typography>
          <Typography variant={'subtitle1'} fontWeight={'bold'}>
            &nbsp;({`${teams? teams.length : 0}/${round.size}`})
          </Typography>
        </Box>
        <Box display={'flex'} justifyContent={'space-evenly'}>
          <List disablePadding>
            {teams && teams.slice(0, Math.ceil(teams.length / 2)).map((teamId) => (
              <ListItemTeam key={teamId} id={teamId}/>
            ))}
          </List>
          <List disablePadding>
            {teams && teams.slice(Math.ceil(teams.length / 2)).map((teamId) => (
              <ListItemTeam key={teamId} id={teamId}/>
            ))}
          </List>
        </Box>
      </Stack>
    </Card>
  );
};
