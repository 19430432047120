import React, {useEffect, useState} from 'react';
import {IconButton} from '@mui/material';
import Typography from '@mui/material/Typography';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import {useTranslation} from 'react-i18next';
import {PlayerAvatar} from './PlayerAvatar';
import {fetchPublicProfile} from '../../../helpers';

export const TeamPlayer = ({userId, editable, onDelete, reverse}) => {
  const {t} = useTranslation();
  const [name, setName] = useState(null);
  const [profilePictureUrl, setProfilePictureUrl] = useState(null);


  const updatePlayer = async () => {
    try {
      const profile = await fetchPublicProfile(userId);
      setName(profile.username);
      setProfilePictureUrl(profile.profilePictureUrl);
    } catch (error) {
      if (error.status === 404) {
      }
      console.error(error);
    }
  };


  useEffect(()=> {
    updatePlayer();
  }, []);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: '10px',
      }}
      justifyContent={reverse? 'end': 'start'}
      minWidth={'150px'}
    >
      <PlayerAvatar profilePictureUrl={profilePictureUrl} userId={userId}/>
      <Typography style={{margin: '7px'}}>{name}</Typography>
      {editable && (
        <Box display={'flex'} flexDirection={'row'}>
          <IconButton
            aria-label={t('teams.removePlayer')}
            onClick={() => onDelete(userId, name)}
            color={'error'}
          >
            <ClearIcon/>
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

TeamPlayer.propTypes = {
  editable: PropTypes.bool,
  userId: PropTypes.number,
  onDelete: PropTypes.func,
  reverse: PropTypes.bool,
};
