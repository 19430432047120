import React, {useEffect, useState} from 'react';
import {DateTimePicker} from '../../components/input/DateTimePicker';
import {GameSizeSelect} from '../../components/game/GameSizeSelect';
import {
  Box,
  Button,
  Container
  , IconButton, MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {geocodeByAddress} from 'react-google-places-autocomplete';
import axios from 'axios';
import {useCookies} from 'react-cookie';
import {GenderRadio} from '../../components/input/GenderRadio';
import {useNavigate, useParams} from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import {LoadingButton} from '@mui/lab';
import {AlertSnackBar} from '../../components/game/AlertSnackBar';
import {fetchGame, fetchVenue} from '../../helpers';
import {currencies} from "../../constants/Currency";

export const EditGame = () => {
  const params = useParams();
  const id = params.id;
  const [venueName, setVenueName] = useState('');
  const [dateValue, setDateValue] = useState(new Date);
  const [timeValue, setTimeValue] = useState(new Date);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [size, setSize] = useState(10);
  const [location, setLocation] = useState(null);
  const [price, setPrice] = useState('');
  const [currency, setCurrency] = useState('');
  const [paymentType, setPaymentType] = useState('free');
  const [changeLocation, setChangeLocation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const authToken = cookies.auth;
  const navigate = useNavigate();

  const editGame = (body) => {
    axios
        .post(`${process.env.REACT_APP_API_URL}/games/${id}/edit`, body, {headers: {'Authorization': authToken}} )
        .then((res) => {
          setSubmitting(false);
          setSuccess(true);
          navigate(`/games/${id}`);
        })
        .catch( (err) => {
          console.error(err.message);
          console.error(err);
        });
  };

  const updateGame = async () => {
    try {
      const game = await fetchGame(id, authToken);
      if (!game.isOrganiser) {
        navigate(`/games/${id}`);
      }

      const venue = await fetchVenue(game.venueId);

      setTitle(game.title);
      setDescription(game.description);
      setDateValue(new Date(game.dateTime));
      setTimeValue(new Date(game.dateTime));
      setSize(game.size);
      setVenueName(venue.name);
      setSize(game.size);
      setPrice(game.price);
      setCurrency(game.paymentCurrency);
      setPaymentType(game.paymentMethod);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    updateGame();
  }, []);

  const handleSubmit = async (event) => {
    setSubmitting(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const dateTime = new Date(dateValue);
    dateTime.setHours(timeValue.getHours());
    dateTime.setMinutes(timeValue.getMinutes());
    let venue = null;
    if (changeLocation && location == null) {
      return;
    } else if (changeLocation) {
      const address = await geocodeByAddress(location.label);
      venue = {
        id: null,
        name: location.label,
        location: address[0].formatted_address,
      };
    }
    const tzoffset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
    const dateTimeString = (new Date(dateTime - tzoffset))
        .toISOString()
        .replace('T', ' ')
        .substring(0, 16);

    const game = {
      size,
      dateTime: dateTimeString,
      price: paymentType === 'online' || 'cash'? data.get('price') : 0,
      paymentCurrency: paymentType === 'online' || 'cash'? data.get('currency') : null,
      title: data.get('title'),
      description: data.get('description'),
      gender: data.get('gender'),
    };
    editGame({game, venue});
  };

  const onGameSizeChange = (newSize) => {
    setSize(newSize);
  };

  return (
    <Container maxWidth={'md'} style={{justifyContent: 'center'}} >
      <Box
        sx={{
          mt: 8,
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'background.paper',
          borderColor: 'border.light',
          padding: '30px',
        }}
        borderRadius="6px"
        border={1}
      >
        <Typography variant="h5">
          Edit your game
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}} >
          <TextField
            margin="normal"
            required
            fullWidth
            id="title"
            label="Title"
            name="title"
            autoFocus
            value={title}
            onChange={(event)=> setTitle(event.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="description"
            label="Description"
            name="description"
            multiline
            rows={3}
            value={description}
            onChange={(event)=> setDescription(event.target.value)}
          />

          <DateTimePicker
            onDateChange={(newValue) => setDateValue(newValue)}
            onTimeChange={(newValue) => setTimeValue(newValue)}
            dateValue={dateValue}
            timeValue={timeValue}
          />
          <GameSizeSelect
            onChange={onGameSizeChange}
            value={size}
          />
          <GenderRadio/>
          <Box marginTop={3} marginBottom={3}>
            {/* <PriceRadio*/}
            {/*  onChange={(event) => setPaymentType(event.target.value)}*/}
            {/*  value={paymentType}*/}
            {/* />  todo add connected account check here or never update payment
            type because might cause issues with refunds etc*/}
            {paymentType === 'cash' &&
                <Box display={"flex"} alignItems={"center"}>
                  <TextField
                      sx={{mr: 2, mt:1}}
                      style={{minWidth: "85px"}}
                      id="currency"
                      name="currency"
                      select
                      value={currency}
                      onChange={(event) => setCurrency(event.target.value)}
                  >
                    {currencies.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="price"
                    label="Price"
                    name="price"
                    type="number"
                    value={price}
                    onChange={(event) => setPrice(event.target.value)}
                  />
                </Box>
            }
          </Box>
          <Box display={'flex'} flexDirection={'row'}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="venue"
              label="Venue"
              name="venue"
              type="text"
              value={venueName}
              disabled
              onChange={(event) => setVenueName(event.target.value)}
            />
            {changeLocation ? (
                <IconButton
                  color={'error'}
                  onClick={()=>setChangeLocation(false)}
                  aria-label={'cancel'}
                >
                  <CloseIcon/>
                </IconButton>
            ) : (
                <IconButton
                  size={'large'}
                  color={'primary'}
                  onClick={()=>setChangeLocation(true)}
                  aria-label={'change location'}
                >
                  <EditLocationAltIcon/>
                </IconButton>
            )}

          </Box>
          {changeLocation && (
            <Box sx={{mt: 2}}>
              <Typography style={{marginLeft: '5px'}}> New Venue Location: </Typography>
              <GooglePlacesAutocomplete
                apiKey="AIzaSyAAshjfW3KpxX12xl9D8ly_w6G3hGI_ho0"
                apiOptions={{language: 'en', region: 'gb'}}
                selectProps={{
                  location,
                  onChange: setLocation,
                }}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ['uk'],
                  },
                }}
              />
              {location == null && <Typography color={'error'}> New location can't be empty.</Typography>}
            </Box>
          )}
          <AlertSnackBar
            message={'Game edited successfully!'}
            severity={'success'}
            open={success}
            onClose={()=> setSuccess(false)}
          />

          <Box display={'flex'} flexDirection={'row'} style={{gap: '20%'}}>
            <Button
              fullWidth
              variant="contained"
              sx={{mt: 3, mb: 2}}
              color={'error'}
              onClick={() => navigate(`/games/${id}`)}
            >
                        Cancel
            </Button>
            <LoadingButton
              type={'submit'}
              size="small"
              variant="contained"
              sx={{mt: 3, mb: 2}}
              color={'success'}
              loading={submitting}
              fullWidth
            >
              Save Changes
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
