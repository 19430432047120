import React from 'react';
import {Box, Divider, useMediaQuery} from '@mui/material';
import {PlayerWithAvatar} from './PlayerWithAvatar';
import PropTypes from 'prop-types';
import {useTheme} from '@mui/material/styles';

export const PlayersContainer = ({players, size}) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      display="flex"
      flexDirection="row"
    >
      <Box marginRight={smallScreen? 1 : 2}>
        {players.slice(0, Math.floor(size / 2)).map((player) =>
          <PlayerWithAvatar
            key={player.id}
            name={player.name}
            type={player.type}
            reverse
          />,
        )}
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box marginLeft={smallScreen? 1 : 2}>
        {players.slice(Math.floor(size / 2)).map((player) =>
          <PlayerWithAvatar
            key={player.id}
            name={player.name}
            type={player.type}
          />,
        )}
      </Box>
    </Box>
  );
};

PlayersContainer.propTypes = {
  players: PropTypes.array,
  size: PropTypes.number,
};
