import React, {useEffect} from 'react';
import {WelcomeContainer} from '../../components/containers/WelcomeContainer';
import {Box, Container, Typography, useMediaQuery} from '@mui/material';
import FindGamesMockup from '../../assets/mockups/findGamesMockup.png';
import CreateGameMockup from '../../assets/mockups/createGameMockup.png';
import GamesListMockup from '../../assets/mockups/gamesListMockup.png';
import GameViewMockup from '../../assets/mockups/gameViewMockup.png';
import {useTheme} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';

export const Home = () => {
  const theme = useTheme();
  const {t} = useTranslation();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const APKUrl = "https://play.google.com/store/apps/details?id=com.forza90.app";
  const IOSUrl = "https://apps.apple.com/gb/app/forza90/id6720759723";

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    const redirectToAppStore = () => {
      if (/android/i.test(userAgent)) {
        window.location.href = APKUrl;
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.location.href = IOSUrl;
      }
    };

    redirectToAppStore();
  }, []);


  return (
    <Container
      maxWidth={false}
      style={{
        justifyContent: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        padding: '0px',
      }}
    >
      <WelcomeContainer/>
      <Box mt={2} alignSelf={'center'} display={'flex'} flexDirection={'column'} alignItems={'center'} p={3}>
        <Typography variant={'h3'} fontWeight={'bold'} textAlign={'left'} mb={3}>
          {t('homePage.competitiveGames')}
        </Typography>
        <Typography variant={'h5'} color={'#525252'} textAlign={'left'} mb={3}>
          {t('homePage.community')}
        </Typography>
        <Box>
          {!mediumScreen &&
                    <img style={{width: 276, height: 'auto', transform: 'translateY(4vh)'}} src={CreateGameMockup} alt="Find Games Mockup" />
          }
          <img style={{width: 276, height: 'auto'}} src={FindGamesMockup} alt="Find Games Mockup" />
          {!mediumScreen &&
                <img style={{width: 276, height: 'auto', transform: 'translateY(4vh)'}} src={GamesListMockup} alt="Find Games Mockup" />
          }
        </Box>
        <Box style={{backgroundImage: 'linear-gradient(to bottom, #eaedff30 , white)'}} display={'flex'} flexDirection={smallScreen? 'column' : 'row'} justifyContent={'space-evenly'} mt={9} alignItems={'center'} width={'100%'}>
          <Box flexGrow={smallScreen? 1 :0} flexBasis={1}>
            <img style={{width: smallScreen? 240 : 276, height: 'auto', transform: 'rotate(-20deg)'}} src={GameViewMockup} alt="Find Games Mockup" />
          </Box>
          <Box flexGrow={smallScreen? 1: 0.5} flexBasis={1} display={'flex'} flexDirection={'column'}>
            <Typography variant={'h3'} textAlign={'left'} fontWeight={'bold'}> {t('homePage.casualCompetitive')}</Typography>
            <br/>
            <br/>
            <Typography variant={'h7'} color={'#525252'}>
              {t('homePage.schedule')}
            </Typography>
          </Box>
        </Box>

        <Box display={'flex'} flexDirection={smallScreen? 'column' : 'row-reverse'} justifyContent={'space-evenly'} mt={9} alignItems={'center'} width={'100%'}>
          <Box flexGrow={smallScreen? 1 :0} flexBasis={1}>
            <img style={{width: smallScreen? 240 : 276, height: 'auto', transform: 'rotate(20deg)'}} src={CreateGameMockup} alt="Find Games Mockup" />
          </Box>
          <Box flexGrow={smallScreen? 1: 0.5} flexBasis={1} display={'flex'} flexDirection={'column'}>
            <Typography variant={'h3'} textAlign={'left'} fontWeight={'bold'}> {t('homePage.missingPlayersHeader')}</Typography>
            <Typography variant={'h7'} color={'#525252'}>
              {t('homePage.missingPlayersBody')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
