import * as React from 'react';
import {
  Box,
  Button,
  Divider,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useTheme} from '@mui/material/styles';
import SportsIcon from '@mui/icons-material/Sports';
import QRCode from 'react-qr-code';
import {TournamentDetailsHeader} from './TournamentDetailsHeader';
import {OrganiserDetails} from '../game/GameView/OrganiserDetails';
import {GameInfoBox} from '../containers/GameInfoBox';
import {TeamCard} from '../teams/TeamCard';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import {useTranslation} from 'react-i18next';
import {TournamentDescription} from './TournamentDescription';
import {TournamentRoundCardsView} from './round/TournamentRoundCardsView';
import {Waitlist} from './Waitlist';
import {GameDetailsIcon} from '../game/GameView/GameDetailsIcon';
import {AccessTime, EventOutlined, Payment} from '@mui/icons-material';
import {formatDate, formatTime} from '../game/util';
import {TournamentTeamCard} from './team/TournamentTeamCard';
import {MapComponent} from '../game/GameView/MapComponent';

export const TournamentDetailsView = ({tournament, venue, teams, waitlistTeams, rounds,
  userTeam, organiser, isOrganiser, onJoin}) => {
  const params = useParams();
  const {t} = useTranslation();

  const id = params.id;
  const name = tournament.name;
  const size = tournament.size;
  const teamSize = tournament.teamSize;
  const startDate = moment(tournament.startDate);
  const isFull = tournament && teams? teams.length >= size : false;
  const tournamentUrl =
      `${process.env.REACT_APP_APP_URL}/tournaments/${id}`;

  const previousRounds = rounds? rounds.filter((r) => moment().isAfter(moment(r.dateTime))) : [];
  const upcomingRounds = rounds? rounds.filter((r) => moment().isBefore(moment(r.dateTime))) : [];
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Paper elevation={0}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          p={smallScreen? 0 : 2}
        >
          <TournamentDetailsHeader
            id={id}
            name={name}
            startDate={startDate}
            onCancel={() => {}}
            isOrganiser={isOrganiser}
            location={'here'}
          />

          {organiser && <OrganiserDetails organiser={organiser}/>}

          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-evenly'}
            alignItems={'center'}
            mb={2}
          >
            <GameDetailsIcon
              icon={SportsIcon}
              text={t('common.playersAside', {teamSize: teamSize})}
            />
            <GameDetailsIcon
              icon={EventOutlined}
              text={formatDate(tournament.startDate)}
            />
            <GameDetailsIcon
              icon={AccessTime}
              text={formatTime(tournament.startDate)}
            />
            <GameDetailsIcon
              icon={Payment}
              text={tournament.price === 0?
                    t('common.free') : tournament.price.toPrecision(3) + 'GBP'
              }
            />
          </Box>

          <Box
            display={'flex'}
            flexDirection={'column'}
            p={smallScreen? 1: 2}
          >
            {userTeam && (
              <TournamentTeamCard teamSize={teamSize} id={userTeam.teamId} status={userTeam.status} />
            )}
            <Box display={'flex'} justifyContent={'center'}>
              <Typography variant={'h5'} sx={{mb: 1}} fontWeight={'bolder'}>
                {t('tournament.teamsParticipating')}
              </Typography>
              <Typography variant={'h6'} fontWeight={'bold'}>
                  &nbsp;({`${teams.length}`})
              </Typography>
            </Box>
            {tournament.open &&
              <Typography alignSelf={'center'} variant={'subtitle1'} color={'green'}>
                {t('tournament.openRegistrations')}
              </Typography>
            }
            <Box>
            </Box>
            <GameInfoBox style={{alignSelf: 'center'}}>
              <Divider/>
              {teams && teams.map((team)=>
                <div key={team.teamId}>
                  <TeamCard
                    id={team.teamId}
                  />
                  <Divider/>
                </div>,
              )}
              {[...Array((3))]
                  .map((e, i) =>
                    <div key={i}>
                      <Box
                        sx={{
                          my: 1,
                          display: 'flex',
                          flexDirection: 'row',

                          py: 1,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Button
                          color={'success'}
                          startIcon={<GroupAddIcon/>}
                          variant={'text'}
                          onClick={onJoin}
                          disabled={!tournament.open || userTeam != null}
                        >
                          {t('common.openSpot')}
                        </Button>
                      </Box>
                      <Divider/>
                    </div>,
                  )}
            </GameInfoBox>
          </Box>
          <Divider/>
          {upcomingRounds && upcomingRounds.length > 0 && (
            <Box>
              <Box display={'flex'} p={smallScreen? 1: 2}>
                <Typography variant={'h5'} sx={{mb: 1}}>
                  {t('tournament.upcomingRounds')}
                </Typography>
              </Box>
              <Box display={'flex'} flexDirection={'column'} mx={2} mb={2}>
                <TournamentRoundCardsView rounds={upcomingRounds}/>
              </Box>
              <Divider/>
            </Box>
          )}
          {previousRounds && previousRounds.length > 0 && (
            <Box>
              <Box display={'flex'} p={smallScreen? 1: 2}>
                <Typography variant={'h5'} sx={{mb: 1}}>
                  {t('tournament.previousRounds')}
                </Typography>
              </Box>
              <Box display={'flex'} flexDirection={'column'} mx={2} mb={2}>
                <TournamentRoundCardsView rounds={previousRounds}/>
              </Box>
            </Box>
          )}
          {isFull &&
            <Waitlist
              tournament={tournament}
              waitlistTeams={waitlistTeams}
              onJoinWaitlist={onJoin}
            />
          }
          {venue && <MapComponent location={venue.name}/>}
          <TournamentDescription
            description={tournament.description}
          />

          <Box display={'flex'} flexDirection={'column'} mb={3}>
            <Typography variant={'h6'} mb={2} p={smallScreen? 1: 2}>
              {t('common.qrCodeShare')}
            </Typography>
            <Box alignSelf={'center'} mb={10}>
              <QRCode value={tournamentUrl}/>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

TournamentDetailsView.propTypes = {
  teams: PropTypes.array,
  isOrganiser: PropTypes.bool,
  hasJoined: PropTypes.bool,
  onCancel: PropTypes.func,
  onLeave: PropTypes.func,
  onJoin: PropTypes.func,
  onInvite: PropTypes.func,
};
