import React, {useEffect, useState} from 'react';
import {Box, Container, Divider, Typography} from '@mui/material';
import {fetchTournaments} from '../../helpers';
import {TournamentCardsView}
  from '../../components/tournament/TournamentCardsView';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';

export const Tournaments = () => {
  const {t} = useTranslation();
  const [tournaments, setTournaments] = useState([]);

  const updateTournaments = async () => {
    try {
      const response = await fetchTournaments();
      setTournaments(response);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(()=> {
    updateTournaments();
  }, []);

  return (
    <Container component={'main'} sx={{mb: 10, p: 1, minHeight: '100vh'}}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tournaments in London</title> //todo: make dynamic
        <meta name="description" content="Create your team and join tournaments in london" />
      </Helmet>
      <Box display={'flex'} flexDirection={'column'} p={2}>
        <Box>
          <Divider textAlign="left" sx={{borderBottomWidth: '20px'}}>
            <Typography variant={'h4'}>
              {t('common.tournaments')}
            </Typography>
          </Divider>

        </Box>
        <TournamentCardsView tournaments={tournaments}/>
      </Box>
    </Container>
  );
};
