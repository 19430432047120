import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import {useEffect, useState} from 'react';
import axios from 'axios';
import {useCookies} from 'react-cookie';

export const AddPlayerConfirmation = ({onClose, open, onConfirm}) => {
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const [playerType, setPlayerType] = useState('user');
  const [username, setUsername] = useState(null);
  const [name, setName] = useState('');
  const [usernames, setUsernames] = useState([]);
  const [usernameNotFoundError, setUsernameNotFoundError] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    if (playerType === 'user' && username == null) {
      setUsernameNotFoundError(true);
      return;
    }

    const player = {
      type: playerType,
      username: playerType === 'user' ? username : null,
      name: playerType === 'guest' ? name : null,
    };

    onConfirm(player);
  };

  useEffect(()=> {
    const fetchUsernames = async () =>{
      const authToken = cookies.auth;
      await axios.get(`${process.env.REACT_APP_API_URL}/users/usernames`,
          authToken == null ? null : {headers: {'Authorization': authToken,
          }})
          .then((res) => {
            if (res.status === 200) {
              setUsernames(res.data);
            }
          })
          .catch((err) => {
            if (err.status === 401) {
              console.log('Token not valid');
            }
            console.log(err);
          });
    };
    fetchUsernames();
  }, []);

  /** todo: add radio buttons for choosing guest or user,
   * if user add username if guest just add name
   * then send request to add player in parent component.*/
  return (
    <Box>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"

      >
        <DialogTitle id="alert-dialog-title">
          {'Add player'}
        </DialogTitle>
        <Box sx={{mt: 1}} component={'form'} onSubmit={onSubmit} >
          <DialogContent sx={{minWidth: '50vh'}}>
            <FormLabel component="legend">Player Type</FormLabel>
            <RadioGroup
              aria-label="type"
              onChange={(event) =>
                setPlayerType(event.target.value)
              }
              value={playerType}
              name="type"
              row
            >
              <FormControlLabel value="user" control={<Radio />} label="User" />
              <FormControlLabel value="guest" control={<Radio />} label="Guest"/>
            </RadioGroup>
            {playerType === 'user'? (
              <Autocomplete
                id="disabled-options-demo"
                value={username}
                onChange={(event, newValue) => {
                  setUsername(newValue);
                  setUsernameNotFoundError(false);
                }}
                options={usernames}
                // getOptionDisabled={(option) =>
                //     option === timeSlots[0] || option === timeSlots[2]
                // }
                fullWidth
                renderInput={(params) =>
                  <TextField
                    {...params}
                    margin="normal"
                    label="Username"
                    error={usernameNotFoundError}
                    helperText={ usernameNotFoundError? 'User not found.' : ''}
                  />
                }
              />
            ) : (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  type="text"
                  value={name}
                  onChange={(event)=> setName(event.target.value)}
                />
            )}


          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button autoFocus color={'success'} type={'submit'}>
                        Add Player
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

AddPlayerConfirmation.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};
