import mixpanel from 'mixpanel-browser';

// Initialize Mixpanel
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
const env_check = process.env.REACT_APP_ENV === 'production';

// Function to set user details
export const setUserDetails = (userDetails) => {
  localStorage.setItem('userDetails', JSON.stringify(userDetails));
};

// Function to get user details
export const getUserDetails = () => {
  const userDetails = localStorage.getItem('userDetails');
  return JSON.parse(userDetails);
};

// Function to track an event with user data
export const trackEvent = (eventName, eventData) => {
  const userDetails = getUserDetails();

  const eventPayload = {
    ...eventData,
    distinct_id: userDetails?.userId,
    $email: userDetails?.email,
    $username: userDetails?.username,
    // $location: userDetails?.location,
    // Include any other relevant user data
  };
  if (env_check) {
    mixpanel.track(eventName, eventPayload);
  } else {
    console.log('Sending mixpanel event {} {}', eventName, eventPayload);
  }
};
