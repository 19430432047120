import React, {useEffect, useState} from 'react';
import {DateTimePicker} from '../../../components/input/DateTimePicker';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {geocodeByAddress} from 'react-google-places-autocomplete';
import {useCookies} from 'react-cookie';
import {PriceRadio} from '../../../components/input/PriceRadio';
import {useTheme} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {AlertSnackBar} from '../../../components/game/AlertSnackBar';
import {
  createTournamentRound,
  fetchAccountLink,
  fetchConnectedAccount,
} from '../../../helpers';
import {useTranslation} from 'react-i18next';
import {fetchTimezoneFromCoordinates} from '../../../util/timezoneUtils';

export const CreateTournamentRound = () => {
  const {t} = useTranslation();
  const params = useParams();
  const tournamentId = params.id;

  const isLoggedIn = useSelector((state) => state.isLogged);
  const prevLocation = useLocation();
  const navigate = useNavigate();

  const [dateValue, setDateValue] = useState(new Date);
  const [timeValue, setTimeValue] = useState(new Date);

  const [size, setSize] = useState(4);
  const [location, setLocation] = useState(null);
  const [price, setPrice] = useState('');
  const [paymentType, setPaymentType] = useState('free');

  const [onboarded, setOnboarded] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);

  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
  const [invalidLocation, setInvalidLocation] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const authToken = cookies.auth;

  const updateOnboardedStatus = async () => {
    try {
      const account = await fetchConnectedAccount(cookies.auth);
      setOnboarded(account.charges_enabled);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(()=> {
    updateOnboardedStatus();
  }, []);

  const createNewTournamentRound = async (body) => {
    try {
      const response = await createTournamentRound(tournamentId, body, authToken);
      navigate(`/tournaments/${tournamentId}`);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!location) {
      setInvalidLocation(true);
      setErrorSnackBarOpen(true);
      return;
    }
    setErrorSnackBarOpen(false);
    const data = new FormData(event.currentTarget);
    const dateTime = new Date(dateValue);
    dateTime.setHours(timeValue.getHours());
    dateTime.setMinutes(timeValue.getMinutes());

    const address = await geocodeByAddress(location.label);
    const {lat, lng} = address[0].geometry.location;
    const timezoneResult = await fetchTimezoneFromCoordinates(lat(), lng());
    const venue = {
      name: location.label,
      location: address[0].formatted_address,
      ianaTimezone: timezoneResult.timeZoneId,
    };

    const tzoffset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
    const dateTimeString = (new Date(dateTime - tzoffset))
        .toISOString()
        .replace('T', ' ')
        .substring(0, 16);
    const tournamentRound = {
      name: data.get('name'),
      size: size,
      dateTime: dateTimeString,
      price: paymentType === 'online'? data.get('price') : 0,
      description: data.get('description'),
    };
    createNewTournamentRound({tournamentRound, venue});
  };


  const handleSetupAccount = async () => {
    try {
      const accountLink = await fetchAccountLink(cookies.auth);
      window.location.replace(accountLink);
    } catch (e) {
      console.error(e);
      setErrorSnackBarOpen(true);
    }
  };

  const checkDisabled = (value)=> {
    if (!onboarded && value === 'online') {
      setFormDisabled(true);
      return;
    }
    setFormDisabled(false);
  };

  // const onSelectCover = (fileObj) => {
  //     console.log();
  //     if (!fileObj) {
  //         return;
  //     }
  //
  //     // I've kept this example simple by using the first image instead of multiple
  //     setSelectedCover(fileObj);
  // };

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const useStyles = makeStyles({
    root: {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  });
  const classes = useStyles();


  return (
    <Container className={classes.root} component="main" maxWidth={'md'}>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} >
        <Typography variant={smallScreen?'h5': 'h4'} m={smallScreen? 2 : 6}>
          {t('tournaments.create')}
        </Typography>
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'background.paper',
            borderColor: 'border.light',
            padding: '30px',
            width: '100%',
            boxSizing: 'border-box',
          }}
          borderRadius="6px"
          border={1}
        >

          <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}}>
            <Box marginTop={1}>
              <PriceRadio
                onChange={(event) => {
                  setPaymentType(event.target.value);
                  checkDisabled(event.target.value);
                }}
                value={paymentType}
              />
              {paymentType === 'online' && !onboarded && (
                <Box display={'flex'} flexDirection={'column'}>
                  <Typography variant={'subtitle1'} color={'error'}>{t('errors.setupOrganiserAccountError')}</Typography>
                  <Button onClick={handleSetupAccount} variant={'contained'} color={'success'}>{t('common.setupAccount')}</Button>
                </Box>
              )}
              {paymentType === 'online' && onboarded &&
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="price"
                              label={t('common.price')}
                              name="price"
                              type="number"
                              value={price}
                              onChange={(event)=> setPrice(event.target.value)}
                              disabled={formDisabled}
                            />}
            </Box>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label={t('tournaments.tournamentName')}
              name="name"
              autoFocus
              disabled={formDisabled}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="description"
              label={t('common.description')}
              name="description"
              multiline
              rows={3}
              disabled={formDisabled}
            />

            <DateTimePicker
              onDateChange={(newValue) => setDateValue(newValue)}
              onTimeChange={(newValue) => setTimeValue(newValue)}
              dateValue={dateValue}
              timeValue={timeValue}
              disabled={formDisabled}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="size"
              label={t('tournaments.numberOfTeamsLabel')}
              name="size"
              type="number"
              value={size}
              onChange={(event)=>
                setSize(event.target.value)
              }
            />
            <Box sx={{mt: 3}}>
              <Typography style={{marginLeft: '5px'}}> {t('common.venue')}: </Typography>
              <GooglePlacesAutocomplete
                // todo: put in env
                apiKey="AIzaSyAAshjfW3KpxX12xl9D8ly_w6G3hGI_ho0"
                apiOptions={{language: 'en', region: 'sa'}}
                selectProps={{
                  location,
                  onChange: setLocation,
                }}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ['sa'],
                  },
                }}
              />
            </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color={'success'}
              sx={{mt: 3, mb: 6}}
              disabled={formDisabled}
            >
              {t('common.create')}
            </Button>
          </Box>
        </Box>
        <AlertSnackBar
          message={invalidLocation ?
                        t('errors.invalidVenueErrorMessage') : t('errors.genericErrorMessage')
          }
          severity={'error'}
          open={errorSnackBarOpen}
          onClose={() => setErrorSnackBarOpen(false)}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          autoHide={false}
        />
      </Box>
    </Container>
  );
};
