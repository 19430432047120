import React, {useEffect, useState} from 'react';
import {SignUpContainer} from '../../components/containers/SignUpContainer';
import {Box, Button, Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import {EmailTextField} from '../../components/input/EmailTextfield';
import validator from 'validator';
import {AlertSnackBar} from '../../components/game/AlertSnackBar';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';
import {trackEvent} from '../../analytics';

export const ForgotPassword = () => {
  const {t} = useTranslation();
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
  const [invalidInput, setInvalidInput] = useState(false);
  const [noUserExists, setNoUserExists] = useState(false);
  const isLoggedIn = useSelector((state) => state.isLogged);
  const navigate = useNavigate();

  const sendResetEmail = (email) => {
    axios
        .post(`${process.env.REACT_APP_API_URL}/users/forgotpassword`, null, {
          params: {
            email,
          },
        })
        .then((res) => {
          setSubmitting(false);
          setNoUserExists(false);
          setInvalidInput(false);
          setErrorSnackBarOpen(false);
          setEmailSent(true);
          trackEvent('Successful forgot password', {action: 'Forgot password', email: email});
        })
        .catch((error) => {
          setSubmitting(false);
          if (error.response.status === 400) {
            trackEvent('Failed forgot password', {action: 'Forgot password', cause: 'No user with email'});
            setErrorSnackBarOpen(true);
            setNoUserExists(true);
          } else {
            trackEvent('Failed forgot password', {action: 'Forgot password'});
            setErrorSnackBarOpen(true);
          }
        });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    setErrorSnackBarOpen(false);
    trackEvent('Forgot password attempt', {action: 'Forgot password', email: email});
    if (!validator.isEmail(email)) {
      trackEvent('Failed forgot password', {action: 'Forgot password', cause: 'Invalid input'});
      setInvalidInput(true);
      setErrorSnackBarOpen(true);
      setSubmitting(false);
      return;
    }
    sendResetEmail(email);
  };
  useEffect(() => {
    return () => {
      setEmailSent(false);
    };
  }, [isLoggedIn]);

  return (
    <SignUpContainer title={t('registration.resetYourPassword')}>
      <Box sx={{mt: '25px'}}>
        {emailSent? (
            <Typography>
              {t('registration.checkEmail')}
            </Typography>
        ) : (
            <Box>
              <Typography>
                {t('registration.enterEmailToReset')}
              </Typography>
              <EmailTextField
                margin={'normal'}
                value={email}
                onChange={(newValue) => {
                  setEmail(newValue);
                }}
              />
            </Box>
          )}
      </Box>
      {emailSent? (
          <Link to={'/signin'} style={{textDecoration: 'none'}}>
            <Button
              color={'secondary'}
              fullWidth
              variant="contained"
              sx={{mt: 3, mb: 2}}
            >
              {t('registration.returnToSignIn')}
            </Button>
          </Link>
      ) : (
          <LoadingButton
            color={'secondary'}
            type="submit"
            fullWidth
            variant="contained"
            loading={submitting}
            disabled={submitting}
            sx={{mt: 3, mb: 2}}
            onClick={handleSubmit}
          >
            {t('registration.sendPasswordResetEmail')}
          </LoadingButton>
      )}
      <AlertSnackBar
        message={
            invalidInput?
                t('errors.addressErrorsMessage'):
                noUserExists?
                    t('errors.noUserExists') :
                    t('errors.genericErrorMessage')
        }
        severity={'error'}
        open={errorSnackBarOpen}
        onClose={() => setErrorSnackBarOpen(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHide={false}
        sx={{marginTop: '70px'}}
      />
    </SignUpContainer>
  );
};
