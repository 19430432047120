import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

export const TeamSizeSelect = (props)=> {
  const {t} = useTranslation();
  return (
    <Box sx={{minWidth: 120, mt: 3, mb: 3}}>
      <FormControl fullWidth>
        <InputLabel>{t('common.teamSizeLabel')}</InputLabel>
        <Select
          value={props.value}
          label={t('common.teamSizeLabel')}
          onChange={props.onChange}
        >
          {[5, 6, 7, 8, 9, 10, 11]
              .map((e, i) =>
                <MenuItem key={i} value={e}>
                  {t('common.playersAside', {teamSize: e})}
                </MenuItem>,
              )}
        </Select>
      </FormControl>
    </Box>
  );
};

TeamSizeSelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
};
