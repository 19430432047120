import React from 'react';
import {InfoTitleBox} from '../../containers/InfoTitleBox';
import {Box, Button, Typography} from '@mui/material';
import PropTypes from 'prop-types';

export const PlayersContainerHeader = ({hasJoined, onLeave, isCancelled, isPast, isFull, size, players, onJoin, onInviteFriends}) => {
  return (
    <InfoTitleBox
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h7" marginBottom={1}>
        {isFull ? ('The game is currently full. ' +
                        'Check out the other games and dont ' +
                        'miss out on the action!') :
                    hasJoined ? ('Invite your mates and lets get it started! ') :
                        ('Join and show us your skills! ')}
        {!isFull && (size - players.length) + ' spots left'}
      </Typography>
      <Box>
        {hasJoined ?
                    (
                        <Button
                          color={'error'}
                          variant="outlined"
                          style={{margin: 2}}
                          onClick={onLeave}
                          disabled={isCancelled || isPast}
                        >
                            Leave Game
                        </Button>
                    ) :
                    !isFull &&
                    (
                      <Button
                        color="success"
                        variant="contained"
                        style={{margin: 2}}
                        onClick={onJoin}
                        disabled={isCancelled || isPast}
                      >
                            Join Game
                      </Button>
                    )
        }
        {!isFull && (
          <Button
            color="secondary"
            variant="contained"
            style={{margin: 2}}
            onClick={onInviteFriends}
            disabled={isCancelled || isPast}
          >
                        Invite Friends
          </Button>
        )}

      </Box>
    </InfoTitleBox>
  );
};

PlayersContainerHeader.propTypes = {
  isOrganiser: PropTypes.bool,
  isFull: PropTypes.bool,
  isPast: PropTypes.bool,
  players: PropTypes.array,
  size: PropTypes.number,
  hasJoined: PropTypes.bool,
  onCancel: PropTypes.func,
  onLeave: PropTypes.func,
  onJoin: PropTypes.func,
  onInviteFriends: PropTypes.func,
};
