import React from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Container,
  List,
  ListItem,
  Paper,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ForumIcon from '@mui/icons-material/Forum';
import PeopleIcon from '@mui/icons-material/People';
import {Link} from 'react-router-dom';

export const About = () => {
  const paperContainerSx = {
    minHeight: '50vh',
    display: 'flex',
    justifyContent: 'center',
    paddingX: 5,
    flexDirection: 'column',
    flexWrap: 'wrap',
    paddingTop: 2,
  };
  return (
    <Container
      maxWidth={false}
      style={{
        justifyContent: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        padding: '0px',
      }}
    >
      <Paper
        sx={{...paperContainerSx, alignItems: 'center', bgcolor: '#eeefff61'}}
      >
        <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} maxWidth={'500px'}>
          <Typography variant={'h5'} align={'center'} mb={4} color={'primary'} fontWeight={600}>
                        About Us
          </Typography>
          <Typography variant={'h4'} align={'center'} fontWeight={600} mb={3}>
                        We're on a mission to make sports accessible for
            <Typography component={'span'} variant={'inherit'} color={'primary'}>
              {' everyone'}
            </Typography>
          </Typography>
          <Typography variant={'h7'} align={'center'} color={'#666666'}>
                        Our mission is to support athletes and elevate their experience.
                        We are building a community of individuals, teams, and clubs so everyone can play anywhere
                        and at anytime.
          </Typography>
            <Box mt={5} display={"flex"} justifyContent={"space-evenly"}>
                <Link to={"/terms"}>
                    <Typography> Terms and conditions</Typography>
                </Link>
                <Link to={"/privacy"}>
                    <Typography> Privacy policy</Typography>
                </Link>
            </Box>
        </Box>
      </Paper>
      <Paper

        sx={{
          ...paperContainerSx,
          bgcolor: 'white',
        }}
        elevation={0}
      >
        <Box display={'flex'} flexDirection={'column'} maxWidth={'500px'} justifySelf={'self-start'}>
          <Typography variant={'h4'} fontWeight={600} mb={2}>
                        Our ultimate goal
          </Typography>
          <Typography variant={'h7'} color={'#666666'}>
                        We aim to give everyone in the community an experience similar to that of professional athletes.
                        Everyone gets a chance to showcase their skills and share it with the world by supporting them with
                        our recorded games, player stats, and personalised profiles. We plan to organise games, tournaments, and leagues
                        in every city so everyone can participate regardless of their location or availability. <br/> <br/>
                        All of that is achievable cultivating our values:
          </Typography>
          <List dense>
            <ListItem>
              <CheckCircleIcon color={'primary'}/>
              <Typography marginLeft={1} variant={'subtitle1'} fontWeight={'bold'}>
                                User obsessed. We put our users front and center.
              </Typography>
            </ListItem>
            <ListItem>
              <CheckCircleIcon color={'primary'}/>
              <Typography marginLeft={1} variant={'subtitle1'} fontWeight={'bold'}>
                                Integrity. Our strong morals sprout honesty in our work.
              </Typography>
            </ListItem>
            <ListItem>
              <CheckCircleIcon color={'primary'}/>
              <Typography marginLeft={1} variant={'subtitle1'} fontWeight={'bold'}>
                                Trust. Our users believe in us.
              </Typography>
            </ListItem>
            <ListItem>
              <CheckCircleIcon color={'primary'}/>
              <Typography marginLeft={1} variant={'subtitle1'} fontWeight={'bold'}>
                                Excellence. We are aiming high and we know it.
              </Typography>
            </ListItem>
          </List>
        </Box>
      </Paper>
      <Paper

        sx={{...paperContainerSx, bgcolor: '#eeefff61'}}
      >
        <Box display={'flex'} flexDirection={'column'} maxWidth={'500px'} justifySelf={'self-start'} minWidth={'100%'} >
          <Typography variant={'h4'} fontWeight={600} mb={3}>
                        How can you support us?
          </Typography>
          <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} flexWrap={'wrap'}>

            <Card sx={{minWidth: 250, maxWidth: 400, m: 1}}>
              <CardContent>
                <Box display={'flex'} alignItems={'center'} marginBottom={1}>
                  <ForumIcon color={'primary'}/>
                  <Typography variant={'h6'} gutterBottom marginLeft={1} fontWeight={600}>
                                    Give feedback
                  </Typography>
                </Box>
                <Typography variant={'h7'} color={'#666666'}>
                               Tell us what and where we can improve or share your happy moments with us!
                               Your feedback is valuable for our growth and we'd love to hear from you! <br/> <br/>
                               And lastly, from time to time, we send our community a survey for more structured feedback,
                               you're always invited to participate to share your thoughts.
                </Typography>
              </CardContent>
              <CardActions>
                <Box
                  sx={{
                    'margin': '10px',
                    '&:hover': {
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }}
                >
                  <a
                    style={{textDecoration: 'none', color: 'inherit'}}
                    target="_blank"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSd3E1-Z9l4t0WMrymYUwkNqDtBiqi_LM0Rwc9Ss_OeLplv9vA/viewform?usp=sf_link"
                    rel="noreferrer"
                  >
                    <Typography variant={'subtitle1'} color={'primary'}>
                      {'Leave your feedback \xa0 \xa0 \xa0 \xa0 >'}
                    </Typography>
                  </a>
                </Box>
              </CardActions>
            </Card>
            <Card sx={{minWidth: 250, maxWidth: 400, m: 1}}>
              <CardContent>
                <Box display={'flex'} alignItems={'center'} marginBottom={1}>
                  <PeopleIcon color={'primary'}/>
                  <Typography variant={'h6'} gutterBottom marginLeft={1} fontWeight={600}>
                                        Join the community
                  </Typography>
                </Box>
                <Typography variant={'h7'} color={'#666666'}>
                                    Become a member of a huge community of athletes and people just like you.
                                    You can:
                </Typography>
                <List dense>
                  <ListItem>
                    <Typography fontSize={'15px'}>
                      {'\u2022'} Join a game by finding one that suits you.
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography fontSize={'15px'}>
                      {'\u2022'} Host and organise a game for others.
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography fontSize={'15px'}>
                      {'\u2022'} Create your own team and invite your mates.
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography fontSize={'15px'}>
                      {'\u2022'} Organise tournaments or join them with your team.
                    </Typography>
                  </ListItem>
                </List>
              </CardContent>
              <CardActions>
                <Box
                  sx={{
                    'margin': '10px',
                    '&:hover': {
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }}
                >
                  <Link to={'/games'} style={{textDecoration: 'none', color: 'inherit'}}>
                    <Typography variant={'subtitle1'} color={'primary'}>
                      {'Find a game now \xa0 \xa0 \xa0 \xa0 >'}
                    </Typography>
                  </Link>
                </Box>
              </CardActions>
            </Card>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};
