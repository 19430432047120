import React from 'react';
import {Box, FormControlLabel,
  FormLabel, Radio, RadioGroup} from '@mui/material';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

export const PriceRadio = (props) => {
  const {t} = useTranslation();
  return (
    <Box>
      <FormLabel component="legend">{t('common.paymentType')}</FormLabel>
      <RadioGroup
        aria-label="payment"
        defaultValue="free"
        name="payment"
        row
        onChange={props.onChange}
        value={props.value}
      >
        <FormControlLabel value="free" control={<Radio />} label={t('common.free')} />
        {/*<FormControlLabel*/}
        {/*  value="online"*/}
        {/*  control={<Radio />}*/}
        {/*  label={t('common.online')}*/}
        {/*/>*/}
          <FormControlLabel
              value="cash"
              control={<Radio />}
              label={t('common.cash')}
          />
      </RadioGroup>
    </Box>
  );
};

PriceRadio.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};
