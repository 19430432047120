import * as React from 'react';
import {Container} from '@mui/material';
import {useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {TeamDetailsView} from '../../components/teams/TeamDetailsView';
import {fetchPublicProfile, fetchTeam, fetchTeamPlayers} from '../../helpers';
import axios from 'axios';
import {AlertSnackBar} from '../../components/game/AlertSnackBar';
import {DeleteTeamConfirmation} from '../../components/teams/DeleteTeamConfirmation';
import {useTranslation} from 'react-i18next';

export const Team = () => {
  const {t} = useTranslation();
  const params = useParams();
  const id = params.id;
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const [team, setTeam] = useState({});
  const [isCreator, setIsCreator] = useState(false);
  const [players, setPlayers] = useState([]);
  const [invites, setInvites] = useState([]);
  const [captain, setCaptain] = useState({});

  const [deleteTeamConfirmationOpen, setDeleteTeamConfirmationOpen] =
      useState(false);
  const [deleteTeamSnackBarOpen, setDeleteTeamSnackBarOpen] = useState(false);

  const authToken = cookies.auth;

  const updateTeam = async () => {
    const authToken = cookies.auth;
    try {
      const team = await fetchTeam(id, authToken);
      setTeam(team);
      setIsCreator(team.isCreator);

      const players = await fetchTeamPlayers(id);
      setPlayers(players);

      if (team.isCreator) {
        const teamInvites = await fetchTeamInvites(id, authToken);
        setInvites(teamInvites);
      }
    } catch (error) {
      // TODO display not found page
      if (error.status === 404) {
      }
      console.error(error);
    }
  };


  useEffect(()=> {
    updateTeam();
  }, []);

  const deleteTeam = () => {
    axios
        .delete(`${process.env.REACT_APP_API_URL}/teams/${id}`,
            authToken == null? null :
                {headers: {'Authorization': authToken}},
        )
        .then((res) => {
          setDeleteTeamConfirmationOpen(false);
          setDeleteTeamSnackBarOpen(true);
        })
        .catch( (err) => {
          console.error(err.message);
          console.error(err);
        });
  };

  const onDeleteTeam = () => {
    setDeleteTeamConfirmationOpen(true);
  };

  const closeDeleteTeamConfirmation = () => {
    setDeleteTeamConfirmationOpen(false);
  };

  return (
    <Container>
      {team && (
        <TeamDetailsView
          team={team}
          isCreator={isCreator}
          players={players}
          invites={invites}
          onDelete={onDeleteTeam}
        />
      )}

      <DeleteTeamConfirmation
        open={deleteTeamConfirmationOpen}
        onClose={closeDeleteTeamConfirmation}
        onConfirm={deleteTeam}
      />
      <AlertSnackBar
        message={t('alerts.deletedTeam')}
        severity={'warning'}
        open={deleteTeamSnackBarOpen}
        onClose={() => setDeleteTeamSnackBarOpen(false)}
        autoHide
      />
    </Container>
  );
};
