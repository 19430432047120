import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import {TimeDisplay} from '../TimeDisplay';
import {Box, CardContent, Chip, Divider, Skeleton, useMediaQuery} from '@mui/material';
import {Link} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import StarRateIcon from '@mui/icons-material/StarRate';
import LockIcon from '@mui/icons-material/Lock';
import Grid2 from '@mui/material/Unstable_Grid2';
import {getSimpleDate} from '../util';
import {useTranslation} from 'react-i18next';
import {fetchGamePlayers, fetchPublicProfile, fetchVenue} from '../../../helpers';
import {trackEvent} from '../../../analytics';

export const CompactGameCard = ({id, organiserId, venueId, price, paymentCurrency, title, size, dateTime, rating, isPrivate, showDate}) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [organiser, setOrganiser] = useState(null);
  const [venue, setVenue] = useState(null);
  const [spotsLeft, setSpotsLeft] = useState(0);

  const updateVenue = async () => {
    try {
      const venue = await fetchVenue(venueId);
      setVenue(venue);
    } catch (e) {
      console.error(e);
    }
  };

  const updateOrganiser = async () => {
    try {
      const organiser = await fetchPublicProfile(organiserId);
      setOrganiser(organiser);
    } catch (e) {
      console.error(e);
    }
  };

  const updatePlayers = async () => {
    try {
      const players = await fetchGamePlayers(id);
      setSpotsLeft(size - players.length);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(()=> {
    updateVenue();
    updateOrganiser();
    updatePlayers();
  }, []);

  return (
    <Grid2 xs={12} md={6} lg={4} mb={2} style={{minWidth: smallScreen? 0 : '350px'}}>
      <Link
        to={`/games/${id}`}
        style={{textDecoration: 'none'}}
        onClick={() => trackEvent(
            'View Game',
            {action: 'Game card click', gameId: id, venue: venue.name, dateTime: dateTime},
        )}
      >
        <Card mt={2} p={0.5} elevation={0}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box display={'flex'} alignItems={'center'}>
              {isPrivate && <LockIcon sx={{alignSelf: 'center', mb: 0.5}} color={'infoIcon'}/>}
              {isPrivate && <Typography variant={'h6'} mb={1}>&nbsp;</Typography>}
              <Typography color={'black'} variant={'h6'} fontWeight={'bold'}>
                {title}
              </Typography>
            </Box>
            <TimeDisplay horizontal variant={'h6'} dateTime={dateTime} />
          </Box>
          <Box display={'flex'} justifyContent={'space-between'}>
            {venue?
                <Typography variant={'h7'} color={'black'} >
                  {venue.name}
                </Typography> :
                <Skeleton variant={'text'} width={150} animation={'wave'}/>
            }

            <Box alignItems={'center'} display={'flex'} style={{color: '#222222'}} ml={1}>
              <StarRateIcon/>
              <Typography variant={smallScreen? 'subtitle1': 'h7'}>
                {rating === 0? t('common.any') : rating}
              </Typography>
            </Box>
          </Box>
          {organiser ?
              <Typography variant={'subtitle1'} color={'#222222'}>
                {t('games.gameFormat', {size: size/2, organiser: organiser.username})}
              </Typography> :
              <Skeleton width={100} variant={'text'} animation={'wave'}/>
          }
          {showDate && <Typography variant={'subtitle1'} color={'#222222'}>{getSimpleDate(dateTime)}</Typography>}
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography
              variant="subtitle1"
              style={{color: 'black'}}
              sx={{fontWeight: 'bold'}}
            >
              {price === 0? t('common.free') : price.toLocaleString() + ' ' + paymentCurrency}
            </Typography>
            <Chip
              label={size - spotsLeft + '/' + size}
              style={{
                fontSize: '12px',
                fontWeight: 'bold',
                minWidth: '55px',
                maxHeight: '22px',
                borderRadius: '6px',
              }}
              color={spotsLeft === 0? 'default' : 'secondary'}
            />
          </Box>
        </Card>
      </Link>
    </Grid2>
  );
};

CompactGameCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  price: PropTypes.number,
  time: PropTypes.string,
  dateTime: PropTypes.string,
  size: PropTypes.number,
  rating: PropTypes.number,
  isPrivate: PropTypes.bool,
  showDate: PropTypes.bool,
};
