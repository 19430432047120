import React from 'react';
import PropTypes from 'prop-types';
import {Box, TextField} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {DatePicker, TimePicker} from '@mui/x-date-pickers';
import {useTranslation} from 'react-i18next';

export const DateTimePicker = (props)=> {
  const {t} = useTranslation();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          display: 'flex',
          marginTop: '5px',
        }}
      >
        <Box sx={{marginRight: '2px'}}>
          <DatePicker
            label={t('common.date')}
            value={props.dateValue}
            onChange={props.onDateChange}
            renderInput={(params) => <TextField {...params} />}
            minDate={Date.now()}
          />
        </Box>
        <Box sx={{marginLeft: '2px'}}>
          <TimePicker
            label={t('common.time')}
            value={props.timeValue}
            onChange={props.onTimeChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
      </Box>
    </LocalizationProvider>
  );
};
DateTimePicker.propTypes = {
  dateValue: PropTypes.instanceOf(Date),
  onDateChange: PropTypes.func,
  timeValue: PropTypes.instanceOf(Date),
  onTimeChange: PropTypes.func,
};
