import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {Box, Container, Typography, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material/styles';
export const SignUpContainer = (props) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container component="main" maxWidth="xs" sx={{minHeight: '100vh'}}>
      <Box
        sx={{
          marginTop: smallScreen? 0.5: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{m: 3, bgcolor: 'secondary.main'}}>
          <LockOutlinedIcon/>
        </Avatar>
        <Typography component="h1" variant="h5">
          {props.title}
        </Typography>
        <Box
          sx={{
            mt: 2,
            padding: '30px',
            bgcolor: 'background.paper',
            borderColor: 'border.light',
          }}
          borderRadius="6px"
          border={1}
        >
          {props.children}
        </Box>
      </Box>
    </Container>
  );
};

SignUpContainer.propTypes = {
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
};
