import React, {useEffect} from 'react';
import {NavButton} from './NavButton';
import {Logo} from '../buttons/Logo';
import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {logOut} from '../../redux/actions';
import {useCookies} from 'react-cookie';
import {LinkText} from './LinkText';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';
import {LanguageSwitcher} from '../LanguageSwitcher';
import {useTranslation} from 'react-i18next';
export const NavBar = () => {
  const {t} = useTranslation();
  const isLoggedIn = useSelector((state) => state.isLogged);
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  useEffect(()=> {
    console.log('Logged in status changed');
  }, [isLoggedIn]);

  return (
    <AppBar
      position="static"
      color="white"
      sx={{maxHeight: '70px'}}
    >
      <Box display={'flex'} alignItems={'center'}>
        <Logo/>
        {/* Box on the left of the navigation bar.*/}
        {/* Takes twice as much space as box on the right.*/}
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexGrow: 2,
          }}
        >

          <LinkText to={'/'} text={t('common.home')}/>
          <LinkText to={'/games'} text={t('common.games')}/>
          <LinkText to={'/tournaments'} text={t('common.tournaments')}/>
          <LinkText to={'/teams'} text={t('common.teams')}/>
          <LinkText to={'/about'} text={t('common.about')}/>
        </Box>
        {/* Box on the right of the navigation bar.*/}
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            flexGrow: 1,
          }}
        >
          {/* <LanguageSwitcher/>*/}
          {isLoggedIn?
            <React.Fragment>
              <Box>
                <IconButton onClick={handleClick} size="small" sx={{ml: 2}}>
                  <MenuRoundedIcon fontSize={'large'}/>
                </IconButton>
              </Box>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    'overflow': 'visible',
                    'filter': 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    'mt': 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
              >
                <MenuItem>
                  <Box
                    sx={{
                      'margin': '10px',
                      '&:hover': {
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                    onClick={()=> {
                      window.location.replace('https://docs.google.com/forms/d/e/1FAIpQLSd3E1-Z9l4t0WMrymYUwkNqDtBiqi_LM0Rwc9Ss_OeLplv9vA/viewform?usp=sf_link');
                    }}
                  >
                    <Typography variant='h6'>
                      {t('common.contact')}
                    </Typography>
                  </Box>
                </MenuItem>
                <MenuItem component={Link} to={'/myprofile'}>
                  <NavButton text={t('common.profile')}/>
                </MenuItem>
                <Divider />
                <MenuItem>
                  <Button
                    color="error"
                    onClick={()=> {
                      dispatch(logOut());
                      removeCookie('auth', {domain:'forza90.com'});
                    }}
                  >
                    {t('common.signOut')}
                  </Button>
                </MenuItem>
              </Menu>
            </React.Fragment> :
            <>
              <LinkText to={'/signin'} text={t('common.signIn')}/>
              <LinkText to={'/signup'} text={t('common.signUp')}/>
            </>
          }
        </Box>
      </Box>
    </AppBar>
  );
};
