import React from 'react';
import PropTypes from 'prop-types';
import {Box, Divider, Stack, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {TournamentRoundCard} from './TournamentRoundCard';

export const TournamentRoundCardsView = ({rounds}) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Stack
      sx={{
        // mt: 3,
        // mb: 2,
        bgcolor: 'background.paper',
      }}
      display={'flex'}
      justifyContent={'center'}
      flexDirection={'column'}
      p={smallScreen? 1: 2}
      spacing={2}
      // alignSelf={smallScreen? 'center' : 'flex-start'}
      // width={smallScreen? '100%' : '95%'}
    >
      {rounds.map((round) =>
        <TournamentRoundCard
          key={round.id}
          round={round}
        />,
      )}
    </Stack>
  );
};

TournamentRoundCardsView.propTypes = {
  rounds: PropTypes.array,
};
