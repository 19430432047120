import React, {useEffect, useState} from 'react';
import {Avatar} from '@mui/material';
import {fetchPublicProfile} from '../../../helpers';

export const SmallPlayerAvatar = ({userId}) => {
  const [username, setUsername] = useState(null);
  const [profilePictureUrl, setProfilePictureUrl] = useState(null);

  const updatePlayer = async () => {
    try {
      const profile = await fetchPublicProfile(userId);
      setProfilePictureUrl(profile.profilePictureUrl);
      setUsername(profile.username);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(()=> {
    updatePlayer();
  }, []);

  return (
    <Avatar
      sx={{width: 20, height: 20, fontSize: 12}}
      key={userId}
      alt={username}
      src={profilePictureUrl}
    >
      {
        username && username.charAt(0).toUpperCase()
      }
    </Avatar>
  );
};
