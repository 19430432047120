import React, {useEffect, useState} from 'react';
import {
  Backdrop,
  Box,
  Button, CircularProgress, Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {AlertSnackBar} from '../game/AlertSnackBar';
import 'react-phone-input-2/lib/material.css';
import PhoneInput from 'react-phone-input-2';
import validator from 'validator';
import {useTranslation} from 'react-i18next';
import {trackEvent} from '../../analytics';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {fetchUserTournamentTeam} from '../../helpers';
import {useCookies} from 'react-cookie';

export const CreateTeam = (props) => {
  const {t} = useTranslation();
  const [contact, setContact] = useState('');
  const [invalidInput, setInvalidInput] = useState(false);
  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
  const [backDropOpen, setBackDropOpen] = useState(false);
  const isLoggedIn = useSelector((state) => state.isLogged);
  const navigate = useNavigate();
  const params = useParams();
  const tournamentId = params.tournamentId;
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const authToken = cookies.auth;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorSnackBarOpen(false);
    setInvalidInput(false);
    const data = new FormData(event.currentTarget);
    trackEvent(
        'Create team attempt',
        {action: 'Create team', teamName: data.get('name'), contact: contact},
    );
    if (contact == null || !validator.isMobilePhone(contact)) {
      setErrorSnackBarOpen(true);
      setInvalidInput(true);
      trackEvent(
          'Failed create team ',
          {action: 'Create team', teamName: data.get('name'), contact: contact, cause: 'Invalid Input'},
      );
      return;
    }
    const team = {
      name: data.get('name'),
      contact,
    };


    props.onCreate(team);
  };

  const checkUserTournamentTeamExists = async () => {
    const userTeam = await fetchUserTournamentTeam(authToken, tournamentId);
    if (userTeam != null) {
      setBackDropOpen(true);
      setTimeout(function() {
        navigate(`/tournaments/${tournamentId}`);
      }, 4000);
    }
  };

  useEffect(()=> {
    checkUserTournamentTeamExists();
  }, []);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} >
      {/* <Typography variant={smallScreen?'h5': 'h4'} m={smallScreen? 2 : 6}>*/}
      {/*  {t('teams.create')}*/}
      {/* </Typography>*/}
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'background.paper',
          borderColor: 'border.light',
          padding: '30px',
          width: '100%',
          boxSizing: 'border-box',
        }}
        borderRadius="6px"
        border={1}
      >

        <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}}>
          <Typography variant={'subtitle1'} color={'gray'}>
            {t('teamCreateFlow.teamNamePrompt')}
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label={t('teamCreateFlow.nameLabel')}
            name="name"
            autoFocus
            sx={{mb: 3}}
          />
          <Typography sx={{mb: 1}} variant={'subtitle1'} color={'gray'}>
            {t('teamCreateFlow.contactNumberPrompt')}
          </Typography>
          <PhoneInput
            inputStyle={invalidInput? {color: 'red', borderColor: 'red'} : {}}
            country={'lb'}
            // placeholder='Enter phone number'
            value={contact}
            specialLabel={''}
            onChange={setContact}
          />

          <Button
            color={'secondary'}
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 3, mb: 2}}
          >
            {t('common.create')}
          </Button>
        </Box>
      </Box>
      <AlertSnackBar
        message={invalidInput? t('errors.addressErrorsMessage') : t('errors.genericErrorMessage')}
        severity={'error'}
        open={errorSnackBarOpen}
        onClose={() => setErrorSnackBarOpen(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHide={false}
      />
      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={backDropOpen}
      >
        <Stack sx={{p: 8}} alignItems={'center'} bgcolor={'white'} spacing={3}>
          <Typography variant={'h4'}> You already have a team for this tournament.</Typography>
          <CircularProgress color="inherit" sx={{mt: 1}}/>
          <Typography> Returning to tournament page</Typography>
        </Stack>
      </Backdrop>
    </Box>
  );
};
