import React from 'react';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

export const AuthenticatedRoute = ({children, authRoute = '/signin'}) => {
  const isLoggedIn = useSelector((state) => state.isLogged);
  const location = useLocation();
  if (!isLoggedIn) {
    return <Navigate to={authRoute} replace state={{from: location}} />;
  }
  return children;
};
