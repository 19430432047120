import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';
import {ChooseTeamComponent} from '../../teams/ChooseTeamComponent';

export const AddTournamentRoundTeamDialog = ({teams, onClose, open, onConfirm}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [selectedTeamIndex, setSelectedTeamIndex] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);

  useEffect(()=> {
    updateSelectedTeam();
  }, [open, selectedTeamIndex]);
  /**
     * if there is only one team, it is selected.
     */
  const updateSelectedTeam = () => {
    let team = null;
    if (teams.length > 1 && selectedTeamIndex !== null) {
      team = teams[selectedTeamIndex];
    } else if (teams.length === 1) {
      team = teams[0];
    }
    setSelectedTeam(team);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setSelectedTeamIndex(null);
          onClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          {teams.length > 0 && (
            <ChooseTeamComponent
              onChoose={(index) => setSelectedTeamIndex(index)}
              teams={teams}
              teamSize={1}
              selectedTeamIndex={selectedTeamIndex}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color={'error'}>
            {t('common.cancel')}
          </Button>
          <LoadingButton
            loading={false}
            onClick={() => onConfirm(selectedTeam.id)}
            autoFocus
            color={'success'}
          >
            {t('common.join')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AddTournamentRoundTeamDialog.propTypes = {
  teams: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};
