import React from 'react';

const TermsAndConditions = () => {
    const htmlContent = `
    <h1>Terms and Conditions</h1>
    <p><strong>Last Updated:</strong> October 4, 2024</p>

    <p>Welcome to Forza90, a platform operated by Forza90 LTD ("we," "us," or "our"). By accessing or using the Forza90 platform, you agree to comply with and be bound by the following terms and conditions. If you do not agree to these terms, please do not use the platform.</p>

    <h2>1. Acceptance of Terms</h2>
    <p>By creating an account or using Forza90 in any capacity, you agree to these Terms and Conditions, as well as our Privacy Policy. These terms apply to all users of the platform, including players, organizers, and other participants.</p>

    <h2>2. Account Registration</h2>
    <p>To access certain features of Forza90, you must create an account. You agree to provide accurate and up-to-date information during registration. You are responsible for maintaining the confidentiality of your account and password. Forza90 is not liable for any unauthorized use of your account.</p>

    <h2>3. Use of the Platform</h2>
    <p>You agree to use the Forza90 platform for lawful purposes only. Any misuse of the platform, including but not limited to harassment, inappropriate behavior, or fraudulent activity, is strictly prohibited. We reserve the right to suspend or terminate your account if you violate these terms.</p>

    <h3>Organizing and Joining Games</h3>
    <p>Forza90 allows users to organize and join football games. Game organizers are responsible for ensuring the accuracy of game details (time, location, etc.). Forza90 is not responsible for the actions or conduct of any participants.</p>

    <h2>4. Fees and Payments</h2>
    <p>Certain features of Forza90, such as booking or organizing games, may involve fees. You agree to pay all applicable fees associated with your use of the platform. Fees are non-refundable unless otherwise specified. Forza90 reserves the right to modify fees at any time, with notice provided before changes take effect.</p>

    <h2>5. Cancellation and Refunds</h2>
    <p>Cancellation policies may vary by game or event. Organizers may have their own policies for refunds or cancellations. Forza90 is not responsible for refunds or disputes between users. Please review the specific terms provided by the game organizer before booking.</p>

    <h2>6. Limitation of Liability</h2>
    <p>Forza90 provides a platform to connect users for football games. We are not responsible for any injuries, losses, or damages that occur before, during, or after any game. You agree to participate in games at your own risk.</p>

    <h2>7. Intellectual Property</h2>
    <p>All content, logos, and trademarks displayed on Forza90 are the property of Forza90 LTD or its licensors. You may not use any of the platform's content without explicit permission from Forza90 LTD.</p>

    <h2>8. Modifications to the Terms</h2>
    <p>We reserve the right to modify these Terms and Conditions at any time. Any changes will be posted on this page with an updated "Last Updated" date. Your continued use of the platform after changes are made will constitute acceptance of those changes.</p>

    <h2>9. Governing Law</h2>
    <p>These Terms and Conditions are governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising from or related to the use of the platform shall be resolved in the courts of [Your Jurisdiction].</p>

    <h2>10. Contact Us</h2>
    <p>If you have any questions about these Terms and Conditions, please contact us at:</p>
    <p><strong>Email:</strong> support@forza90.co.uk</p>
  `;

    return (
        <div style={{padding: 20}}>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </div>
    );
};

export default TermsAndConditions;
