import React from 'react';
import {IconButton} from '@mui/material';
import Typography from '@mui/material/Typography';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import RefreshIcon from '@mui/icons-material/Refresh';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {useTranslation} from 'react-i18next';

export const TeamInvite = (props) => {
  const {t} = useTranslation();

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: '10px',
      }}
      justifyContent={'start'}
      minWidth={'150px'}
    >
      <EmailOutlinedIcon style={{fill: '#f8a542'}} sx={{width: '30px', height: '35px'}}/>
      <Typography style={{margin: '7px'}} >{props.name}</Typography>
      <Box display={'flex'} flexDirection={'row'}>
        <IconButton
          aria-label={t('teams.resendInvite')}
          onClick={props.onResend}
          color={'primary'}
        >
          <RefreshIcon/>
        </IconButton>
        <IconButton
          aria-label={t('teams.cancelInvite')}
          onClick={props.onDelete}
          color={'error'}
        >
          <ClearIcon/>
        </IconButton>
      </Box>
    </Box>
  );
};

TeamInvite.propTypes = {
  name: PropTypes.string,
  onDelete: PropTypes.func,
  onResend: PropTypes.func,
};
