import * as React from 'react';
import {
  Box,
  Divider,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {useParams} from 'react-router-dom';
import {
  formatDate,
  formatTime,
} from '../../game/util';
import {
  AccessTime,
  EventOutlined,
  Payment,
} from '@mui/icons-material';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useTheme} from '@mui/material/styles';
import SportsIcon from '@mui/icons-material/Sports';
import QRCode from 'react-qr-code';
import {MapComponent} from '../../game/GameView/MapComponent';
import {GameDetailsIcon} from '../../game/GameView/GameDetailsIcon';
import {GameInfoBox} from '../../containers/GameInfoBox';
import {TeamCard} from '../../teams/TeamCard';
import {useTranslation} from 'react-i18next';
import {TournamentDescription} from '../TournamentDescription';
import {TournamentRoundDetailsHeader} from './TournamentRoundDetailsHeader';
import {fetchVenue} from '../../../helpers';
import {useEffect, useState} from 'react';

export const TournamentRoundDetailsView = ({tournament, round, teams, isOrganiser}) => {
  const params = useParams();
  const {t} = useTranslation();

  const id = params.id;
  const tournamentId = params.tournamentId;
  const name = round.name;
  const size = round.size;
  const teamSize = tournament.teamSize;
  const price = round.price;
  const isCancelled = round.isCancelled;
  const dateTime = moment(round.dateTime);
  const isPast = moment().isAfter(dateTime);
  const roundUrl = `${process.env.REACT_APP_APP_URL}/tournaments/${tournamentId}/rounds/${id}`;

  const [venue, setVenue] = useState(null);

  const updateVenue = async () => {
    try {
      const roundVenue = await fetchVenue(round.venueId);
      setVenue(roundVenue);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(()=> {
    updateVenue();
  }, []);


  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Paper elevation={0}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          p={smallScreen? 0 : 2}
        >
          <TournamentRoundDetailsHeader
            id={id}
            tournamentId={tournamentId}
            tournamentName={tournament.name}
            name={name}
            startDate={round.dateTime}
            onCancel={() => {}}
            isOrganiser={isOrganiser}
            isCancelled={isCancelled}
            isPast={isPast}
            location={venue? venue.location : 'here'}
          />

          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-evenly'}
            alignItems={'center'}
            mb={2}
          >
            <GameDetailsIcon
              icon={SportsIcon}
              text={t('common.playersAside', {teamSize: teamSize})}
            />
            <GameDetailsIcon
              icon={EventOutlined}
              text={formatDate(round.dateTime)}
            />
            <GameDetailsIcon
              icon={AccessTime}
              text={formatTime(round.dateTime)}
            />
            <GameDetailsIcon
              icon={Payment}
              text={price === 0?
                  t('common.free') : price.toPrecision(3) + 'GBP' // todo fix
              }
            />
          </Box>

          <Box
            display={'flex'}
            flexDirection={'column'}
            p={smallScreen? 1: 2}
          >
            <Box display={'flex'} justifyContent={'center'}>
              <Typography variant={'h5'} sx={{mb: 1}}>
                {t('tournament.teamsParticipating')}
              </Typography>
              {teams &&
                <Typography variant={'h6'} fontWeight={'bold'}>
                  &nbsp;({`${teams.length}/${size}`})
                </Typography>
              }
            </Box>
            <GameInfoBox style={{alignSelf: 'center'}}>
              {teams.map((teamId)=>
                <div key={teamId}>
                  <TeamCard
                    id={teamId}
                  />
                  <Divider/>
                </div>,
              )}
            </GameInfoBox>
          </Box>
          {venue && <MapComponent location={venue.name}/>}
          <TournamentDescription
            description={round.description}
          />
          <Box display={'flex'} flexDirection={'column'} mb={3}>
            <Typography variant={'h6'} mb={2} p={smallScreen? 1: 2}>
              {t('common.qrCodeShare')}
            </Typography>
            <Box alignSelf={'center'} mb={10}>
              <QRCode value={roundUrl}/>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

TournamentRoundDetailsView.propTypes = {
  teams: PropTypes.array,
  isOrganiser: PropTypes.bool,
  hasJoined: PropTypes.bool,
  onCancel: PropTypes.func,
  onLeave: PropTypes.func,
  onJoin: PropTypes.func,
  onInvite: PropTypes.func,
};
