import React from 'react';
import {Box, Typography, useMediaQuery} from '@mui/material';
import {formatTime, getSimpleDate} from '../../game/util';
import PropTypes from 'prop-types';
import {useTheme} from '@mui/material/styles';
import {LocationOn} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import {TournamentRoundOptionsMenu} from './TournamentRoundOptionsMenu';
import moment from 'moment';

export const TournamentRoundDetailsHeader = (
    {tournamentId, id, startDate, isCancelled, isOrganiser, onCancel, isPast, name, location, tournamentName},
) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const headerColour = isCancelled? '#c00e0e': isPast? '#727272' : '#67a368';
  return (
    <Box display={'flex'} flexDirection={'column'} p={smallScreen? 1: 2}>
      {isCancelled &&
            <Typography variant={'subtitle1'} color={'#c00e0e'}>
              {t('tournament.cancelled')}
            </Typography>
      }
      {!isCancelled && isPast &&
            <Typography variant={'subtitle1'} color={'#727272'}>
              {t('tournament.past')}
            </Typography>
      }

      <Typography variant={smallScreen? 'h5' : 'h6'} fontWeight={'bolder'} textAlign={'center'} color={'secondary'}>
        {`${tournamentName}`}
      </Typography>

      <Box
        my={2}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'}>
          <Typography variant={smallScreen? 'h3' : 'h4'} fontWeight={'bolder'}>
            {`${name}`}
          </Typography>

        </Box>
        {isOrganiser && (
          <TournamentRoundOptionsMenu
            tournamentId={tournamentId}
            roundId={id}
            onCancel={onCancel}
            disabled={isCancelled || isPast}
          />
        )}
      </Box>
      <Box
        sx={{borderColor: headerColour, color: headerColour}}
        alignSelf={'flex-start'}
        p={1}
        border={1}
        borderRadius={1}
      >
        <Typography variant={'h7'}>
          {`${getSimpleDate(moment(startDate))} • ${formatTime(startDate)}`}
        </Typography>
      </Box>
      <Box display={'flex'} mt={1}>
        <LocationOn
          color={'blue'}
        />
        <Typography variant={'subtitle1'} color={'gray'}>
          {location}
        </Typography>
      </Box>
    </Box>
  );
};

TournamentRoundDetailsHeader.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  dateTime: PropTypes.object,
  isOrganiser: PropTypes.bool,
  isPrivate: PropTypes.bool,
  isPast: PropTypes.bool,
  isCancelled: PropTypes.bool,
  onCancel: PropTypes.func,
  location: PropTypes.string,
};
