import React, {useEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {Box, Button, Container, Stack, Typography, useMediaQuery} from '@mui/material';
import {useSelector} from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import {LoadingButton} from '@mui/lab';
import {fetchTeam, joinTeam} from '../../helpers';
import {useCookies} from 'react-cookie';
import {AlertSnackBar} from '../../components/game/AlertSnackBar';
import EmailIcon from '@mui/icons-material/Email';
import {SignUpComponent} from '../../components/registration/SignUpComponent';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {useTheme} from '@mui/material/styles';
import {SignInComponent} from '../../components/registration/SignInComponent';
import {useTranslation} from 'react-i18next';

export const AcceptInvite = () => {
  const {t} = useTranslation();
  const params = useParams();
  const id = params.id;
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const [team, setTeam] = useState({});
  const [joining, setJoining] = useState(false);
  const [isJoiningSuccessful, setIsJoiningSuccessful] = useState(false);
  const [hasAccount, setHasAccount] = useState(true);
  const [invalidToken, setInvalidToken] = useState(false);
  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
  const navigate = useNavigate();


  const isLoggedIn = useSelector((state) => state.isLogged);
  const [searchParams, setSearchParams] = useSearchParams();
  const inviteToken = searchParams.get('token');

  const onJoinTeam = async (newAuthToken) => {
    setJoining(true);
    const authToken = newAuthToken || cookies.auth;
    try {
      const res = await joinTeam(id, inviteToken, authToken);
      setInvalidToken(false);
      setErrorSnackBarOpen(false);
      setJoining(false);
      setIsJoiningSuccessful(true);
    } catch (error) {
      setJoining(false);
      if (error.response && error.response.status === 403) {
        setInvalidToken(true);
        setErrorSnackBarOpen(true);
      } else {
        setErrorSnackBarOpen(true);
      }
    }
  };

  const handleJoinWhenSignedIn = async () => {
    setJoining(true);
    const authToken = cookies.auth;
    try {
      const res = await joinTeam(id, inviteToken, authToken);
      setInvalidToken(false);
      setErrorSnackBarOpen(false);
      setJoining(false);
      setIsJoiningSuccessful(true);
    } catch (error) {
      setJoining(false);
      if (error.response && error.response.status === 403) {
        setInvalidToken(true);
        setErrorSnackBarOpen(true);
      } else {
        setErrorSnackBarOpen(true);
      }
    }
  };

  const updateTeam = async () => {
    try {
      const response = await fetchTeam(id, null);
      setTeam(response);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    updateTeam();
  }, [isLoggedIn]);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container>
      <Box
        sx={{
          marginTop: smallScreen? 0.5: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{m: 3, bgcolor: 'secondary.main'}}>
          <LockOutlinedIcon/>
        </Avatar>
        <Typography component="h1" variant="h5">
          {isJoiningSuccessful? t('teamInvite.joinedTeamTitle'): t('teamInvite.title')}
        </Typography>
        <Box
          sx={{
            mt: 2,
            padding: '30px',
            bgcolor: 'background.paper',
            borderColor: 'border.light',
          }}
          borderRadius="6px"
          border={1}
        >
          {isJoiningSuccessful? (
              <Stack alignItems="center" spacing={4}>
                <CheckIcon color={'success'} style={{fontSize: '120px'}} />
                <Typography>{t('teamInvite.joinedTeamCongrats')}</Typography>
                <Button variant={'outlined'} color={'secondary'} onClick={()=> navigate(`/teams/${id}`)}>
                  {t('teams.view')}
                </Button>
              </Stack>
          ) : (
              <div>
                <Stack alignItems="center" spacing={4} mb={2}>
                  <EmailIcon color={'secondary'} style={{fontSize: '120px'}} />
                  <Typography>
                    {t('teamInvite.invitation', {teamName: team.name})}
                  </Typography>
                </Stack>
                {!isLoggedIn && !hasAccount && (
                  <>
                    <Typography textAlign={'start'} variant={'body1'} color={'gray'}>
                      {t('teamInvite.signupText')}
                    </Typography>
                    <SignUpComponent
                      style={{padding: '0px'}}
                      signInButton={true}
                      onSignIn={()=> setHasAccount(true)}
                      signUpButtonText={t('teams.join')}
                      onSuccessfulSignup={onJoinTeam}
                      loading={joining}
                    />
                  </>
                )}
                {!isLoggedIn && hasAccount && (
                  <>
                    <Typography textAlign={'start'} variant={'body1'} color={'gray'}>
                      {t('teamInvite.signupText')}
                    </Typography>
                    <SignInComponent
                      style={{padding: '0px'}}
                      signUpButton={true}
                      onSignUp={()=> setHasAccount(false)}
                      signInButtonText={t('teams.join')}
                      onSuccessfulSignIn={onJoinTeam}
                      loading={joining}
                    />
                  </>
                )}
                {isLoggedIn && (
                  <LoadingButton
                    onClick={handleJoinWhenSignedIn}
                    fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                    loading={joining}
                    disabled={joining}
                  >
                    {t('teams.join')}
                  </LoadingButton>
                )}
              </div>
          )}
        </Box>
      </Box>
      <AlertSnackBar
        message={invalidToken?
                t('errors.invalidTeamInviteToken'): t('errors.genericErrorMessage')
        }
        severity={'error'}
        open={errorSnackBarOpen}
        onClose={() => setErrorSnackBarOpen(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHide={false}
        sx={{marginTop: '70px'}}
      />

    </Container>
  );
};
