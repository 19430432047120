import {Link} from 'react-router-dom';
import {Avatar} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import React from 'react';

export const PlayerAvatar = ({profilePictureUrl, userId}) => {
  return (
    <Link
      to={`/users/${userId}/profile`}
      style={{textDecoration: 'none'}}
    >
      <Avatar sx={{bgcolor: '#363636'}} src={profilePictureUrl}>
        <PersonIcon/>
      </Avatar>
    </Link>
  );
};
