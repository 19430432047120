export const fetchTimezoneFromCoordinates = async (latitude, longitude) => {
  const response = await fetch(`https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${Math.floor(Date.now() / 1000)}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);
  const data = await response.json();

  if (response.ok) {
    return data;
  } else {
    throw new Error(data.error_message || 'Error retrieving timezone');
  }
};
