import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
} from '@mui/material';
import {useCookies} from 'react-cookie';
import {useNavigate, useParams} from 'react-router-dom';
import {fetchTeam} from '../../helpers';
import {InvitePlayerComponent} from '../../components/teams/InvitePlayerComponent';
import {useTranslation} from 'react-i18next';

export const ManageTeamPlayers = () => {
  const {t} = useTranslation();
  const params = useParams();
  const id = params.id;
  const [teamName, setTeamName] = useState('');

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const navigate = useNavigate();

  const updateTeam = async () => {
    const authToken = cookies.auth;
    try {
      const team = await fetchTeam(id, authToken);
      if (!team.isCreator) {
        navigate(`/teams/${id}`);
      }
      setTeamName(team.name);
    } catch (e) {
      console.error(e);
    }
  };


  useEffect(()=> {
    updateTeam();
  }, []);

  return (
    <Container maxWidth={'md'} style={{justifyContent: 'center'}} >
      <Box margin={1.5} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
        <Typography variant='h4'>
          {teamName}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 3,
          mb: 2,
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'background.paper',
          borderColor: 'border.light',
          paddingY: '30px',

        }}
        borderRadius="6px"
        border={1}
      >
        <InvitePlayerComponent teamId={id}/>

        <Button
          color={'success'}
          onClick={()=> navigate(`/teams/${id}`)}
          sx={{alignSelf: 'flex-end'}}
        >
          {t('common.done')}
        </Button>
      </Box>
    </Container>
  );
};
