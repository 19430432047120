import React from 'react';
import Avatar from '@mui/material/Avatar';
import {Link} from 'react-router-dom';
import {Box, Typography, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';

export const OrganiserDetails = ({organiser}) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Link
      to={`/users/${organiser.userId}/profile`}
      style={{textDecoration: 'none'}}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        mx={2}
        mb={2}
        alignItems={'center'}
      >
        <Avatar
          sx={{
            bgcolor: 'rgb(237 27 36)',
            width: smallScreen? 50: 65,
            height: smallScreen? 50 : 65,
          }}
          alt={organiser.username}
          src={organiser.profilePictureUrl}
        >
          {
            organiser.username &&
                        organiser.username.charAt(0).toUpperCase()
          }
        </Avatar>
        <Box display={'flex'} flexDirection={'column'} ml={1}>
          <Typography variant={smallScreen? 'h7' : 'h6'} color={'black'}>
            {`${organiser.firstname} ${organiser.surname} 
            (${organiser.username})`}
          </Typography>
          <Typography variant={'subtitle1'} color={'gray'}>
            {t('common.organiser')}
          </Typography>

        </Box>
      </Box>
    </Link>
  );
};
