import React, {useState} from 'react';
import {AppBar, Box, IconButton} from '@mui/material';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {GoPerson} from 'react-icons/go';
import logo from '../../assets/blackRedLogo.png';
export const TopNavBar = () => {
  const isLoggedIn = useSelector((state) => state.isLogged);
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const navigate = useNavigate();
  const handleProfileClicked = ()=> {
    isLoggedIn? navigate('/myprofile') : navigate('/signin');
  };
  const handleToggle = () => {
    setIsBurgerMenuOpen(!isBurgerMenuOpen);
  };

  const handleMenuItemClicked = (path) => {
    navigate(path);
    setIsBurgerMenuOpen(false);
  };

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{px: 0.7, bgcolor: 'rgba(251,251,253,0.95)'}}
    >
      <Box
        display={'flex'}
        flexDirection={'row-reverse'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box display={'flex'} flexDirection={'row-reverse'} >
          <BurgerMenu onToggle={handleToggle} onMenuItemClicked={handleMenuItemClicked} isOpen={isBurgerMenuOpen} />
          {!isBurgerMenuOpen && (
            <IconButton aria-label="profile" onClick={handleProfileClicked}>
              <GoPerson fontSize={23} fontWeight={40} color={'black'}/>
            </IconButton>
          )}
        </Box>
        {!isBurgerMenuOpen &&
              <Link to={'/'} style={{textDecoration: 'none', color: 'inherit'}} >
                <Box
                  component="img"
                  sx={{
                    width: 40,
                  }}
                  marginTop={0}
                  alt="Forza90"
                  src={logo}
                />
              </Link>
        }
      </Box>
    </AppBar>
  );
};
import {useSelector} from 'react-redux';
import BurgerMenu from './BurgerMenu';
