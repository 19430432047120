import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from '@mui/material';
import {useCookies} from 'react-cookie';
import {useNavigate, useParams} from 'react-router-dom';
import {LoadingButton} from '@mui/lab';
import {AlertSnackBar} from '../../components/game/AlertSnackBar';
import {editTeam, fetchTeam} from '../../helpers';
import PhoneInput from 'react-phone-input-2';
import validator from 'validator';
import {useTranslation} from 'react-i18next';

export const EditTeam = () => {
  const {t} = useTranslation();
  const params = useParams();
  const id = params.id;
  const [teamName, setTeamName] = useState('');
  const [contact, setContact] = useState('');
  const [invalidInput, setInvalidInput] = useState(false);
  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const navigate = useNavigate();

  const updateTeam = async () => {
    const authToken = cookies.auth;
    try {
      const team = await fetchTeam(id, authToken);
      if (!team.isCreator) {
        navigate(`/teams/${id}`);
      } else {
        setTeamName(team.name);
        setContact(team.contact);
      }
    } catch (e) {
      console.error(e);
    }
  };


  useEffect(()=> {
    updateTeam();
  }, []);

  const handleSubmit = async (event) => {
    setSubmitting(true);
    event.preventDefault();
    setErrorSnackBarOpen(false);
    setInvalidInput(false);
    if (contact == null || !validator.isMobilePhone(contact)) {
      setErrorSnackBarOpen(true);
      setInvalidInput(true);
      setSubmitting(false);
      return;
    }
    const data = new FormData(event.currentTarget);
    const team = {
      name: data.get('name'),
      contact,
    };
    const authToken = cookies.auth;
    try {
      const response = await editTeam(id, team, authToken);
      setSubmitting(false);
      setSuccess(true);
      navigate(`/teams/${id}`);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Container maxWidth={'md'} style={{justifyContent: 'center'}} >
      <Box
        sx={{
          mt: 8,
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'background.paper',
          borderColor: 'border.light',
          padding: '30px',
        }}
        borderRadius="6px"
        border={1}
      >
        <Typography variant="h5">
          {t('teams.editYourTeam')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}} >
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label={t('teamCreateFlow.nameLabel')}
            name="name"
            autoFocus
            value={teamName}
            onChange={(event)=> setTeamName(event.target.value)}
          />
          <PhoneInput
            inputStyle={invalidInput? {color: 'red', borderColor: 'red'} : {}}
            country={'sa'}
            placeholder={t('common.enterPhoneNumber')}
            value={contact}
            onChange={setContact}
          />
          <AlertSnackBar
            message={t('alerts.editedTeam')}
            severity={'success'}
            open={success}
            onClose={()=> setSuccess(false)}
          />

          <Box display={'flex'} flexDirection={'row'} style={{gap: '20%'}}>
            <Button
              fullWidth
              variant="contained"
              sx={{mt: 3, mb: 2}}
              color={'error'}
              onClick={() => navigate(`/teams/${id}`)}
            >
              {t('common.cancel')}
            </Button>
            <LoadingButton
              type={'submit'}
              size="small"
              variant="contained"
              sx={{mt: 3, mb: 2}}
              color={'success'}
              loading={submitting}
              fullWidth
            >
              {t('common.saveChanges')}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
      <AlertSnackBar
        message={invalidInput?
            t('errors.addressErrorsMessage') :
            t('errors.genericErrorMessage')}
        severity={'error'}
        open={errorSnackBarOpen}
        onClose={() => setErrorSnackBarOpen(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHide={false}
      />
    </Container>
  );
};
