import React, {useEffect} from 'react';
import {SignUpContainer} from '../../components/containers/SignUpContainer';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {SignUpComponent} from '../../components/registration/SignUpComponent';

export const SignUp = () => {
  const {t} = useTranslation();
  const isLoggedIn = useSelector((state) => state.isLogged);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/games');
    }
  }, []);

  const signinLink = location.search == null ? '/signin' : `/signin${location.search}`;
  return (
    <SignUpContainer title={t('registration.signUpTitle')}>
      <SignUpComponent
        style={{padding: '0px', marginTop: '35px', marginBottom: '20px'}}
        signInButton={true}
        onSignIn={()=> navigate(signinLink)}
        signUpButtonText={t('common.signUp')}
        onSuccessfulSignup={()=> navigate(`/games`)}
      />
    </SignUpContainer>
  );
};
