import React, {useEffect, useState} from 'react';
import {SignUpContainer} from '../../components/containers/SignUpContainer';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Grid, Stack, Typography} from '@mui/material';
import axios from 'axios';
import {useSelector} from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import {PasswordTextField} from '../../components/input/PasswordTextField';
import {ConfirmPasswordTextField} from '../../components/input/ConfirmPasswordTextField';
import validator from 'validator';
import {AlertSnackBar} from '../../components/game/AlertSnackBar';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';
import {trackEvent} from '../../analytics';

export const ResetPassword = () => {
  const {t} = useTranslation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [isResetSuccessful, setIsResetSuccessful] = useState(false);
  const [invalidInput, setInvalidInput] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);


  const isLoggedIn = useSelector((state) => state.isLogged);
  const navigate = useNavigate();
  const params = useParams();
  const resetPassword = (user) => {
    const token = params.token;
    axios
        .post(`${process.env.REACT_APP_API_URL}/users/resetpassword`, user, {
          params: {
            token: token,
          },
        } )
        .then((res) => {
          setInvalidToken(false);
          setInvalidInput(false);
          setErrorSnackBarOpen(false);
          setSubmitting(false);
          setIsResetSuccessful(true);
          trackEvent('Successful reset password', {action: 'Reset password'});
        })
        .catch((error) => {
          setSubmitting(false);
          if (error.response) {
            if (error.response.status === 403 &&
                error.response.data.message === 'Reset token is not valid') {
              trackEvent('Failed reset password', {action: 'Reset password', cause: 'Invalid token'});
              setErrorSnackBarOpen(true);
              setInvalidToken(true);
            } else {
              trackEvent('Failed reset password', {action: 'Reset password'});
              setErrorSnackBarOpen(true);
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            trackEvent('Failed reset password', {action: 'Reset password'});
            setErrorSnackBarOpen(true);
          } else {
            // Something happened in setting up the request that triggered an Error
            trackEvent('Failed reset password', {action: 'Reset password'});
            setErrorSnackBarOpen(true);
          }
        });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    trackEvent('Reset password attempt', {action: 'Reset password'});
    if (!validateInput()) {
      setInvalidInput(true);
      setErrorSnackBarOpen(true);
      setSubmitting(false);
      trackEvent('Failed reset password', {action: 'Reset password', cause: 'Invalid input'});
      return;
    }
    setInvalidInput(false);
    resetPassword({password});
  };

  const validateInput = () => {
    let valid = true;
    if (confirmPassword !== password) {
      valid = false;
    }
    // Default options { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1}
    if (!validator.isStrongPassword(password)) {
      valid = false;
    }
    return valid;
  };

  useEffect(() => {
    return () => {
      setIsResetSuccessful(false);
    };
  }, [isLoggedIn, params]);

  return (
    <SignUpContainer
      title={isResetSuccessful?
            t('registration.passwordIsReset'): t('registration.changePassword')
      }
    >
      {isResetSuccessful? (
          <Stack alignItems="center" spacing={4}>
            <CheckIcon color={'success'} style={{fontSize: '120px'}} />
            <Typography>{t('registration.successfulReset')}</Typography>
            <Button variant={'outlined'} color={'secondary'} onClick={()=> navigate('/signin')}>
              {t('common.backToSignIn')}
            </Button>
          </Stack>
        ) : (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PasswordTextField
                  value={password}
                  onChange={(value) => setPassword(value)}
                  autoComplete={'new-password'}
                />
              </Grid>
              <Grid item xs={12}>
                <ConfirmPasswordTextField
                  password={password}
                  value={confirmPassword}
                  onChange={(value) => setConfirmPassword(value)}
                  autoComplete={'new-password'}
                />
              </Grid>
            </Grid>
            <LoadingButton
              onClick={handleSubmit}
              color={'secondary'}
              fullWidth
              variant="contained"
              sx={{mt: 3, mb: 2}}
              loading={submitting}
              disabled={submitting}
            >
              {t('common.resetPassword')}
            </LoadingButton>
          </div>
        )}
      <AlertSnackBar
        message={
            invalidInput?
                t('errors.addressErrorsMessage') :
                invalidToken?
                    t('errors.invalidResetPasswordToken'):
                    t('errors.genericErrorMessage')
        }
        severity={'error'}
        open={errorSnackBarOpen}
        onClose={() => setErrorSnackBarOpen(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHide={false}
        sx={{marginTop: '70px'}}
      />
    </SignUpContainer>
  );
};
