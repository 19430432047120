import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';

export const InfoTitleBox = (props) => {
  return (
    <Box
      style={{
        ...(props.style),
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
      }}
      sx={{
        bgcolor: 'background.secondary',
        borderColor: 'border.light',
      }}
      padding={2}
      borderBottom={1}
      display={'flex'}
      flexDirection={'row'}
      flex={1}
    >
      {props.children}
    </Box>
  );
};

InfoTitleBox.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
};
