import React, {useState} from 'react';
import {TextField} from '@mui/material';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

export const ConfirmPasswordTextField = ({onChange, value, password}) => {
  const {t} = useTranslation();
  const [mismatch, setMismatch] = useState(true);
  const [confirmPasswordFocused, setConfirmPasswordFocused] = useState(false);

  const onConfirmPasswordChange = (event) => {
    const newValue = event.target.value;
    validateConfirmPassword(password, newValue);
    onChange(newValue);
  };

  const validateConfirmPassword = (password, confirmPassword) => {
    let valid = true;
    if (password !== confirmPassword) {
      setMismatch(true);
      valid=false;
    } else {
      setMismatch(false);
    }
    return valid;
  };
  return (
    <TextField
      required
      fullWidth
      name="confirm_password"
      label={t('registration.confirmPassword')}
      type="password"
      id="confirm_password"
      autoComplete="new-password"
      variant="outlined"
      onFocus={()=> setConfirmPasswordFocused(true)}
      error={confirmPasswordFocused && mismatch}
      onChange={onConfirmPasswordChange}
      value={value}
      color={confirmPasswordFocused && !mismatch ? 'success' : 'error'}
      helperText={confirmPasswordFocused && mismatch?
          t('errors.passwordMismatch') : ''}
    />
  );
};

ConfirmPasswordTextField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  password: PropTypes.string,
};
