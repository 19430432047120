import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Avatar, Skeleton} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import {fetchPublicProfile} from '../../../helpers';

export const TeamCaptain = ({userId}) => {
  const [captain, setCaptain] = useState(null);

  const updateCaptain = async () => {
    try {
      const profile = await fetchPublicProfile(userId);
      setCaptain(profile);
    } catch (error) {
      if (error.status === 404) {
      }
      console.error(error);
    }
  };


  useEffect(()=> {
    updateCaptain();
  }, []);

  return (
    <Link
      to={`/users/${userId}/profile`}
      style={{textDecoration: 'none'}}
    >
      {captain?
                <Avatar sx={{bgcolor: '#363636'}} src={captain.profilePictureUrl}>
                  <PersonIcon/>
                </Avatar> :
                <Skeleton animation={'wave'} variant={'circular'} />
      }
    </Link>
  );
};
