import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import * as React from 'react';
import PropTypes from 'prop-types';

export const RemovePlayerConfirmation = (
    {onClose, open, onConfirm, playerToRemove}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure you want to remove 
          ${playerToRemove.name} from the game?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              If you remove a player from a game they can still be added again at a later time.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={() => onConfirm(playerToRemove.id)} autoFocus color={'error'}>
              Remove Player
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

RemovePlayerConfirmation.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  playerToRemove: PropTypes.object,
};
