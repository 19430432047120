import React from 'react';
import PropTypes from 'prop-types';
import {Box, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material/styles';

export const GameInfoBox = (props) => {
  return (
    <Box
      style={{
        ...(props.style),
      }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderColor: 'border.light',
      }}
      width={'90%'}
      borderRadius="6px"
      border={1}
      marginX={2}
      marginBottom={2}
    >
      {props.children}
    </Box>
  );
};

GameInfoBox.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
};
