import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {createStore} from 'redux';
import {Provider} from 'react-redux';
import {rootReducer} from './redux/reducers';
import {CookiesProvider} from 'react-cookie';
import {BrowserRouter as Router} from 'react-router-dom';
const store = createStore(rootReducer); // TODO: use redux toolkit
import './i18n';
import ScrollToTop from './components/navigation/ScrollToTop';
import {MixpanelProvider} from 'react-mixpanel-browser';
import {DomainProvider} from './DomainContext';

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
      <CookiesProvider>
        <Provider store={store}>
          <Router>
            <React.Suspense fallback="...is loading">
              <ScrollToTop />
              <DomainProvider>
                <App/>
              </DomainProvider>
            </React.Suspense>
          </Router>
        </Provider>
      </CookiesProvider>,
      rootElement,
  );
} else {
  ReactDOM.render(
      <CookiesProvider>
        <MixpanelProvider>
          <Provider store={store}>
            <Router>
              <React.Suspense fallback="...is loading">
                <App/>
              </React.Suspense>
            </Router>
          </Provider>
        </MixpanelProvider>
      </CookiesProvider>,
      rootElement,
  );
}
