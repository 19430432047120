import * as React from 'react';
import {
  Box,
  Button, ImageList, ImageListItem,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {useParams} from 'react-router-dom';
import {GamePlayersContainer} from '../GamePlayersContainer';
import {GameInfoBox} from '../../containers/GameInfoBox';
import {formatDate, formatTime, getRandomGameCover} from '../util';
import {
  AccessTime,
  EventOutlined,
  Payment,
} from '@mui/icons-material';
import moment from 'moment';
import PropTypes from 'prop-types';
import {GameCoverPicture} from '../GameCoverPicture';
import {GameDetailsHeader} from './GameDetailsHeader';
import {OrganiserDetails} from './OrganiserDetails';
import {useTheme} from '@mui/material/styles';
import StarsIcon from '@mui/icons-material/Stars';
import {GameDetailsIcon} from './GameDetailsIcon';
import {MapComponent} from './MapComponent';
import {PlayersContainerHeader} from './PlayersContainerHeader';
import {GameDescription} from './GameDescription';
import QRCode from 'react-qr-code';
import {GameMedia} from './GameMedia';
import ModalImage from 'react-modal-image';

export const GameDetailsView = ({game, venue, players, organiser, isOrganiser, hasJoined, onCancel, onLeave, onJoin, onInvite, onFileChange}) => {
  const params = useParams();

  const id = params.id;
  const size = game.size;
  const price = game.price;
  const isCancelled = game.cancelled;
  const isPrivate = game.isPrivate;
  const media = []; // todo
  const dateTime = moment(game.dateTime);
  const isPast = moment().isAfter(dateTime);
  const isFull = players? game.size === players.length : false;
  const gameUrl = `${process.env.REACT_APP_APP_URL}/games/${id}`;


  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const coverPic = game.coverPicUrl || getRandomGameCover(id);


  return (
    <Box display={'flex'} flexDirection={'column'}>
      <GameCoverPicture editable={false} previewFile={game.coverPicUrl} defaultCover={coverPic}/>
      <Box display={'flex'} flexDirection={'column'} p={smallScreen? 0 : 2}>
        <GameDetailsHeader
          id={id}
          title={game.title}
          dateTime={game.dateTime}
          onCancel={onCancel}
          isOrganiser={isOrganiser}
          isPrivate={isPrivate}
          isCancelled={isCancelled}
          isPast={isPast}
          location={venue.location}
        />
        {organiser && <OrganiserDetails organiser={organiser}/>}

        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'} alignItems={'center'} mb={2}>
          <GameDetailsIcon
            icon={StarsIcon}
            text={game.rating === 0? 'All skill levels' : `Minimum rating: ${game.rating}`}
          />
          <GameDetailsIcon
            icon={EventOutlined}
            text={formatDate(game.dateTime)}
          />
          <GameDetailsIcon
            icon={AccessTime}
            text={formatTime(game.dateTime)}
          />
          <GameDetailsIcon
            icon={Payment}
            text={price === 0? 'Free' :  price.toLocaleString() + ' ' + game.paymentCurrency}
          />
        </Box>
        {venue && <MapComponent location={venue.name}/>}
        <GameInfoBox style={{alignSelf: 'center'}}>
          <PlayersContainerHeader
            players={players}
            isOrganiser={isOrganiser}
            hasJoined={hasJoined}
            onJoin={onJoin}
            onLeave={onLeave}
            onInviteFriends={onInvite}
            onCancel={onCancel}
            size={game.size}
            isPast={isPast}
            isFull={isFull}
            isCancelled={isCancelled}
          />
          <GamePlayersContainer size={size} players={players}/>
        </GameInfoBox>
        <GameDescription description={game.description}/>
        <Box display={'flex'} flexDirection={'column'} mb={3}>
          <Typography variant={'h6'} mb={2} p={smallScreen? 1: 2}>
              Share! Just scan this QR code and you will be redirected to this game.
          </Typography>
          <Box alignSelf={'center'}>
            <QRCode value={gameUrl}/>
          </Box>
        </Box>
        {(isOrganiser || media) && (
          <Box display={'flex'} flexDirection={'column'} mb={3}>
            <Typography variant={'h6'} mb={2} p={smallScreen? 1: 2}>
                  Photos from the game
            </Typography>
            <Box ml={2}>
              <ImageList cols={3}>
                {isOrganiser && (
                  <ImageListItem>
                    <GameMedia onFileChange={onFileChange}/>
                  </ImageListItem>
                )}
                {media && media.map((url) => (
                  <ImageListItem key={url}>
                    {/* TODO: add video support with react-modal-video*/}
                    <ModalImage
                      small={url}
                      large={url}
                      alt="game media"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Box>
          </Box>
        )}

        <Box alignSelf={'center'} mb={3} >
          {hasJoined ?
                (
                    <Button
                      color={'error'}
                      variant="outlined"
                      onClick={onLeave}
                      disabled={isCancelled || isPast}
                    >
                      Leave Game
                    </Button>
                ) :
                !isFull &&
                (
                  <Button
                    color="success"
                    variant="contained"
                    onClick={onJoin}
                    disabled={isCancelled || isPast}
                  >
                      Join Game
                  </Button>
                )
          }
        </Box>
      </Box>
    </Box>
  );
};

GameDetailsView.propTypes = {
  game: PropTypes.object,
  isOrganiser: PropTypes.bool,
  hasJoined: PropTypes.bool,
  onCancel: PropTypes.func,
  onLeave: PropTypes.func,
  onJoin: PropTypes.func,
  onInvite: PropTypes.func,
};
