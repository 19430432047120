import React from 'react';
import {Box, Divider, Typography} from '@mui/material';
import PropTypes from 'prop-types';

export const GameDescription = ({description}) => {
  return (
    <Box p={1}>
      {description}
      <Divider/>
      <Typography variant={'body1'}>
        <p><b>🚀 Ready for some action-packed fun?</b></p>
        <p>Hit that "JOIN" button and let's get started!<br/></p>

        <p><b>💰 Secure your spot before it's too late.</b></p>
        <p>Only <b>cash</b> payments are accepted. Make sure you have enough change to pay the court fee.<br/></p>

        <p><b>⏰ Punctuality is key! Arrive on time, folks!</b></p>
        <p>Please be there 15 minutes before the game starts.<br/></p>
        <p>If you're running a tad late, no worries – just give our <b>host a heads-up</b>.<br/></p>

      </Typography>
    </Box>
  );
};

GameDescription.propTypes = {
  description: PropTypes.string,
};
