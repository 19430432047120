import {useEffect, useState} from 'react';
import {TabPanel} from '@mui/lab';
import {AlertSnackBar} from '../../game/AlertSnackBar';
import PropTypes from 'prop-types';
import * as React from 'react';
import {fetchGames} from '../../../helpers';
import {GamesComponent} from '../../game/GamesComponent';
import {Typography} from '@mui/material';
import {GameCardsView} from '../../game/GameCards/GameCardsView';
import {CompactGameCard} from '../../game/GameCards/CompactGameCard';

export const GamesTab = ({value, type, userId, selected}) => {
  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
  const [games, setGames] = useState([]);

  const updateGames = async () => {
    try {
      setErrorSnackBarOpen(false);
      const games = await fetchGames(type, userId);
      setGames(games);
    } catch (e) {
      setErrorSnackBarOpen(true);
      console.error(e);
    }
  };

  useEffect(()=> {
    if (selected) {
      updateGames();
    }
  }, [selected, userId]);

  const emptyGamesMessage = type ==='userHosted'? 'You have not hosted any games yet.' : 'There are no games to be displayed';

  return (
    <TabPanel value={value}>
      {games && games.map( (game) =>
        <CompactGameCard
          id = {game.id}
          organiserId={game.organiserId}
          venueId={game.venueId}
          price = {game.price}
          paymentCurrency={game.paymentCurrency}
          dateTime = {game.dateTime}
          size={game.size}
          title={game.title}
          rating={game.rating}
          isPrivate={game.isPrivate}
          showDate
          key={game.id}
        />,
      )}
      {(!games || games.length === 0) && (
        <Typography variant={'h6'}>
          {emptyGamesMessage}
        </Typography>
      )}
      <AlertSnackBar
        message={'An error occurred. Please refresh the page or try again later.'}
        severity={'error'}
        open={errorSnackBarOpen}
        onClose={() => setErrorSnackBarOpen(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHide={false}
      />
    </TabPanel>
  );
};

GamesTab.propTypes = {
  value: PropTypes.string,
  type: PropTypes.string,
  userId: PropTypes.number,
  selected: PropTypes.bool,
};
