import React from 'react';
import {Link} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';


export const LinkText = (props) => {
  return (
    <Box
      sx={{
        'margin': '10px',
        '&:hover': {
          opacity: [0.9, 0.8, 0.7],
        },
      }}
      style={{textDecoration: 'none', color: 'inherit'}}
      component={Link}
      to={props.to}
    >
      <Typography variant='h6' color={'primary'}>
        {props.text}
      </Typography>
    </Box>
  );
};

LinkText.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string,
};
