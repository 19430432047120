import * as React from 'react';
import {
  Box, Button,
  Container,
  Tab,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {
  fetchProfile, fetchPublicProfile,
  uploadProfilePicture,
} from '../helpers';
import {TabContext, TabList} from '@mui/lab';
import {BalanceTab} from '../components/profile/tabs/BalanceTab';
import {StatsTab} from '../components/profile/tabs/StatsTab';
import {GamesTab} from '../components/profile/tabs/GamesTab';
import {ProfilePicture} from '../components/profile/ProfilePicture';
import PropTypes from 'prop-types';
import {useNavigate, useParams} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import {logOut} from '../redux/actions';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

export const Profile = ({publicProfile})=> {
  const {t} = useTranslation();
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [userId, setUserId] = useState(null);
  const [profilePictureUrl, setprofilePictureUrl] = useState(null);
  const params = useParams();
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState('1');


  const handleFileChange = async (fileObj) => {
    if (!fileObj) {
      return;
    }
    try {
      await uploadProfilePicture(fileObj, cookies.auth);
      updateProfile();
    } catch (e) {
      console.error(e);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateProfile = async () => {
    try {
      let profile;
      if (publicProfile) {
        const id = params.id;
        if (!id) return;
        profile = await fetchPublicProfile(id);
      } else {
        profile = await fetchProfile(cookies.auth);
      }
      setprofilePictureUrl(profile.profilePictureUrl);
      setName(profile.firstname + ' ' + profile.surname);
      setUserId(profile.userId);
      setUsername(profile.username);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(()=> {
    updateProfile();
  }, []);

  return (
    <Container
      maxWidth={false}
      style={{
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        padding: '0px',
      }}
      sx={{
        mb: 10,
      }}
    >
      <Box>
        <Box
          style={{
            backgroundColor: '#273c5e',
          }}
          minHeight={150}
          padding={2}
        >
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} >
            <ProfilePicture url={profilePictureUrl} onFileChange={handleFileChange} editable={!publicProfile}/>
            <Typography variant={'h4'} marginTop={1}>
              {username}
            </Typography>
            <Typography variant={'h6'} color={'gray'}>
              {name}
            </Typography>
          </Box>
        </Box>
        <Box sx={{width: '100%', typography: 'body1'}}>
          <TabContext value={value}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                sx={{mx: 3}}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                <Tab label="Upcoming Games" value="1" style={{fontSize: '10px'}}/>
                <Tab label="Played" value="2" style={{fontSize: '10px'}}/>
                <Tab label="Games Hosted" value="3" style={{fontSize: '10px'}}/>
                <Tab label={'Stats'} value="4" disabled style={{fontSize: '10px'}}/>
                {!publicProfile && <Tab label="Balance" value="5" style={{fontSize: '10px'}}/>}

              </TabList>
            </Box>
            <GamesTab value="1" type={'userUpcoming'} userId={userId} selected={value === '1'}/>
            <GamesTab value="2" type={'userPrevious'} userId={userId} selected={value === '2'}/>
            <GamesTab value="3" type={'userHosted'} userId={userId} selected={value === '3'}/>
            <StatsTab value="4" selected={value === '4'}/>
            {!publicProfile && <BalanceTab value="5" selected={value === '5'} userId={userId}/>}
          </TabContext>
        </Box>
      </Box>
      {!publicProfile && (
        <Box p={5}>
          <Button fullWidth variant={'outlined'}
            color="error"
            onClick={() => {
              dispatch(logOut());
              removeCookie('auth', {domain:'forza90.com'});
              navigate('/games');
            }}
          >
            {t('common.signOut')}
          </Button>
        </Box>
      )}

    </Container>
  );
};

Profile.propTypes = {
  publicProfile: PropTypes.bool,
};
