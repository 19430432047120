import React, {useEffect, useState} from 'react';
import {
  Box,
  Button, CircularProgress,
  Container, Paper, Typography, useMediaQuery,
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {useNavigate} from 'react-router-dom';
import CreateIcon from '@mui/icons-material/Create';
import {fetchGames} from '../../helpers';
import {GamesPageCarousel} from '../../components/game/GamesPageCarousel';
import {GameCardsView} from '../../components/game/GameCards/GameCardsView';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';
import {trackEvent} from '../../analytics';


export const Games = () => {
  const {t} = useTranslation();
  const [games, setGames] = useState([]);
  const [featuredGames, setFeaturedGames] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const updateGames = async () => {
    try {
      setLoading(true);
      const games = await fetchGames('upcoming');
      setGames(games);
      const featuredGames = await fetchGames('featured');
      setFeaturedGames(featuredGames);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  useEffect(()=> {
    updateGames();
  }, []);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container maxWidth={false} style={{padding: '10px'}} sx={{minHeight: '100vh'}}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Football Games in Lebanon</title>
        <meta name="description" content="Organise and Join football games in Lebanon" />
      </Helmet>
      <Box display={'flex'} flexDirection={'column'} >
        <GamesPageCarousel/>
        <Paper
          elevation={16}
          style={{
            alignSelf: 'center',
            display: 'flex',
            flexDirection: 'row',
            minWidth: smallScreen? '80%':'60%',
            marginTop: smallScreen?'-30px':'-40px',
            zIndex: 1,
            minHeight: '60px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          sx={{
            bgcolor: 'secondary.main',
            mb: 4,
          }}
        >
          <Typography
            variant={ smallScreen? 'h5' : 'h4'}
            color={'#ffffff'}
          >
            {t('games.upcoming')}
          </Typography>
        </Paper>
        <Box
          display={'flex'}
          flexDirection={smallScreen? 'column': 'row'}
          alignItems={'center'}
          alignSelf={'center'}
        >
          <Typography variant={'h7'}>
            {t('games.hostGamePrompt')}
          </Typography>
          <Button
            sx={{
              ml: smallScreen? 0: 2,
              my: smallScreen? 1 : 0,
            }}
            fullWidth={smallScreen}
            variant="outlined"
            startIcon={<CreateIcon />}
            onClick={() => {
              trackEvent(
                  'Host Game',
                  {action: 'Host Game button clicked'},
              );
              navigate('/games/create');
            }}
            color={'primary'}
          >
            {t('games.host')}
          </Button>
        </Box>
        <br/>
        <Box sx={{bgcolor: 'lightyellow'}} alignSelf={'center'} p={2}>
          <Typography variant={'h7'}>
            {'Would you like to become a Forza90 organiser?'}
            <br/>
            <a
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLScPyB1fyBzMqreqTWtMeQQSn-0q-DufENXredThPLTKhvMs1A/viewform?usp=sf_link"
              rel="noreferrer"
              onClick={() => trackEvent(
                  'Organiser form fill',
                  {action: 'Fill organiser form click'},
              )}
            >
              {'Register your interest'}
            </a>
            {' now and we\'ll be in touch soon.'}

          </Typography>
        </Box>
        {loading?
            <CircularProgress size={50} sx={{alignSelf: 'center', mt: 10}}/> :
            <GameCardsView games={games} featuredGames={featuredGames}/>
        }
      </Box>
    </Container>
  );
};
