import React from 'react';
import Typography from '@mui/material/Typography';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import {formatTime} from './util';

export const TimeDisplay = ({dateTime, variant, horizontal}) => {
  const timeString = formatTime(dateTime);
  const period = timeString.substr(timeString.length - 2);
  const displayTime = timeString.slice(0, -2);
  return (
    <Box
      display={'flex'}
      flexDirection={horizontal? 'row': 'column'}
      alignItems={'center'}
      marginY={1}
    >
      <Typography
        variant={variant || 'h5'}
        style={{color: 'black'}}
        sx={{fontWeight: 'bold'}}
      >
        {displayTime}
      </Typography>
      {horizontal &&
      <Typography variant={variant || 'h5'}>
        &nbsp;
      </Typography>}
      <Typography
        variant={variant || 'h5'}
        style={{color: 'black'}}
        sx={{fontWeight: 'bold'}}
      >
        {period}
      </Typography>

    </Box>
  );
};

TimeDisplay.propTypes = {
  dateTime: PropTypes.string,
};
