import React from 'react';
import {SignUpContainer} from '../../components/containers/SignUpContainer';
import {useLocation} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {SignInComponent} from '../../components/registration/SignInComponent';

export const SignIn = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const signupLink = location.search == null ? '/signup' : `/signup${location.search}`;

  return (
    <SignUpContainer title={t('registration.signInTitle')}>
      <SignInComponent
        style={{padding: '0px', marginTop: '5px'}}
        signUpButton={true}
        onSignUp={()=> navigate(signupLink)}
        signInButtonText={t('common.signIn')}
        onSuccessfulSignIn={() => {}}
      />
    </SignUpContainer>
  );
};
