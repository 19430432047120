import React from 'react';
import {Box, Divider, Radio, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

export const ChooseTeamComponent = ({teams, teamSize, onChoose, selectedTeamIndex}) => {
  const {t} = useTranslation();
  return (
    <Box p={1}>
      <Typography variant={'subtitle1'}>
        {t('tournament.chooseTeam')}
      </Typography>
      {teams.length === 0? (
                    <Typography
                      textAlign={'center'}
                      variant={'subtitle2'}
                      color={'error'}
                    >
                      {t('errors.notTheCaptain')}
                    </Typography>
                ) :
                (
                    teams.map((team, i) =>
                      <Box key={team.id}>
                        <Box display={'flex'}>
                          <Radio
                            checked={selectedTeamIndex === i}
                            onChange={() => onChoose(i)}
                          />
                          <Box
                            sx={{
                              my: 1,
                              display: 'flex',
                              flexDirection: 'row',
                              paddingX: 2,
                              justifyContent: 'space-between',
                              flex: 1,
                            }}
                          >
                            <Box display={'flex'} flexDirection={'column'} justifyContent={'space-evenly'}>
                              <Typography variant={'h5'} color={'black'} >
                                {team.name}
                              </Typography>
                              <Typography
                                color={
                                        team.players.length < teamSize ?
                                            'error' : 'green'
                                }
                              >
                                {t('common.numberOfPlayers', {number: team.players.length})}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>

                        <Divider/>
                      </Box>,
                    )
                )}
    </Box>
  );
};
