import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Typography, useMediaQuery} from '@mui/material';
import moment from 'moment';
import _ from 'lodash';
import {formatDate} from '../util';
import {GameCardsContainer} from './GameCardsContainer';
import {useTheme} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';
import {CompactGameCard} from './CompactGameCard';

export const GameCardsView = ({games, featuredGames}) => {
  const {t} = useTranslation();
  const [gamesByDate, setGamesByDate] = useState({});

  useEffect(()=> {
    if (games && games.length !== 0) {
      const featuredGameIds = featuredGames.map((fg) => fg.id);
      const sortedGames = games
          .sort((a, b) => moment(a.dateTime).diff(moment(b.dateTime)));
      setGamesByDate(_.groupBy(
          sortedGames.filter((g) => !featuredGameIds.includes(g.id)),
          (item) => formatDate(item.dateTime),
      ));
    }
  }, [games]);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      flexDirection={'column'}
      p={smallScreen? 0 : 3}
      alignSelf={smallScreen? 'center' : 'flex-start'}
      width={smallScreen? '100%' : '95%'}
    >
      {/*<GameCardsContainer featured>*/}
      {/*  {featuredGames && featuredGames.length > 0? (*/}
      {/*      featuredGames.map( (game) =>*/}
      {/*        <CompactGameCard*/}
      {/*          id = {game.id}*/}
      {/*          organiserId={game.organiserId}*/}
      {/*          venueId={game.venueId}*/}
      {/*          price = {game.price}*/}
      {/*          paymentCurrency={game.paymentCurrency}*/}
      {/*          dateTime = {game.dateTime}*/}
      {/*          size={game.size}*/}
      {/*          title={game.title}*/}
      {/*          rating={game.rating}*/}
      {/*          isPrivate={game.isPrivate}*/}
      {/*          showDate*/}
      {/*          key={game.id}*/}
      {/*        />,*/}
      {/*      )*/}
      {/*  ) : (*/}
      {/*      <Typography m={3} variant={'subtitle1'}>*/}
      {/*        {t('games.noFeaturedGames')}*/}
      {/*      </Typography>*/}
      {/*  )}*/}
      {/*</GameCardsContainer>*/}
      {Object.entries(gamesByDate).map(([date, games]) => (
        <GameCardsContainer date={date} key={date}>
          {games.map( (game) =>
            <CompactGameCard
              id = {game.id}
              organiserId={game.organiserId}
              venueId={game.venueId}
              price = {game.price}
              paymentCurrency={game.paymentCurrency}
              dateTime = {game.dateTime}
              size={game.size}
              title={game.title}
              rating={game.rating}
              isPrivate={game.isPrivate}
              key={game.id}
            />,
          )}
        </GameCardsContainer>
      ))}
    </Box>
  );
};

GameCardsView.propTypes = {
  games: PropTypes.array,
};
