import React, {useEffect, useState} from 'react';
import {
  Alert,
  Box, Button,
  Container,
  Snackbar,
  Typography,
} from '@mui/material';
import axios from 'axios';
import {useCookies} from 'react-cookie';
import {useNavigate, useParams} from 'react-router-dom';
import {GamePlayersContainer} from '../../components/game/GamePlayersContainer';
import {AddPlayerConfirmation} from '../../components/game/AddPlayerConfirmation';
import {RemovePlayerConfirmation} from '../../components/game/RemovePlayerConfirmation';
import {fetchGame, fetchGamePlayers} from '../../helpers';

export const ManagePlayers = () => {
  const params = useParams();
  const id = params.id;
  const [title, setTitle] = useState('');
  const [size, setSize] = useState(10);
  const [players, setPlayers] = useState([]);

  const [addPlayerConfirmationOpen, setAddPlayerConfirmationOpen] = useState(false);
  const [addPlayerSnackBarOpen, setAddPlayerSnackBarOpen] = useState(false);

  const [removePlayerConfirmationOpen, setRemovePlayerConfirmationOpen] = useState(false);
  const [removePlayerSnackBarOpen, setRemovePlayerSnackBarOpen] = useState(false);
  const [playerToRemove, setPlayerToRemove] = useState({});

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const authToken = cookies.auth;
  const navigate = useNavigate();

  const onAddPlayer = () => {
    setAddPlayerConfirmationOpen(true);
  };

  const closeAddPLayerConfirmation = () => {
    setAddPlayerConfirmationOpen(false);
  };

  const addPlayer = (player) => {
    console.log('Requesting to add player to game with auth token : ' + authToken);
    axios
        .post(`${process.env.REACT_APP_API_URL}/games/${id}/players`, player,
                authToken == null ? null : {headers: {'Authorization': authToken}})
        .then((res) => {
          console.log(res);
          setAddPlayerConfirmationOpen(false);
          setAddPlayerSnackBarOpen(true);
          updateGame();
        })
        .catch( (err) => {
          console.error(err.message);
          console.error(err);
        });
  };

  const removePlayer = (playerId) => {
    console.log('Requesting to remove player from game with auth token : ' + authToken);

    axios
        .delete(`${process.env.REACT_APP_API_URL}/games/${id}/players/${playerId}`,
                authToken == null ? null : {headers: {'Authorization': authToken}})
        .then((res) => {
          console.log(res);
          setRemovePlayerConfirmationOpen(false);
          setRemovePlayerSnackBarOpen(true);
          updateGame();
        })
        .catch( (err) => {
          console.error(err.message);
          console.error(err);
        });
  };

  const onRemovePlayer = (player) => {
    setPlayerToRemove(player);
    setRemovePlayerConfirmationOpen(true);
  };

  const closeRemovePlayerConfirmation = () => {
    setRemovePlayerConfirmationOpen(false);
  };

  const updateGame = async () => {
    try {
      const game = await fetchGame(id, authToken);
      if (!game.isOrganiser) {
        navigate(`/games/${id}`);
      }
      setTitle(game.title);
      setSize(game.size);
      const playersResponse = await fetchGamePlayers(id);
      setPlayers(playersResponse);
    } catch (e) {
      console.error(e);
    }
  };


  useEffect(()=> {
    updateGame();
  }, []);

  return (
    <Container maxWidth={'md'} style={{justifyContent: 'center'}} >
      <Box margin={1.5} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
        <Typography variant='h4'>
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 3,
          mb: 2,
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'background.paper',
          borderColor: 'border.light',
          paddingY: '30px',

        }}
        borderRadius="6px"
        border={1}
      >
        <Typography variant="h5" m={3}>
                    Manage Players
        </Typography>
        <GamePlayersContainer
          size={size}
          players={players}
          editable
          onAddPlayer={onAddPlayer}
          onRemovePlayer={onRemovePlayer}
        />

        <Button color={'success'} onClick={()=> navigate(`/games/${id}`)} sx={{alignSelf: 'flex-end'}}>
                    Done
        </Button>

        <AddPlayerConfirmation
          open={addPlayerConfirmationOpen}
          onClose={closeAddPLayerConfirmation}
          onConfirm={addPlayer}
        />
        <Snackbar open={addPlayerSnackBarOpen} autoHideDuration={6000} onClose={() => setAddPlayerSnackBarOpen(false)}>
          <Alert onClose={() => setAddPlayerSnackBarOpen(false)} severity="success" sx={{width: '100%'}}>
                        Player added to the game successfully.
          </Alert>
        </Snackbar>

        <RemovePlayerConfirmation
          open={removePlayerConfirmationOpen}
          onClose={closeRemovePlayerConfirmation}
          onConfirm={removePlayer}
          playerToRemove={playerToRemove}
        />
        <Snackbar open={removePlayerSnackBarOpen} autoHideDuration={6000} onClose={() => setRemovePlayerSnackBarOpen(false)}>
          <Alert onClose={() => setRemovePlayerSnackBarOpen(false)} severity="warning" sx={{width: '100%'}}>
                        Player removed from the game successfully.
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};
