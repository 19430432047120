import {useEffect, useState} from 'react';
import {TabPanel} from '@mui/lab';
import {AlertSnackBar} from '../../game/AlertSnackBar';
import PropTypes from 'prop-types';
import * as React from 'react';
import {fetchUserStats} from '../../../helpers';

export const StatsTab = ({value, selected}) => {
  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);

  const updateStats = async () => {
    try {
      // Todo
      const stats = await fetchUserStats();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(()=> {
    if (selected) {
      updateStats();
    }
  }, [selected]);

  return (
    <TabPanel value={value}>
      <AlertSnackBar
        message={'An error occurred. Please refresh the page or try again later.'}
        severity={'error'}
        open={errorSnackBarOpen}
        onClose={() => setErrorSnackBarOpen(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHide={false}
      />
    </TabPanel>
  );
};

StatsTab.propTypes = {
  value: PropTypes.string,
  selected: PropTypes.bool,
};
