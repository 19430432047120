import React from 'react';
import {Box, Typography, useMediaQuery} from '@mui/material';
import {formatTime, getSimpleDate} from '../util';
import {GameOptionsMenu} from '../GameOptionsMenu';
import PropTypes from 'prop-types';
import {useTheme} from '@mui/material/styles';
import {LocationOn} from '@mui/icons-material';

export const GameDetailsHeader = ({id, dateTime, isPrivate,
  isOrganiser, isCancelled, onCancel, isPast, title, location}) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const headerColour = isCancelled? '#c00e0e': isPast? '#727272' : '#67a368';

  return (
    <Box display={'flex'} flexDirection={'column'} p={smallScreen? 1: 2}>
      <Box
        sx={{borderColor: headerColour, color: headerColour}}
        alignSelf={'flex-start'}
        p={1}
        border={1}
        borderRadius={1}
      >
        <Typography variant={'h7'}>
          {`${getSimpleDate(dateTime)} • ${formatTime(dateTime)} • Football  • ${isPrivate? 'Private' : 'Public'} Game`}
        </Typography>
      </Box>
      {isCancelled &&
        <Typography variant={'subtitle1'} color={'#c00e0e'}>
          This game has been cancelled
        </Typography>
      }
      {!isCancelled && isPast &&
        <Typography variant={'subtitle1'} color={'#727272'}>
          This game has already been played
        </Typography>
      }

      <Box
        my={2}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box>
          <Typography variant={smallScreen? 'h5' : 'h4'}>
            {`⚽️ ${title}⚽️`}
          </Typography>
          <Box display={'flex'} mt={1}>
            <LocationOn
              color={'primary'}
            />
            <Typography variant={'subtitle1'} color={'gray'}>
              {location}
            </Typography>
          </Box>
        </Box>
        {isOrganiser && (
          <GameOptionsMenu
            gameId={id}
            onCancel={onCancel}
            disabled={isCancelled || isPast}
          />
        )}
      </Box>
    </Box>
  );
};

GameDetailsHeader.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  dateTime: PropTypes.object,
  isOrganiser: PropTypes.bool,
  isPrivate: PropTypes.bool,
  isPast: PropTypes.bool,
  isCancelled: PropTypes.bool,
  onCancel: PropTypes.func,
  location: PropTypes.string,
};
