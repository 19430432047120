import * as React from 'react';
import PropTypes from 'prop-types';
import {Alert, Snackbar} from '@mui/material';

export const AlertSnackBar = ({message, open, onClose, severity, anchorOrigin, autoHide, sx}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHide? 6000 : null}
      onClose={onClose}
      anchorOrigin={anchorOrigin || {vertical: 'bottom', horizontal: 'left'}}
      sx={sx}
    >
      <Alert onClose={onClose} severity={severity} sx={{width: '100%'}}>
        {message}
      </Alert>
    </Snackbar>
  );
};

AlertSnackBar.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  severity: PropTypes.string,
  anchorOrigin: PropTypes.object,
  autoHide: PropTypes.bool,
  sx: PropTypes.object,
};
