
const themeReducer = (state = true, action) => {
  if (action.type === 'TOGGLETHEME') {
    return !state;
  } else {
    return state;
  }
};

export default themeReducer;
