import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import {Box, Checkbox, TextField, Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {LoadingButton} from '@mui/lab';

export const JoinGameConfirmation = ({title, organiser, onClose, open,
  onConfirm, paid, isPrivate, secret, onSecretChange, submitting, rating,
}) => {
  const [accepted, setAccepted] = useState(false);

  useEffect(()=> {
    return ()=> {
      setAccepted(false);
    };
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>

        {rating > 0 ? (
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Typography variant={'body1'}>
                    {`The minimum rating for this game is ${rating}. Your rating is 2 stars only, lol.`}
                    <br/>
                    Join our featured games where we record games and track stats for each player.
                    <br/>
                    IF you're good enough, your rating will go up. Only then will you be able to play with the big boys.
                    <Link
                      to={`/games`}
                      style={{textDecoration: 'none'}}
                    >
                      {' Find featured games now'}
                    </Link>
                  </Typography>
                </DialogContentText>
              </DialogContent>
          ) : (
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Typography variant={'body1'}>
                    <p>
                    This game is hosted by
                      <Link
                        to={`/users/${organiser.userId}/profile`}
                        style={{textDecoration: 'none'}}
                      >
                      @{organiser.username}
                      </Link>.
                      <br/>
                    Please do not hesitate {' '}
                      <a
                        href='https://docs.google.com/forms/d/e/1FAIpQLSd3E1-Z9l4t0WMrymYUwkNqDtBiqi_LM0Rwc9Ss_OeLplv9vA/viewform?usp=sf_link'
                        style={{textDecoration: 'none'}}
                      >
                      to get in touch with us
                      </a>
                      {' '} if you have any concerns.
                    Our team is available 24/7 and will be happy to assist you.
                    </p>
                    <p>
                    A confirmation will be sent to your email as soon as your spot is reserved.
                    </p>
                  </Typography>
                  {!paid && (
                    <Typography variant={'body1'}>
                      <p>
                        You will automatically receive refund if the game is cancelled or you modify your attendance
                        status 2 days before planned kick off date
                      </p>
                    </Typography>
                  )}

                </DialogContentText>
                {isPrivate && (
                  <Box display={'flex'} flexDirection={'column'} my={2}>
                    <Typography> This game is private, do you know the secret?</Typography>
                    <TextField
                      margin="normal"
                      id="secret"
                      label="Secret"
                      name="secret"
                      autoFocus
                      value={secret}
                      onChange={onSecretChange}
                    />
                  </Box>
                )}
                <Box display={'flex'} alignItems={'center'}>
                  <Checkbox value={accepted} onChange={()=> setAccepted(!accepted)} sx={{marginLeft: -1, my: 1}}/>
                  <Typography variant={'body1'}> I have read and accepted the terms & conditions.</Typography>
                </Box>
                <Typography variant={'body2'} color={'gray'}>
                  At Forza90, we pride ourselves in honesty,
                  transparency, and ofcourse, our high quality games.
                </Typography>
              </DialogContent>
          )}


        <DialogActions>
          <Button onClick={onClose} color={'error'}>Cancel</Button>
          <LoadingButton
            loading={submitting}
            onClick={onConfirm}
            autoFocus
            color={'success'}
            disabled={(isPrivate && !secret) || !accepted || submitting || rating > 0}
          >
            {paid? 'Pay and Join' : 'Join'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

JoinGameConfirmation.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  paid: PropTypes.bool,
  isPrivate: PropTypes.bool,
  secret: PropTypes.string,
  onSecretChange: PropTypes.func,
  submitting: PropTypes.bool,
};
