import React from 'react';

const PrivacyPolicy = () => {
    const htmlContent = `
    <h1>Privacy Policy</h1>
    <p><strong>Last Updated:</strong> October 1, 2024</p>

    <p>Forza90 LTD ("we," "our," or "us") operates the Forza90 platform, which connects football players and allows them to join or organize football games. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our platform and services. By using Forza90, you consent to the practices outlined in this policy.</p>

    <h2>1. Information We Collect</h2>
    <h3>Personal Data</h3>
    <p>When you register for an account or use Forza90, we may collect personal information that you voluntarily provide to us, including:</p>
    <ul>
      <li>First Name and Surname (used for display purposes when joining or organizing games)</li>
      <li>Email Address (used for communication purposes such as booking confirmations or updates)</li>
      <li>Phone Number (used by us or game organizers to contact you in case of any changes to your booking or for any related inquiries)</li>
    </ul>

    <h3>Automatically Collected Information</h3>
    <p>We may collect certain information automatically when you access the platform, such as device information.</p>

    <h2>2. How We Use Your Information</h2>
    <p>We may use the information we collect for the following purposes:</p>
    <ul>
      <li>To facilitate the organization and management of football games</li>
      <li>To communicate with you regarding your bookings, changes, or important updates</li>
      <li>To improve our platform and offer new features</li>
      <li>To comply with legal obligations</li>
    </ul>

    <h2>3. How We Share Your Information</h2>
    <p>We may share your information in the following situations:</p>
    <ul>
      <li>With game organizers or participants when necessary for the operation of a game</li>
      <li>If required by law, such as to comply with a legal request or protect the safety of users</li>
    </ul>

    <h2>4. Data Security</h2>
    <p>We use industry-standard security measures to protect your personal information. However, no method of transmission over the internet is completely secure. While we strive to protect your data, we cannot guarantee its absolute security.</p>

    <h2>5. Your Data Rights</h2>
    <p>You have the right to access, correct, or delete your personal data. You may also object to or restrict certain processing of your data. If you wish to exercise any of these rights, please contact us at the email address provided below.</p>

    <h2>6. Data Retention</h2>
    <p>We retain your personal data for as long as necessary to provide you with our services, comply with legal obligations, and resolve disputes. You may request deletion of your account and data at any time by contacting us.</p>

    <h2>7. Third-Party Links</h2>
    <p>Forza90 may contain links to third-party websites or services. We are not responsible for the privacy practices or content of such third parties. We encourage you to review their privacy policies before providing any personal information.</p>

    <h2>8. Children's Privacy</h2>
    <p>Our platform is not directed to individuals under the age of 16. If we become aware that we have collected personal data from a child under 16, we will take steps to delete such information as soon as possible.</p>

    <h2>9. Changes to This Privacy Policy</h2>
    <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated "Last Updated" date. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.</p>

    <h2>10. Contact Us</h2>
    <p>If you have any questions or concerns about this Privacy Policy or your personal data, please contact us at:</p>
    <p><strong>Email:</strong> support@forza90.co.uk</p>
  `;

    return (
        <div style={{padding: 20}}>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </div>
    );
};

export default PrivacyPolicy;
