import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';


export const NavButton = (props) => {
  return (
    <Box
      sx={{
        'margin': '10px',
        '&:hover': {
          opacity: [0.9, 0.8, 0.7],
        },
      }}
    >
      <Typography variant='h6'>
        {props.text}
      </Typography>
    </Box>
  );
};

NavButton.propTypes = {
  text: PropTypes.string,
};
