import React from 'react';
import {Box, Divider, Typography} from '@mui/material';
import PropTypes from 'prop-types';

export const TournamentDescription = ({description}) => {
  return (
    <Box p={1}>
      {/* <Typography variant={'h6'}>*/}
      {/*  {description}*/}
      {/* </Typography>*/}
      <h3>Welcome to Forza90 Showdown 🏆</h3>
      <p>Get ready for the most exhilarating football tournament in London! ⚽</p>

      <h4>Tournament Details 📋:</h4>
      <ul>
        <li><strong>Format:</strong> 7-aside</li>
        <li><strong>Teams:</strong> 16 teams divided into 4 groups</li>
        <li><strong>Dates:</strong> September 29 and October 6 for group matches</li>
        <li><strong>Grand Final:</strong> October 13 for knockout rounds</li>
        <li><strong>Qualification:</strong> The top 2 teams from each group will advance to the knockout rounds</li>
        <li><strong>Substitutions:</strong> Unlimited subs to keep the action going</li>
        <li><strong>Fair Play:</strong> No slide tackles to ensure everyone's safety</li>
        <li><strong>Team Registration:</strong> All teammates must be registered by joining your team before confirming your spot</li>
        <li><strong>Act Fast:</strong> Limited spots available, so hurry up and secure your place! ⏳</li>
      </ul>

      <p>Join us for two thrilling Fridays of group-stage matches (September 29 and October 6), where teams will battle for supremacy. The action will be intense as the top 2 teams from each group secure their spots in the knockout rounds. 🔥</p>

      <p>Then, mark your calendars for the Grand Final on October 13, where the remaining teams will compete for the ultimate championship! 🏅</p>

      <p>Don't miss your chance to be a part of the <strong>Forza90 Showdown</strong>. Grab your teammates, register, and get ready to shine on the pitch! ⚽✨</p>
    </Box>
  );
};

TournamentDescription.propTypes = {
  description: PropTypes.string,
};

