import React from 'react';
import {Box, Typography, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import Background from '../../assets/backgrounds/background7.jpg';
import logo from '../../assets/blackRedFullLogo.png';
import {useTranslation} from 'react-i18next';
import {AppStoreButton, ButtonsContainer, GooglePlayButton} from "react-mobile-app-button";
export const WelcomeContainer = () => {
    const theme = useTheme();
    const {t} = useTranslation();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const APKUrl = "https://play.google.com/store/apps/details?id=com.forza90.app";
    const IOSUrl = "https://apps.apple.com/gb/app/forza90/id6720759723";

  return (
    <Box
      sx={{
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: '#f5f5f7',
        backgroundImage: `url(${Background})`,
      }}
      minHeight={'40vh'}
      p={2}
      justifyContent={'center'}
      alignItems={smallScreen? 'flex-start' : 'center'}
      display={'flex'}
      flexDirection={'column' }
    >
      <Box
        component={'img'}
        alt="Forza90"
        src={logo}
        width={smallScreen? 190 : 250}
        alignSelf={'center'}
      />

      <Box display={'flex'} flexDirection={'column'} mt={0} mb={3}>
        <Typography
          fontWeight={'bold'}
          textAlign={'left'}
          mt={2}
          variant={smallScreen? 'h3': 'h2'}
          color={'whitesmoke'}
        >
          {t('welcomeContainer.playFootball')}

            <Typography
                fontWeight={'bold'}
                color={'whitesmoke'}
                textAlign={'left'}
                variant={smallScreen? 'h3': 'h2'}
            >
                {t('welcomeContainer.now')}
            </Typography>
        </Typography>
      </Box>
        <ButtonsContainer gap={2}>
            <Box style={{backgroundColor: '#dedede', borderRadius: 10}}>
                <GooglePlayButton
                    url={APKUrl}
                    className={"custom-style"}
                    width={155}
                    height={35}
                />
            </Box>

            <Box style={{backgroundColor: '#dedede', borderRadius: 10}}>
                <AppStoreButton
                    url={IOSUrl}
                    className={"custom-style"}
                    width={155}
                    height={35}
                />
            </Box>
        </ButtonsContainer>
    </Box>
  );
};
