import React, {useState} from 'react';
import {
  Box,
  Button,
  Container,
  Step,
  StepLabel,
} from '@mui/material';
import {useCookies} from 'react-cookie';
import {useTheme} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';
import {useNavigate, useParams} from 'react-router-dom';
import {CreateTeam} from '../../components/teams/CreateTeam';
import {Stepper} from '@mui/material';
import {createTournamentTeam} from '../../helpers';
import {InvitePlayerComponent} from '../../components/teams/InvitePlayerComponent';
import {useTranslation} from 'react-i18next';
import {SignUpComponent} from '../../components/registration/SignUpComponent';
import {SignInComponent} from '../../components/registration/SignInComponent';
import {trackEvent} from '../../analytics';


export const TeamCreateFlow = ({currentStep}) => {
  const {t} = useTranslation();
  const steps = [
    {key: 'create', label: t('teamCreateFlow.create')},
    {key: 'invite', label: t('teamCreateFlow.invite')},
  ];

  const navigate = useNavigate();
  const params = useParams();
  const teamId = params.id;
  const tournamentId = params.tournamentId;
  const [hasAccount, setHasAccount] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const authToken = cookies.auth;

  const theme = useTheme();
  const useStyles = makeStyles({
    root: {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  });
  const classes = useStyles();

  const nextStep = () => {
    if (currentStep === steps.length) {
      navigate(`/teams/${teamId}`, {replace: true});
    } else {
      navigate(`/teams/${teamId}/create/join`);
    }
  };

  const handleBack = () => {
    navigate(`/teams/${teamId}/create/invite`);
  };

  const CurrentStep = () => {
    switch (currentStep) {
      case 0:
        return hasAccount? (
            <SignInComponent
              style={{padding: '0px', marginTop: '5px'}}
              signUpButton={true}
              onSignUp={()=> setHasAccount(false)}
              signInButtonText={t('common.signIn')}
              onSuccessfulSignIn={()=> navigate(`/teams/create`, {replace: true})}
            />
        ) : (
            <SignUpComponent
              style={{padding: '0px', marginTop: '35px', marginBottom: '20px'}}
              signInButton={true}
              onSignIn={()=> setHasAccount(true)}
              signUpButtonText={t('common.signUp')}
              onSuccessfulSignup={()=> navigate(`/teams/create`, {replace: true})}
            />
        );
      case 1:
        return <CreateTeam onCreate={onCreateTeam}/>;
      case 2:
        return <InvitePlayerComponent teamId={teamId}/>;
    }
  };

  const onCreateTeam = async (team) => {
    try {
      const response = await createTournamentTeam(team, tournamentId, authToken);
      trackEvent(
          'Successful create team',
          {action: 'Create team', teamName: team.name, teamId: response.teamId},
      );
      trackEvent(
          'Successful join tournament',
          {action: 'Join tournament', tournamentId: tournamentId, teamId: response.teamId},
      );
      navigate(`/teams/${response.teamId}/create/invite`, {replace: true});
    } catch (e) {
      trackEvent(
          'Unsuccessful create team',
          {action: 'Create team', teamName: team.name},
      );
      console.error(e);
    }
  };

  const getSteps = () => {
    const extraSteps = [];
    if (!hasAccount) {
      extraSteps.push({key: 'signup', label: t('registration.createAccount')});
    } else if (hasAccount) {
      extraSteps.push({key: 'signin', label: t('registration.signIn')});
    }
    return extraSteps.concat(steps);
  };


  return (
    <Container className={classes.root} component="main" maxWidth={false} sx={{mb: 8, minHeight: '100vh'}}>
      <Box sx={{width: '100%'}} mt={3}>
        <Stepper activeStep={+currentStep} alternativeLabel
          sx={{
            '& .MuiStepLabel-root .Mui-completed': {
              color: 'secondary.dark', // circle color (COMPLETED)
            },
            '& .MuiStepLabel-root .Mui-active': {
              color: 'secondary.main', // circle color (ACTIVE)
              fontSize: 'medium',
              fontWeight: 'bold',
            },
            '& .MuiStepIcon-text': {
              fontSize: 'medium',
              fontWeight: 'bold',
            },
          }}>
          {getSteps().map((step, index) =>
            <Step key={step.key} color={'secondary'}>
              <StepLabel color={'secondary'} >{step.label}</StepLabel>
            </Step>,
          )}
        </Stepper>
        <React.Fragment>
          <CurrentStep />
          {currentStep > 1 && (
            <Box sx={{display: 'flex', flexDirection: 'row', pt: 2, mb: 2}}>
              {currentStep > 2 && (
                <Button
                  color="inherit"
                  disabled={currentStep === 0}
                  onClick={handleBack}
                  sx={{mr: 1}}
                >
                  {t('common.back')}
                </Button>
              )}
              <Box sx={{flex: '1 1 auto'}} />

              <Button onClick={nextStep} variant={'contained'}>
                {currentStep === steps.length?
                    t('common.finish') : t('common.next')
                }
              </Button>
            </Box>
          )}
        </React.Fragment>
      </Box>
    </Container>
  );
};
