import React from 'react';
import {Box, IconButton, Tooltip} from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import Background from '../../assets/background.png';
import {makeStyles} from '@mui/styles';

export const GameCoverPicture = ({previewFile, onFileChange, editable, defaultCover}) => {
  const [preview, setPreview] = useState(null);
  const fileInput = React.createRef();
  const handleClick = () => {
    // 👇️ open file input box on click of other element
    fileInput.current.click();
  };

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!previewFile) {
      setPreview(null);
      return;
    }

    const objectUrl = URL.createObjectURL(previewFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [previewFile]);

  const useStyles = makeStyles({
    button: {
      'backgroundColor': '#bdbdbd',
      '&:hover': {
        backgroundColor: '#fff',
        color: '#3c52b2',
      },
    }});
  const classes = useStyles();

  return (
    <Box
      sx={{
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: preview? `url(${preview})` : `url(${defaultCover})`,
        borderColor: 'border.light',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        display: 'flex',
      }}
      borderRadius="4px"
      border={1}
      minHeight={'250px'}
      width={'98%'}
    >
      {editable && (
        <Box>
          <Tooltip title={'Upload cover photo'}>
            <IconButton
              onClick={handleClick}
              className={classes.button}
              // sx={{bgcolor: '#bdbdbd', borderColor: 'black', border: 2}}
            >
              <AddAPhotoIcon sx={{fontSize: 20}} />
            </IconButton>
          </Tooltip>
          <input
            style={{
              display: 'none',
            }}
            ref={fileInput}
            type="file"
            onChange={() => onFileChange(
                fileInput.current.files && fileInput.current.files[0])}
          />
        </Box>
      )}
    </Box>
  );
};

GameCoverPicture.propTypes = {
  url: PropTypes.string,
  onFileChange: PropTypes.func,
  previewFile: PropTypes.string,
  editable: PropTypes.bool,
};
