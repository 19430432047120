import axios from 'axios';

export const register = (body) => {
  axios.defaults.withCredentials = false;
  return axios.post(`${process.env.REACT_APP_API_URL}/users/register`, body)
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
};

export const login = (body) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/users/login`, body)
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
};

export const fetchUsernames = (authToken) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/users/usernames`,
        authToken == null ? null : {headers: {'Authorization': authToken}})
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.log('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const fetchCustomerAccount = (authToken) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/stripe/customer`,
      {headers: {'Authorization': authToken}})
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.log('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const fetchConnectedAccount = (authToken) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/stripe/connected_account`,
      {headers: {'Authorization': authToken}})
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.log('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const fetchConnectedAccountBalance = (authToken) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/stripe/connected_account/balance`,
      {headers: {'Authorization': authToken}})
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.log('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const fetchProfile = (authToken) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/myprofile`,
      {headers: {'Authorization': authToken}})
      .then((res)=> res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const fetchPublicProfile = (userId) =>{
  return axios.get(`${process.env.REACT_APP_API_URL}/users/${userId}/profile`)
      .then((res)=> res.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
};

export const fetchVenue = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/venues/${id}`)
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

// TODO
export const fetchUserStats = () =>{
  return;
};

export const uploadProfilePicture = (file, authToken) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/profile/picture`, {},
      {headers: {'Authorization': authToken}})
      .then((res) => res.data)
      .then((url) => uploadFileToSpaces(url, file))
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const uploadGameCoverPicture = (gameId, file, authToken) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/games/${gameId}/cover`, {},
      {headers: {'Authorization': authToken}})
      .then((res) => res.data)
      .then((url) => uploadFileToSpaces(url, file))
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const uploadGameMedia = (gameId, file, authToken) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/games/${gameId}/media`, {},
      {
        headers: {'Authorization': authToken},
        params: {
          fileName: file.name,
        },
      })
      .then((res) => res.data)
      .then((url) => uploadFileToSpaces(url, file))
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const uploadFileToSpaces = (url, file) => {
  return axios.put(url, file, {headers: {'Content-Type': 'application/octet-stream'}});
};

export const fetchStripeConnectDashboardUrl = (authToken) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/stripe/connected_account/login_link`,
      {headers: {'Authorization': authToken}})
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.log('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

// used to finish onboarding user's Connected Account
export const fetchAccountLink = (authToken) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/stripe/account_link`,
      {
        returnUrl: `${process.env.REACT_APP_APP_URL}/games/create`,
        refreshUrl: `${process.env.REACT_APP_APP_URL}/games/create`,
      },
      {headers: {'Authorization': authToken}} )
      .then((res)=> res.data)
      .catch((err) => {
        console.error(err.message);
        throw err;
      });
};

export const fetchGames = (type, userId) => {
  let endpoint = '';
  switch (type) {
    case 'upcoming':
      endpoint = '/games/upcoming';
      break;
    case 'featured':
      endpoint = '/games/upcoming/featured';
      break;
    case 'previous':
      endpoint = '/games/previous';
      break;
    case 'userUpcoming':
      if (userId === null || userId ==='') return;
      endpoint = `/users/${userId}/games/upcoming`;
      break;
    case 'userPrevious':
      if (userId === null || userId ==='') return;
      endpoint = `/users/${userId}/games/previous`;
      break;
    case 'userHosted':
      if (userId === null || userId ==='') return;
      endpoint = `/users/${userId}/games/hosted`;
      break;
  }

  return axios.get(`${process.env.REACT_APP_API_URL}${endpoint}`)
      .then((res)=> res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const fetchGame = (id, authToken) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/games/${id}`,
        authToken == null ? null : {headers: {'Authorization': authToken}})
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};


export const fetchGamePlayers = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/games/${id}/players`)
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const fetchTeams = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/teams`)
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const fetchTeam = (id, authToken) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/teams/${id}`,
        authToken == null ? null : {headers: {'Authorization': authToken}})
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const fetchTeamInvites = (id, authToken) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/teams/${id}/invites`,
        authToken == null ? null : {headers: {'Authorization': authToken}})
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const fetchTeamPlayers = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/teams/${id}/players`, {})
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const createTournamentTeam = (team, tournamentId, authToken) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/tournaments/${tournamentId}/teams`, team,
      {headers: {'Authorization': authToken}} )
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const editTeam = (teamId, team, authToken) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/teams/${teamId}/edit`, team,
      {headers: {'Authorization': authToken}} )
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const joinTeam = (id, inviteToken, authToken) => {
  return axios
      .post(`${process.env.REACT_APP_API_URL}/teams/${id}/join`, {}, {
        params: {
          token: inviteToken,
        },
        headers: {'Authorization': authToken},
      })
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const inviteTeamPlayer = (teamId, player, authToken) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/teams/${teamId}/invite`, player,
      {headers: {'Authorization': authToken}} )
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const cancelTeamInvite = (teamId, inviteId, authToken) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/teams/${teamId}/invite/${inviteId}/cancel`, {},
      {headers: {'Authorization': authToken}} )
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const removeTeamPlayer = (teamId, userId, authToken) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/teams/${teamId}/players/${userId}`,
      {headers: {'Authorization': authToken}} )
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const fetchTournaments = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/tournaments`)
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const fetchTournament = (id, authToken) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/tournaments/${id}`,
      authToken == null ? null : {headers: {'Authorization': authToken}})
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const fetchConfirmedTournamentTeams = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/tournaments/${id}/teams/confirmed`)
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const fetchWaitlistTournamentTeams = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/tournaments/${id}/teams/waitlist`)
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const fetchTournamentRounds = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/tournaments/${id}/rounds`)
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const fetchTournamentRound = (tournamentId, id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/tournaments/${tournamentId}/rounds/${id}`)
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const fetchTournamentRoundTeams = (tournamentId, id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/tournaments/${tournamentId}/rounds/${id}/teams`)
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const fetchUserTeams = (authToken) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/teams/myteams`,
        authToken == null ? null : {headers: {'Authorization': authToken}})
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const fetchUserTournamentTeam = (authToken, tournamentId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/tournaments/${tournamentId}/teams/myteam`,
        authToken == null ? null : {headers: {'Authorization': authToken}})
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const createTournament = (body, authToken) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/tournaments`, body,
      {headers: {'Authorization': authToken}} )
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const createTournamentRound = (tournamentId, body, authToken) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/tournaments/${tournamentId}/rounds`, body,
      {headers: {'Authorization': authToken}} )
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const joinTournament = (tournamentId, teamId, authToken) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/tournaments/${tournamentId}/join?teamId=${teamId}`, {},
      {headers: {'Authorization': authToken}} )
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const joinWaitlist = (tournamentId, teamId, authToken) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/tournaments/${tournamentId}/waitlist?teamId=${teamId}`, {},
      {headers: {'Authorization': authToken}} )
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const addTournamentRoundTeam = (tournamentId, roundId, teamId, authToken) => {
  return axios.post(
      `${process.env.REACT_APP_API_URL}/tournaments/${tournamentId}/rounds/${roundId}/add?teamId=${teamId}`,
      {},
      {headers: {'Authorization': authToken}} )
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

export const addTournamentTeam = (id, teamId, authToken) => {
  return axios.post(
      `${process.env.REACT_APP_API_URL}/tournaments/${id}/teams/add?teamId=${teamId}`,
      {},
      {headers: {'Authorization': authToken}} )
      .then((res) => res.data)
      .catch((err) => {
        if (err.status === 401) {
          console.error('Token not valid');
        }
        console.log(err);
        throw err;
      });
};

