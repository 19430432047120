import React from 'react';
import {Box, Button, Typography, useMediaQuery} from '@mui/material';
import Card from '@mui/material/Card';
import {formatTime, getSimpleDate} from '../game/util';
import moment from 'moment';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GroupsIcon from '@mui/icons-material/Groups';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';


export const TournamentCard = ({tournament, joinable, onJoin}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  // const isFull = tournament.teams? tournament.size === tournament.teams.length : false;
  const isFull = false;

  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box key={tournament.id}>
      <Card
        sx={{
          alignSelf: 'center',
          height: 250,
          my: 1,
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          paddingX: 2,
          justifyContent: 'space-evenly',
        }}
        elevation={3}
        onClick={joinable?
            () => onJoin(tournament) :
            ()=> navigate(`/tournaments/${tournament.id}`)
        }
      >
        <Box
          display={'flex'}
          justifyContent={'space-between'}
        >
          <Typography
            variant={'h5'}
            color={isFull? 'red' : 'secondary'}
            fontWeight={'bold'}
          >
            {tournament.name}
          </Typography>
          <Typography>
            {`${getSimpleDate(moment(tournament.startDate))} • 
                ${formatTime(tournament.startDate)}`}
          </Typography>
        </Box>

        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 4,
            }}
            variant={'body1'}
          >
            {tournament.description}
          </Typography>
        </Box>

        <Box display={'flex'} justifyContent={'space-between'} >
          <Box>
            {/* <Box display={'flex'} alignItems={'center'}>*/}
            {/*  <EmojiEventsIcon*/}
            {/*    sx={{fontSize: smallScreen? 28 : 30, color: '#e0bd36'}}*/}
            {/*  />*/}
            {/*  <Typography sx={{ml: 1}}>*/}
            {/*    {t('tournaments.numberOfTeams', {size: tournament.teams? tournament.teams.length : 20})}*/}
            {/*  </Typography>*/}
            {/* </Box>*/}
            <Box display={'flex'} alignItems={'center'}>
              <GroupsIcon
                sx={{fontSize: smallScreen? 28 : 30, color: '#0c8500'}}
              />
              <Typography sx={{ml: 1}}>
                {t('common.playersAside', {teamSize: tournament.teamSize})}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{alignSelf: 'flex-end'}}
            px={2}
            py={1}
          >
            <Typography
              fontStyle={'italic'}
              variant={'h6'} fontWeight={'bolder'}
              color={ isFull? 'red' : 'green'}
            >
              {!tournament.open? t('common.completed') :
                        isFull? t('common.full') : t('common.open')}
            </Typography>
          </Box>
        </Box>
        {joinable && (
          <Button
            sx={{mb: -1}}
            fullWidth
            variant={joinable? 'text' : 'contained'}
            onClick={()=> navigate(`/tournaments/${tournament.id}`)}
            color={'secondary'}
          >
            {t('tournament.view')}
          </Button>
        )}
      </Card>
    </Box>
  );
};
