export const currencies = [
    {
        value: 'USD',
        label: 'USD',
    },
    // {
    //     value: 'GBP',
    //     label: '£',
    // },
    // {
    //     value: 'SAR',
    //     label: 'SAR',
    // },
    {
        value: 'LBP',
        label: 'LBP',
    },
];
