import React, {useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useDispatch} from 'react-redux';
import {useCookies} from 'react-cookie';
import axios from 'axios';
import {logIn, logOut} from './redux/actions';
import {useMediaQuery} from '@mui/material';
import {NavBar} from './components/navigation/NavBar';
import {QueryClient, QueryClientProvider} from 'react-query';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';
import {BottomNavBar} from './components/navigation/BottomNavBar';
import {TopNavBar} from './components/navigation/TopNavBar';
import {Outlet} from 'react-router-dom';
import {AuthenticatedRoute} from './routes/AuthenticatedRoute';
import {NonAuthenticatedRoute} from './routes/NonAuthenticatedRoute';
import {authNavRoutes, authRoutes, navRoutes, nonAuthNavRoutes, nonAuthRoutes, routes} from './routes/routes';
import {setUserDetails, trackEvent} from './analytics';
import mixpanel from 'mixpanel-browser';
import Footer from './components/Footer';


const theme = createTheme({
  palette: {
    type: 'light',
    blue: {
      main: '#005b9b',
      dark: '#012034',
      light: '#a8d6ff',
    },

    primary: {
      main: '#222222',
      dark: '#222222',
      light: 'rgba(45,45,45,0.18)',
    },
    secondary: {
      main: '#f10f44',
      dark: '#ab0030',
      light: '#ff5386',
    },
    white: {
      main: '#ffffff',
    },
    infoIcon: {
      main: 'rgba(23,23,26,0.78)',
    },
    greyIcon: {
      main: '#b0b0b0',
    },
    // background: {
    //   paper: '#f6f8fa',
    //   secondary: 'white',
    // },
    border: {
      light: '#d8dee4',
    },
  },
  typography: {
    fontSize: i18n.language === 'ar'? 14: 11,
    fontWeightMedium: '600',
    fontFamily: 'Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;',
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '16px',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: '16px',
        },
      },
    },
  },
});

axios.defaults.withCredentials = false;

const App = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const queryClient = new QueryClient();

  const {i18n} = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    const authToken = cookies.auth;

    // Checking if we have an authToken stored already
    // If there is, check if token is valid and fetch user details
    // If token is valid, login user automatically
    // If no token or token is not valid, user stays logged out
    if (authToken != null) {
      axios
          .get(
              `${process.env.REACT_APP_API_URL}/users/details`,
              // eslint-disable-next-line max-len
              {headers: {'Authorization': authToken}},
          )
          .then((res) => {
            const nextMonth = new Date();
            nextMonth.setDate(nextMonth.getDate() + 60); // two months
            setCookie('auth', authToken, {maxAge: 5260000, expires: nextMonth,  domain: 'forza90.com'});

            const user = res.data;
            setUserDetails({
              userId: user.id,
              email: user.email,
              username: user.username,
              firstname: user.firstname,
              surname: user.surname,
            });
            // Identify the user using the Mixpanel People Analytics API
            mixpanel.identify(user.id);

            // Set user properties using the Mixpanel People Analytics API
            mixpanel.people.set({
              $email: user.email,
              $username: user.username,
              $firstname: user.firstname,
              $surname: user.surname,
            });


            dispatch(logIn());
          })
          .catch((err) => {
            console.log('Authentication token is not valid, logging user out.');
            dispatch(logOut());
            removeCookie('auth', {domain:'forza90.com'});
            console.log(err);
          });
    } else {
      console.log('No authentication token. User is not logged in.');
    }
    // document.dir = i18n.dir();
    // [i18n, i18n.language]
  }, []);

  const NavLayout = () => (
    <>
      {/* {smallBar && <BottomNavBar/>}*/}
      <Outlet />
    </>
  );

  const smallBar = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <QueryClientProvider client={queryClient}>
          {smallBar? <TopNavBar/> : <NavBar/>}
          <Routes>
            {/* Routes without the bottom navigation bar*/}
            {routes.map(({path, element}) => (
              <Route path={path} element={element} key={path}/>
            ))}
            {authRoutes.map(({path, element, authRoute}) => (
              <Route key={path} path={path} element={
                <AuthenticatedRoute authRoute={authRoute}>
                  {element}
                </AuthenticatedRoute>
              }/>
            ))}
            {nonAuthRoutes.map(({path, element, redirectRoute}) => (
              <Route key={path} path={path} element={
                <NonAuthenticatedRoute redirectRoute={redirectRoute}>
                  {element}
                </NonAuthenticatedRoute>
              }/>
            ))}
            {/* Routes with the bottom navigation bar*/}
            {navRoutes.map(({path, element}) => (
              <Route key={path} path={path} element={<NavLayout/>}>
                <Route index element={element}/>
              </Route>
            ))}
            {authNavRoutes.map(({path, element, redirectRoute}) => (
              <Route key={path} path={path} element={<NavLayout/>}>
                <Route path={path} element={
                  <AuthenticatedRoute redirectRoute={redirectRoute}>
                    {element}
                  </AuthenticatedRoute>
                }/>
              </Route>
            ))}
            {nonAuthNavRoutes.map(({path, element, redirectRoute}) => (
              <Route key={path} path={path} element={<NavLayout/>}>
                <Route path={path} element={
                  <NonAuthenticatedRoute redirectRoute={redirectRoute}>
                    {element}
                  </NonAuthenticatedRoute>
                }/>
              </Route>
            ))}
          </Routes>
          <Footer/>
        </QueryClientProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
