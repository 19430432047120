import React from 'react';
import {GameInfoBox} from '../containers/GameInfoBox';
import {TeamCard} from '../teams/TeamCard';
import {Box, Button, Divider, Typography, useMediaQuery} from '@mui/material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import {useTranslation} from 'react-i18next';
import {useTheme} from '@mui/material/styles';


export const Waitlist = ({waitlistTeams, onJoinWaitlist}) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      p={smallScreen? 1: 2}
    >
      <Box display={'flex'} justifyContent={'center'}>
        <Typography variant={'h5'} sx={{mb: 1}}>
          {t('common.waitlist')}
        </Typography>

      </Box>

      <GameInfoBox style={{alignSelf: 'center'}}>
        {waitlistTeams && waitlistTeams.map((team)=>
          <div key={team.teamId}>
            <TeamCard
              id={team.teamId}
            />
            <Divider/>
          </div>,
        )}
        <Box
          sx={{
            my: 1,
            display: 'flex',
            flexDirection: 'row',
            py: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            color={'success'}
            startIcon={<GroupAddIcon/>}
            variant={'text'}
            onClick={onJoinWaitlist}
          >
            {t('common.openSpot')}
          </Button>
        </Box>
      </GameInfoBox>
    </Box>
  );
};
