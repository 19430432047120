import * as React from 'react';
import {
  fetchAccountLink,
  fetchConnectedAccount, fetchConnectedAccountBalance,
  fetchCustomerAccount,
  fetchStripeConnectDashboardUrl,
} from '../../../helpers';
import {Box, Button, Divider, Stack, Tooltip, Typography} from '@mui/material';
import {TabPanel} from '@mui/lab';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {AlertSnackBar} from '../../game/AlertSnackBar';
import {useCookies} from 'react-cookie';

export const BalanceTab = ({value, selected, userId}) => {
  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
  const [balance, setBalance] = useState(0.0);
  const [organiserBalance, setOrganiserBalance] = useState(0.0);
  const [onboarded, setOnboarded] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);

  const handleViewDashboard = async () => {
    try {
      const url = await fetchStripeConnectDashboardUrl(cookies.auth);
      window.open(url);
    } catch (e) {
      console.error(e);
      setErrorSnackBarOpen(true);
    }
  };

  const handleSetupAccount = async () => {
    try {
      const accountLink = await fetchAccountLink(cookies.auth);
      window.location.replace(accountLink);
    } catch (e) {
      console.error(e);
      setErrorSnackBarOpen(true);
    }
  };
  const updateCustomerAccountBalance = async () => {
    try {
      const account = await fetchCustomerAccount(cookies.auth);
      console.log('Customer account: ');
      console.log(account);
      setBalance(account.balance);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(()=> {
    if (selected) {
      updateCustomerAccountBalance();
      updateConnectedAccount();
    }
  }, [selected, userId]);

  const updateConnectedAccount = async () => {
    try {
      const account = await fetchConnectedAccount(cookies.auth);
      console.log('Connected account: ');
      console.log(account);
      setOnboarded(account.charges_enabled);
      const balance = await fetchConnectedAccountBalance(cookies.auth);
      console.log('Connected account balance: ');
      console.log(balance);
      setOrganiserBalance(balance.pending[0].amount/100);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <TabPanel value={value}>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        justifyContent={'center'}
      >
        <Stack direction={'column'} alignItems={'center'}>
          <Typography fontSize={20} fontWeight={'bold'}> {'£' + (balance? balance.toPrecision(3): 0.0)}</Typography>
          <Typography> Current Balance </Typography>
        </Stack>
        <Tooltip title="Balance from organising games" arrow>
          <Stack direction={'column'} alignItems={'center'}>
            <Typography fontSize={20} fontWeight={'bold'}> {'£' + (organiserBalance? organiserBalance.toPrecision(3) :0.0)} </Typography>
            <Typography> Organiser Balance </Typography>
          </Stack>
        </Tooltip>
        <Stack direction={'column'} alignItems={'center'}>
          <Typography fontSize={20} fontWeight={'bold'}> GBP </Typography>
          <Typography> Currency </Typography>
        </Stack>
      </Stack>
      {!onboarded && (
        <Box display={'flex'} flexDirection={'column'} m={3} justifyContent={'space-around'}>
          <Typography variant={'h6'}>To accept payments online, please set up your organiser account</Typography>
          <Button onClick={handleSetupAccount} variant={'outlined'} color={'success'}> Setup account</Button>
        </Box>
      )}
      {onboarded && (
        <Box display={'flex'} flexDirection={'column'} m={3} justifyContent={'space-around'}>
          <Typography variant={'h6'}>Check out your payments in the Stripe Dashboard</Typography>
          <Button onClick={handleViewDashboard} variant={'outlined'} color={'success'}> View Dashboard</Button>
        </Box>
      )}
      <AlertSnackBar
        message={'An error occurred. Please refresh the page or try again later.'}
        severity={'error'}
        open={errorSnackBarOpen}
        onClose={() => setErrorSnackBarOpen(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHide={false}
      />
    </TabPanel>
  );
};

BalanceTab.propTypes = {
  value: PropTypes.string,
  selected: PropTypes.bool,
  userId: PropTypes.number,
};
