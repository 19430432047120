import React, {useEffect} from 'react';
import {Box, IconButton, Tooltip} from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import {makeStyles} from '@mui/styles';

export const GameMedia = ({onFileChange}) => {
  const fileInput = React.createRef();
  const handleClick = () => {
    // 👇️ open file input box on click of other element
    fileInput.current.click();
  };

  const useStyles = makeStyles({
    button: {
      'backgroundColor': 'rgba(189,189,189,0.69)',
      // '&:hover': {
      //     backgroundColor: '#fff',
      //     color: '#3c52b2',
      // },
    }});
  const classes = useStyles();

  return (
    <Box className={classes.button} sx={{height: '100%'}} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <Tooltip title={'Upload game media'}>
        <IconButton
          onClick={handleClick}

          // sx={{bgcolor: '#bdbdbd', borderColor: 'black', border: 2}}
        >
          <AddAPhotoIcon sx={{fontSize: 30}} />
        </IconButton>
      </Tooltip>
      <input
        style={{
          display: 'none',
        }}
        ref={fileInput}
        type="file"
        onChange={() => onFileChange(
            fileInput.current.files && fileInput.current.files[0])}
      />
    </Box>
  );
};
