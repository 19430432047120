import React, {useEffect, useState} from 'react';
import {Box, Container, Divider, Typography, useMediaQuery} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {fetchTeams, fetchUserTeams} from '../../helpers';
import {useTheme} from '@mui/material/styles';
import {TeamCardsView} from '../../components/teams/TeamCardsView';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';
import {useSelector} from 'react-redux';
import {useCookies} from 'react-cookie';

export const Teams = () => {
  const {t} = useTranslation();
  const [teams, setTeams] = useState([]);
  const [userTeams, setUserTeams] = useState([]);
  const isLoggedIn = useSelector((state) => state.isLogged);
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const authToken = cookies.auth;
  const navigate = useNavigate();

  const updateTeams = async () => {
    try {
      const teamsResponse = await fetchTeams();
      setTeams(teamsResponse);
      if (isLoggedIn) {
        const userTeamsResponse = await fetchUserTeams(authToken);
        setUserTeams(userTeamsResponse);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(()=> {
    updateTeams();
  }, []);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container component={'main'} maxWidth={false} style={{padding: '0px', marginBottom: '70px', minHeight: '100vh'}}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Football Teams in Riyadh</title>
        <meta name="description" content="Create your own team and start competing now" />
      </Helmet>
      <Box display={'flex'} flexDirection={'column'} p={2}>
        <Box>
          <Typography variant={'h4'}> {t('common.teams')} </Typography>
          <Divider sx={{my: 3}}/>
        </Box>
        {userTeams.length === 0 &&
        <Box>
          <Typography variant={'h6'}>{t('teams.noTeamsYet')}</Typography>
          <Typography> {t('teams.timeToCreate')}</Typography>
        </Box>
        }
        {userTeams.length !== 0 &&
            <>
              <Typography variant={'h6'}> {t('teams.yourTeams')}</Typography>
              <TeamCardsView teams={userTeams}/>
              <Typography style={{textTransform: 'none'}}>
                {t('teams.createQuestion')}{' '}
                <Typography
                  variant="button"
                  component="span"
                  sx={{
                    color: 'blue.main',
                    cursor: 'pointer',
                    textTransform: 'none',
                    display: 'inline',
                  }}
                  onClick={() => navigate('/teams/create')}
                >
                  {t('teams.visitTeamCreation')}
                </Typography>
              </Typography>
            </>
        }
        <Divider sx={{my: 3}}/>
        <TeamCardsView teams={teams}/>
      </Box>
    </Container>
  );
};
