import React, {useEffect, useState} from 'react';
import {Box, Divider, IconButton} from '@mui/material';
import {PlayerWithAvatar} from './PlayerWithAvatar';
import PropTypes from 'prop-types';
import RemoveIcon from '@mui/icons-material/Remove';
import {getDefaultPlayers, getPlayersWithNames} from './util';

export const GamePlayersContainer = (
    {players, size, editable, onAddPlayer, onRemovePlayer},
) => {
  const [playersList, setPlayersList] = useState([]);

  useEffect(()=> {
    const updatePlayers = () => {
      const newPlayers = [];
      const reservedPlayers = getPlayersWithNames(players);
      const defaultPlayers = getDefaultPlayers(size-players.length);
      newPlayers.push(...reservedPlayers);
      newPlayers.push(...defaultPlayers);
      setPlayersList(newPlayers);
    };
    updatePlayers();
  }, [players, size]);


  return (
    <Box
      display="flex"
      flexDirection="row"
      padding={2}
      justifyContent={'center'}
    >
      <Box marginRight={2}>
        {playersList.slice(0, Math.floor(size / 2)).map((player) =>
          <Box display={'flex'} flexDirection={'row'} key={player.id}>
            <PlayerWithAvatar
              name={player.name}
              type={player.type}
              reverse={true}
              editable={editable}
              onAddPlayer={onAddPlayer}
              userId={player.userId}
            />
            {editable && player.type !== 'default' && (
              <IconButton
                aria-label="remove"
                size="small"
                onClick={() => onRemovePlayer(player)}
                color={'error'}
              >
                <RemoveIcon fontSize="inherit" />
              </IconButton>
            )}
          </Box>,

        )}
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box marginLeft={2}>
        {playersList.slice(Math.floor(size / 2)).map((player) =>
          <Box display={'flex'} flexDirection={'row'} key={player.id}>
            <PlayerWithAvatar
              name={player.name}
              type={player.type}
              editable={editable}
              onAddPlayer={onAddPlayer}
              userId={player.userId}
            />
            {editable && player.type !== 'default' && (
              <IconButton
                aria-label="remove"
                size="small"
                onClick={() => onRemovePlayer(player)}
                color={'error'}
              >
                <RemoveIcon fontSize="inherit" />
              </IconButton>
            )}
          </Box>,
        )}
      </Box>
    </Box>
  );
};

GamePlayersContainer.propTypes = {
  players: PropTypes.array,
  size: PropTypes.number,
  editable: PropTypes.bool,
  onAddPlayer: PropTypes.func,
  onRemovePlayer: PropTypes.func,
};
