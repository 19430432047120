import React, {useState} from 'react';
import {Box, Grid, TextField, Typography, useMediaQuery} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Link, useLocation} from 'react-router-dom';
import {AlertSnackBar} from '../game/AlertSnackBar';
import {useDispatch} from 'react-redux';
import {useTheme} from '@mui/material/styles';
import {login} from '../../helpers';
import {useCookies} from 'react-cookie';
import {logIn} from '../../redux/actions';
import {useTranslation} from 'react-i18next';
import {setUserDetails, trackEvent} from '../../analytics';
import mixpanel from 'mixpanel-browser';

export const SignInComponent = ({loading, signInButtonText, onSuccessfulSignIn, signUpButton, onSignUp, style}) => {
  const {t} = useTranslation();
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const location = useLocation();
  const [usernameOrEmail, setUsernameOrEmail] =
        useState(location.state? location.state.username: '');
  const [password, setPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
  const [invalidInput, setInvalidInput] = useState(false);


  const track = (user)=> {
    setUserDetails({
      userId: user.id,
      email: user.email,
      username: user.username,
      // Include any other relevant user data
    });
    // Identify the user using the Mixpanel People Analytics API
    mixpanel.identify(user.id);

    // Set user properties using the Mixpanel People Analytics API
    mixpanel.people.set({
      $name: user.firstname + '' + user.surname,
      $email: user.email,
      $username: user.username,
      // Set other relevant properties
    });

    // Track the login event using the helper function
    trackEvent('Successful sign in', {action: 'Sign in'});
  };

  const dispatch = useDispatch();

  const signIn = async (user) => {
    removeCookie('auth', {domain:'forza90.com'});
    try {
      const data = await login(user);
      const authToken = 'Bearer ' + data.jwtToken;
      const nextMonth = new Date();
      nextMonth.setDate(nextMonth.getDate() + 60); // two months
      setCookie('auth', authToken, {maxAge: 5260000, expires: nextMonth, domain: 'forza90.com'});
      dispatch(logIn());
      setErrorSnackBarOpen(false);
      setInvalidInput(false);
      setSubmitting(false);
      track(data.user);
      onSuccessfulSignIn(authToken);
    } catch (error) {
      trackEvent(
          'Failed sign in',
          {
            cause: error.response.data.message,
            usernameOrEmail: usernameOrEmail,
          },
      );
      setSubmitting(false);
      if (error.response) {
        if (error.response.status === 400) {
          // either wrong password or invalid username
          setErrorSnackBarOpen(true);
          setInvalidInput(true);
        } else {
          setErrorSnackBarOpen(true);
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        setErrorSnackBarOpen(true);
      } else {
        // Something happened in setting up the request that triggered an Error
        setErrorSnackBarOpen(true);
      }
    }
  };
  const handleSubmit = async (event) => {
    // Track the button click event using the helper function
    trackEvent('Sign in attempt', {action: 'Sign in', usernameOrEmail: usernameOrEmail});

    event.preventDefault();
    setSubmitting(true);
    setErrorSnackBarOpen(false);
    await signIn({
      usernameOrEmail: usernameOrEmail.toLowerCase().trim(),
      password,
    });
  };

  const signinLink = location.search == null ? '/signin' : `/signin${location.search}`;
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        mt: 2,
        bgcolor: 'background.paper',
        borderColor: 'border.light',
      }}
      style={{...style}}
      borderRadius="6px"
    >
      <TextField
        margin="normal"
        required
        fullWidth
        id="login_field"
        label={t('common.usernameOrEmail')}
        name="login"
        autoComplete="username"
        autoFocus
        autoCapitalize={false}
        variant="outlined"
        value={usernameOrEmail}
        onChange={(e)=> setUsernameOrEmail(e.target.value)}
      />

      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label={t('common.password')}
        type="password"
        id="password"
        variant="outlined"
        autoComplete="current-password"
        value={password}
        onChange={(e)=> setPassword(e.target.value)}
      />
      <Grid container>
        <Grid item xs>
          <Link to={'/signin/forgot'}>
            <Typography variant="subtitle1" color={'secondary.dark'} sx={{textTransform: 'none'}}>
              {t('registration.forgotPassword')}
            </Typography>
          </Link>
        </Grid>
        <Grid item>
          {signUpButton? (
                <Typography
                  variant="subtitle1"
                  sx={{textTransform: 'none'}}
                  color={'secondary.dark'}
                  onClick={onSignUp}
                >
                  {t('registration.dontHaveAnAccount')}
                </Typography>
            ) : (
                <Link to={signinLink}>
                  <Typography variant="subtitle1" color={'secondary.dark'}>
                    {t('registration.dontHaveAnAccount')}
                  </Typography>
                </Link>
            )}
        </Grid>
      </Grid>
      <LoadingButton
        color={'secondary'}
        type="submit"
        fullWidth
        variant="contained"
        sx={{mt: 3, mb: 2}}
        loading={submitting || loading}
        disabled={submitting || loading}
      >
        {signInButtonText}
      </LoadingButton>
      <AlertSnackBar
        message={invalidInput ?
                    t('errors.incorrectCredentials') : t('errors.genericErrorMessage')}
        severity={'error'}
        open={errorSnackBarOpen}
        onClose={() => setErrorSnackBarOpen(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHide={false}
        sx={{marginTop: smallScreen? 0: '50px'}}
      />
    </Box>
  );
};
