import React from 'react';
import {Box, Typography, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material/styles';

export const GameDetailsIcon = ({icon: Icon, text}) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Icon sx={{fontSize: smallScreen? 24 : 30, mb: 1}} color={'infoIcon'}/>
      <Typography variant={smallScreen? 'subtitle1' : 'h7'} marginLeft={1}>
        {text}
      </Typography>
    </Box>
  );
};
