import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, AvatarGroup, Box, Stack, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import Card from '@mui/material/Card';
import {useTranslation} from 'react-i18next';
import CaptainIcon from '../../assets/icons/captain-armband-pink.png';
import {formatDateCustom} from '../game/util';

export const TeamCardsView = ({teams}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        mb: 2,
        bgcolor: 'background.paper',
      }}
      display={'flex'}
      flexDirection={'column'}
    >
      {teams.map((team) =>
        <Box key={team.id}>
          <Card
            sx={{
              my: 1,
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              padding: 2.5,
            }}
            onClick={()=> navigate(`/teams/${team.id}`)}
            elevation={3}
          >
            <Stack spacing={2}>
              <Box alignSelf={'flex-end'}>
                <Typography variant={'subtitle2'} color={'#909090'}>
                  {t(
                      'teams.sinceDate',
                      {date: formatDateCustom(team.createdAt, 'MMM YYYY')},
                  )}
                </Typography>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'} alignSelf={'center'}>
                <Typography variant={'h3'}>
                  {team.name}
                </Typography>
              </Box>
              <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                {team.captain && (
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                  >
                    <img src={CaptainIcon} height={'20px'} alt={'captain'}/>
                    <Typography ml={1} variant={'h6'} color={'blue.dark'}>{team.captain.username}</Typography>
                  </Box>
                )}
                <AvatarGroup
                  max={4}
                  sx={{'& .MuiAvatar-root': {width: 24, height: 24, fontSize: 12}}}
                >
                  {team.players && team.players.map((p) => (
                    <Avatar
                      key={p.userId}
                      alt={p.username}
                      src={p.profilePictureUrl}
                      sx={{bgcolor: 'blue.main'}}
                    />
                  ))}
                  <Avatar
                    key={Math.random()}
                    alt={'player'}
                    sx={{width: 24, height: 24}}
                  />
                  <Avatar
                    key={Math.random()}
                    alt={'player'}
                    sx={{width: 24, height: 24}}
                  />
                  <Avatar
                    key={Math.random()}
                    alt={'player'}
                    sx={{width: 24, height: 24}}
                  />
                  <Avatar
                    key={Math.random()}
                    alt={'player'}
                    sx={{width: 24, height: 24}}
                  />
                </AvatarGroup>
              </Box>
            </Stack>
          </Card>
        </Box>,
      )}
    </Box>
  );
};

TeamCardsView.propTypes = {
  teams: PropTypes.array,
};
