import React from 'react';
import {Box, Typography, useMediaQuery} from '@mui/material';
import {formatTime, getSimpleDate} from '../game/util';
import PropTypes from 'prop-types';
import {useTheme} from '@mui/material/styles';
import {TournamentOptionsMenu} from './TournamentOptionsMenu';
import {useTranslation} from 'react-i18next';

export const TournamentDetailsHeader = ({id, isOrganiser, onCancel, name}) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box display={'flex'} flexDirection={'column'} p={smallScreen? 1: 2}>

      <Box
        my={2}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'}>
          <Typography variant={smallScreen? 'h3' : 'h4'} fontWeight={'bolder'}>
            {`🏆️ ${name}`}
          </Typography>

        </Box>
        {isOrganiser && (
          <TournamentOptionsMenu
            id={id}
            onCancel={onCancel}
          />
        )}
      </Box>
    </Box>
  );
};

TournamentDetailsHeader.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  dateTime: PropTypes.object,
  isOrganiser: PropTypes.bool,
  isPrivate: PropTypes.bool,
  isCancelled: PropTypes.bool,
  onCancel: PropTypes.func,
  location: PropTypes.string,
};
