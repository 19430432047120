import validator from 'validator';

export const validateInput = (password, confirmPassword, email) => {
  let valid = true;
  if (confirmPassword !== password) {
    valid = false;
  }
  // Default options
  // { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1}
  if (!validator.isStrongPassword(password)) {
    valid = false;
  }
  if (!validator.isEmail(email)) {
    valid = false;
  }
  return valid;
};

const setTitle = (title) => {
  document.title = title;
  document.querySelector('[property="og:title"]').setAttribute('content', title);
  document.querySelector('[name="twitter:title"]').setAttribute('content', title);
};

const setDescription = (description) => {
  document.querySelector('[name="description"]').setAttribute('content', description);
  document.querySelector('[property="og:description"]').setAttribute('content', description);
  document.querySelector('[name="twitter:description"]').setAttribute('content', description);
};

const setImage = (image) => {
  document.querySelector('[property="og:image"]').setAttribute('content', image);
  document.querySelector('[name="twitter:image"]').setAttribute('content', image);
};

const setMetadata = (metadata) => {
  setTitle(metadata.title);
  setDescription(metadata.description);
  setImage(metadata.image);
};
