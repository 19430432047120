import * as React from 'react';
import {Box, Button, Container, Divider} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {AlertSnackBar} from '../../components/game/AlertSnackBar';
import {useTranslation} from 'react-i18next';
import {
  addTournamentRoundTeam, addTournamentTeam, fetchConfirmedTournamentTeams,
  fetchTeams,
  fetchTournament,
  fetchTournamentRound,
  joinWaitlist,
} from '../../helpers';
import {GameInfoBox} from '../../components/containers/GameInfoBox';
import {TeamCard} from '../../components/teams/TeamCard';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import {AddTournamentRoundTeamDialog} from '../../components/tournament/round/AddTournamentRoundTeamDialog';

export const ManageTournamentTeams = () => {
  const {t} = useTranslation();
  const params = useParams();
  const id = params.id;
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const [teams, setTeams] = useState([]);
  const [allTeams, setAllTeams] = useState([]);
  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
  const authToken = cookies.auth;

  const [joinWaitlistDialogOpen, setJoinWaitlistDialogOpen] = useState(false);

  const updateTeams = async () => {
    try {
      const response = await fetchTeams();
      setAllTeams(response);
      console.log(response);
    } catch (e) {
      console.error(e);
    }
  };

  const updateTournament = async () => {
    try {
      const response = await fetchTournament(id, authToken);
      if (!response.isOrganiser) {
        navigate(`/tournaments/${id}`);
      }
      const tournamentTeams = await fetchConfirmedTournamentTeams(id);
      setTeams(tournamentTeams);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(()=> {
    updateTournament();
    updateTeams();
  }, []);

  const onJoinWaitlist = () => {
    setJoinWaitlistDialogOpen(true);
  };

  const handleJoinWaitlist = async (teamId) => {
    // setSubmitting(true);

    try {
      await addTournamentTeam(id, teamId, authToken);
      setJoinWaitlistDialogOpen(false);
      // setJoinTournamentSnackBarOpen(true);
      updateTournament();
    } catch (error) {
      setJoinWaitlistDialogOpen(false);
      setErrorSnackBarOpen(true);
    }
  };

  return (
    <Container style={{padding: '0px'}}>
      <GameInfoBox style={{alignSelf: 'center'}}>
        {teams && teams.map((team)=>
          <div key={team.teamId}>
            <TeamCard
              id={team.teamId}
            />
            <Divider/>
          </div>,
        )}
        <Box
          sx={{
            my: 1,
            display: 'flex',
            flexDirection: 'row',

            py: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            color={'success'}
            startIcon={<GroupAddIcon/>}
            variant={'text'}
            onClick={onJoinWaitlist}
          >
            {t('common.openSpot')}
          </Button>
        </Box>
      </GameInfoBox>
      <AddTournamentRoundTeamDialog
        onClose={()=> setJoinWaitlistDialogOpen(false)}
        open={joinWaitlistDialogOpen}
        teams={allTeams}
        onConfirm={handleJoinWaitlist}
      />
      <AlertSnackBar
        message={t('errors.genericErrorMessage')}
        severity={'error'}
        open={errorSnackBarOpen}
        onClose={() => setErrorSnackBarOpen(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHide={false}
      />
    </Container>
  );
};
