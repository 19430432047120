import * as React from 'react';
import {Container} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {AlertSnackBar} from '../../../components/game/AlertSnackBar';
import {useTranslation} from 'react-i18next';
import {fetchTournament, fetchTournamentRound, fetchTournamentRoundTeams} from '../../../helpers';
import {TournamentRoundDetailsView} from '../../../components/tournament/round/TournamentRoundDetailsView';

export const TournamentRound = () => {
  const {t} = useTranslation();
  const params = useParams();
  const id = params.id;
  const tournamentId = params.tournamentId;
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const [round, setRound] = useState(null);
  const [tournament, setTournament] = useState(null);
  const [venue, setVenue] = useState(null);
  const [teams, setTeams] = useState([]);
  const [isOrganiser, setIsOrganiser] = useState(false);
  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);

  const roundUrl = `${process.env.REACT_APP_APP_URL}/tournaments/${tournamentId}/rounds/${id}`;

  const [inviteFriendsSnackBarOpen, setInviteFriendsSnackBarOpen] = useState(false);
  const authToken = cookies.auth;

  const updateTournamentRound = async () => {
    try {
      const tournamentRound = await fetchTournamentRound(tournamentId, id);
      setRound(tournamentRound);

      const roundTeams = await fetchTournamentRoundTeams(tournamentRound.tournamentId, id);
      setTeams(roundTeams);
    } catch (e) {
      console.error(e);
    }
  };

  const updateTournament = async () => {
    try {
      const response = await fetchTournament(tournamentId, authToken);
      setTournament(response);
      setIsOrganiser(response.isOrganiser);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(()=> {
    updateTournamentRound();
    updateTournament();
  }, []);

  // todo fix
  return (
    <Container style={{padding: '0px'}}>
      {round && tournament && (
        <TournamentRoundDetailsView
          round={round}
          tournament={tournament}
          venue={venue}
          teams={teams}
          isOrganiser={isOrganiser}
          onInvite={()=> {
            navigator.clipboard.writeText(roundUrl);
            setInviteFriendsSnackBarOpen(true);
          }}
        />
      )}
      <AlertSnackBar
        message={t('alerts.linkCopied')}
        severity={'success'}
        open={inviteFriendsSnackBarOpen}
        onClose={() => setInviteFriendsSnackBarOpen(false)}
        autoHide
      />

      <AlertSnackBar
        message={t('errors.genericErrorMessage')}
        severity={'error'}
        open={errorSnackBarOpen}
        onClose={() => setErrorSnackBarOpen(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHide={false}
      />
    </Container>
  );
};
