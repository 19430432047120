import React, {useContext, useEffect, useState} from 'react';
import {DateTimePicker} from '../../components/input/DateTimePicker';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography, useMediaQuery,
} from '@mui/material';
import {useCookies} from 'react-cookie';
import {useTheme} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';
import {useLocation, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {AlertSnackBar} from '../../components/game/AlertSnackBar';
import {createTournament} from '../../helpers';
import {TeamSizeSelect} from '../../components/tournament/TeamSizeSelect';
import {useTranslation} from 'react-i18next';
import GooglePlacesAutocomplete, {geocodeByAddress} from 'react-google-places-autocomplete';
import {DomainContext} from '../../DomainContext';
import {fetchTimezoneFromCoordinates} from '../../util/timezoneUtils';

export const CreateTournament = () => {
  const {t} = useTranslation();
  const {country, language, region} = useContext(DomainContext);
  const isLoggedIn = useSelector((state) => state.isLogged);
  const prevLocation = useLocation();
  const navigate = useNavigate();

  const [dateValue, setDateValue] = useState(new Date);
  const [timeValue, setTimeValue] = useState(new Date);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [size, setSize] = useState(10);
  const [teamSize, setTeamSize] = useState(10);
  const [location, setLocation] = useState(null);
  const [price, setPrice] = useState(0);


  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const authToken = cookies.auth;

  const createNewTournament = async (body) => {
    try {
      const response = await createTournament(body, authToken);
      navigate(`/tournaments/${response.tournamentId}`);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setErrorSnackBarOpen(false);

    const address = await geocodeByAddress(location.label);
    const {lat, lng} = address[0].geometry.location;
    const timezoneResult = await fetchTimezoneFromCoordinates(lat(), lng());
    const venue = {
      name: location.label,
      location: address[0].formatted_address,
      ianaTimezone: timezoneResult.timeZoneId,
    };

    const dateTime = new Date(dateValue);
    dateTime.setHours(timeValue.getHours());
    dateTime.setMinutes(timeValue.getMinutes());

    const tzoffset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
    const dateTimeString = (new Date(dateTime - tzoffset))
        .toISOString()
        .replace('T', ' ')
        .substring(0, 16);
    const tournament = {
      name,
      description,
      size,
      teamSize,
      startDate: dateTimeString,
      price,
    };
    createNewTournament({tournament, venue});
  };

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const useStyles = makeStyles({
    root: {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  });
  const classes = useStyles();


  return (
    <Container className={classes.root} component="main" maxWidth={'md'}>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} >
        <Typography variant={smallScreen?'h5': 'h4'} m={smallScreen? 2 : 6}>
          {t('tournaments.create')}
        </Typography>
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'background.paper',
            borderColor: 'border.light',
            padding: '30px',
            width: '100%',
            boxSizing: 'border-box',
          }}
          borderRadius="6px"
          border={1}
        >
          <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label={t('tournaments.tournamentName')}
              name="name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="description"
              label={t('common.description')}
              name="description"
              multiline
              rows={3}
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />

            <DateTimePicker
              onDateChange={(newValue) => setDateValue(newValue)}
              onTimeChange={(newValue) => setTimeValue(newValue)}
              dateValue={dateValue}
              timeValue={timeValue}
            />
            <TeamSizeSelect
              onChange={(event) => setTeamSize(event.target.value)}
              value={teamSize}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="size"
              label={t('tournaments.numberOfTeamsLabel')}
              name="size"
              type="number"
              value={size}
              onChange={(event)=>
                setSize(event.target.value)
              }
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="price"
              label={t('common.price')}
              name="price"
              type="number"
              value={price}
              onChange={(event)=>
                setPrice(event.target.value)
              }
            />
            <Box sx={{mt: 3}}>
              <Typography style={{marginLeft: '5px'}}> {t('common.venue')}: </Typography>
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                apiOptions={{language: language, region: region}}
                selectProps={{
                  location,
                  onChange: setLocation,
                }}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: [country],
                  },
                }}
              />
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color={'success'}
              sx={{mt: 3, mb: 6}}
            >
              {t('common.create')}
            </Button>
          </Box>
        </Box>x
        <AlertSnackBar
          message={t('errors.genericErrorMessage')}
          severity={'error'}
          open={errorSnackBarOpen}
          onClose={() => setErrorSnackBarOpen(false)}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          autoHide={false}
        />
      </Box>
    </Container>
  );
};
