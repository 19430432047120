import React, {useEffect, useState} from 'react';
import {Box, CircularProgress, Container, Typography} from '@mui/material';
import {Navigate, useLocation, useParams} from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const JoinGameReturn = () => {
  const query = useQuery();
  const status = query.get('status');
  const params = useParams();
  const id = params.id;

  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldRedirect(true);
    }, 7000);
    return () => clearTimeout(timer);
  });

  const getContent = () => {
    if (status === 'success') {
      return (
        <>
          <CheckCircleIcon sx={{fontSize: '80px'}} color={'success'}/>
          <Typography variant="h5">
                        Your payment was successful! <br/>
                        Your spot has been reserved and you will
                        get the confirmation shortly.
                        It might take time to appear on the game page.
          </Typography>
        </>
      );
    }
    if (status === 'cancel') {
      return (
        <>
          <Typography>
                        Your payment process has been cancelled.
          </Typography>
        </>
      );
    }
  };

  return (
    <Container>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{
          m: '20%',
          textAlign: 'center',
          padding: '30px',
          borderColor: 'border.light',
        }}
        minHeight={'320px'}
        border={1}
        borderRadius={3}
      >
        {getContent()}
        <Box m={2}>
          <CircularProgress />
          <Typography> Redirecting</Typography>
          {shouldRedirect && (
            <Navigate to={`/games/${id}`}/>
          )}
        </Box>
      </Box>
    </Container>
  );
};
