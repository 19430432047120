import React, {useState} from 'react';
import {Box, Checkbox, FormControlLabel, Grid, TextField, Typography, useMediaQuery} from '@mui/material';
import {EmailTextField} from '../input/EmailTextfield';
import {PasswordTextField} from '../input/PasswordTextField';
import {ConfirmPasswordTextField} from '../input/ConfirmPasswordTextField';
import {LoadingButton} from '@mui/lab';
import {Link} from 'react-router-dom';
import {AlertSnackBar} from '../game/AlertSnackBar';
import {useDispatch} from 'react-redux';
import {useTheme} from '@mui/material/styles';
import {login, register} from '../../helpers';
import {validateInput} from '../../util';
import {useCookies} from 'react-cookie';
import {logIn} from '../../redux/actions';
import {useTranslation} from 'react-i18next';
import {setUserDetails, trackEvent} from '../../analytics';
import mixpanel from 'mixpanel-browser';
import PhoneInput from "react-phone-input-2";
import validator from "validator";

export const SignUpComponent = ({loading, signUpButtonText, onSuccessfulSignup, signInButton, onSignIn, style}) => {
  const {t} = useTranslation();
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [usernameExists, setUsernameExists] = useState(false);
  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
  const [invalidInput, setInvalidInput] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const dispatch = useDispatch();

  const track = (user) => {
    setUserDetails({
      userId: user.id,
      email: user.email,
      username: user.username,
      firstname: user.firstname,
      surname: user.surname,
    });
    // Identify the user using the Mixpanel People Analytics API
    mixpanel.identify(user.id);

    // Set user properties using the Mixpanel People Analytics API
    mixpanel.people.set({
      $name: user.firstname + '' + user.surname,
      $email: user.email,
      $username: user.username,
      $firstname: user.firstname,
      $surname: user.surname,
    });

    trackEvent('Successful register', {action: 'Sign up'});
  };

  const signUp = async (user) => {
    removeCookie('auth', {domain:'forza90.com'});
    try {
      const newUser = await register(user);
      setInvalidInput(false);
      setErrorSnackBarOpen(false);
      setUsernameExists(false);
      setEmailExists(false);
      setSubmitting(false);

      const data = await login({
        usernameOrEmail: newUser.username.toLowerCase().trim(),
        password,
      });
      const authToken = 'Bearer ' + data.jwtToken;
      const nextMonth = new Date();
      nextMonth.setDate(nextMonth.getDate() + 60); // two months
      setCookie('auth', authToken, {maxAge: 5260000, expires: nextMonth, domain: 'forza90.com'});
      dispatch(logIn());
      track(data.user);
      onSuccessfulSignup(authToken);
    } catch (error) {
      setSubmitting(false);
      trackEvent(
          'Failed sign up',
          {
            cause: error.response.data.message,
            username: username,
            email: email,
            firstname: user.firstname,
            surname: user.surname
          }
      );
      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data.message === 'USERNAME ALREADY EXISTS') {
            setUsernameExists(true);
            setInvalidInput(true);
          } else if (error.response.data.message === 'EMAIL ALREADY EXISTS') {
            setEmailExists(true);
            setInvalidInput(true);
          }
        } else {
          setErrorSnackBarOpen(true);
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        setErrorSnackBarOpen(true);
      } else {
        // Something happened in setting up the request that triggered an Error
        setErrorSnackBarOpen(true);
      }
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    setErrorSnackBarOpen(false);
    setInvalidInput(false);
    setInvalidPhone(false);
    const data = new FormData(event.currentTarget);

    trackEvent(
        'Sign up attempt',
        {
          action: 'Sign up',
          username: username,
          email: email,
          firstname: data.get('firstname'),
          surname: data.get('surname')},
    );

    if (!validateInput(password, confirmPassword, email)) {
      setInvalidInput(true);
      setErrorSnackBarOpen(true);
      setSubmitting(false);
      trackEvent(
          'Failed sign up',
          {
            cause: 'Invalid input',
            username: username,
            email: email,
            firstname: data.get('firstname'),
            surname: data.get('surname')},
      );
      return;
    }
    if (phone == null || !validator.isMobilePhone(phone)) {
      setErrorSnackBarOpen(true);
      setInvalidInput(true);
      setInvalidPhone(true);
      setSubmitting(false);
      trackEvent(
          'Failed sign up',
          {
            cause: 'Invalid phone',
            username: username,
            email: email,
            firstname: data.get('firstname'),
            surname: data.get('surname')},
      );
      return;
    }
    setInvalidInput(false);
    const user = {
      firstname: data.get('firstname'),
      surname: data.get('surname'),
      email: email.toLowerCase(),
      phone: phone,
      username: username.toLowerCase(),
      password,
    };
    signUp(user);
  };

  const signInLink = location.search == null ? '/signin' : `/signin${location.search}`;
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9-._]+$/;
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        mt: 2,
        bgcolor: 'background.paper',
        borderColor: 'border.light',
      }}
      style={{...style}}
      borderRadius="6px"
      // border={1}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            autoComplete="given-name"
            name="firstname"
            required
            fullWidth
            id="firstname"
            label={t('common.firstname')}
            autoFocus
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} >
          <TextField
            required
            fullWidth
            id="surname"
            label={t('common.surname')}
            name="surname"
            autoComplete="family-name"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneInput
              inputStyle={invalidPhone? {color: 'red', borderColor: 'red'} : {}}
              country={'lb'}
              // placeholder='Enter phone number'
              value={phone}
              specialLabel={''}
              onChange={setPhone}
          />
        </Grid>
        <Grid item xs={12}>
          <EmailTextField
            value={email}
            onChange={(newValue) => {
              setEmail(newValue);
              setEmailExists(false);
            }}
            emailExists={emailExists}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputProps={{maxLength: 20}}
            required
            fullWidth
            id="username"
            label={t('common.username')}
            name="username"
            autoComplete="username"
            variant="outlined"
            value={username}
            error={usernameExists}
            onChange={(event)=> {
              const value = event.target.value;
              if (value !== '' && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
                return;
              }
              setUsernameExists(false);
              setUsername(event.target.value);
            }}
            helperText={usernameExists ? t('errors.usernameExists') :
                t('registration.usernameHelperText')}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordTextField value={password} onChange={(value) => setPassword(value)}/>
        </Grid>
        <Grid item xs={12}>
          <ConfirmPasswordTextField
            password={password}
            value={confirmPassword}
            onChange={(value) => setConfirmPassword(value)}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Grid item>
          {signInButton? (
              <Typography variant="subtitle1" sx={{textTransform: 'none'}} color={'secondary.dark'} onClick={onSignIn}>
                {t('registration.alreadyHaveAnAccount')}
              </Typography>
            ) : (
                <Link to={signInLink}>
                  <Typography variant="subtitle1" color={'secondary.dark'}>
                    {t('registration.alreadyHaveAnAccount')}
                  </Typography>
                </Link>
            )}
        </Grid>
      </Grid>
      <Grid item xs={12} mt={2}>
        <FormControlLabel
          control={
            <Checkbox value="allowExtraEmails" color="secondary" />
          }
          label={t('common.termsAndConditionsAccept')}
        />
      </Grid>
      <LoadingButton
        type="submit"
        fullWidth
        variant="contained"
        sx={{mt: 1, mb: 2}}
        loading={submitting || loading}
        disabled={submitting || usernameExists || emailExists || loading}
        color={'secondary'}
      >
        {signUpButtonText}
      </LoadingButton>
      <AlertSnackBar
        message={invalidPhone? t('errors.invalidPhoneErrorMessage') :invalidInput ?
                    t('errors.addressErrorsMessage') : t('errors.genericErrorMessage')}
        severity={'error'}
        open={errorSnackBarOpen}
        onClose={() => setErrorSnackBarOpen(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHide={false}
        sx={{marginTop: smallScreen? 0: '50px'}}
      />
    </Box>
  );
};
