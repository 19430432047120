import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';
import {geocodeByAddress, getLatLng} from 'react-google-places-autocomplete';
import {toNumber} from 'lodash';

export const MapComponent = ({location}) => {
  const [latitude, setLatitude] = useState(51.4971562);
  const [longitude, setLongitude] = useState(-0.1168206);
  const [url, setUrl] = useState('');
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAAshjfW3KpxX12xl9D8ly_w6G3hGI_ho0',
  });

  useEffect(()=> {
    if (isLoaded) {
      geocodeByAddress(location)
          .then((results) => {
            setUrl(`https://www.google.com/maps/search/?api=1&query=${results[0].formatted_address}&query_place_id=${results[0].place_id}`);
            return getLatLng(results[0]);
          })
          .then((latLng) => {
            setLatitude(latLng.lat);
            setLongitude(latLng.lng);
          })
          .catch((error) => console.error('Error', error));
    }
  }, [isLoaded]);

  const containerStyle = {
    width: '90%',
    height: '40vh',
    alignSelf: 'center',
    marginBottom: '20px',
    marginTop: '10px',
  };

  return isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{lat: toNumber(latitude), lng: toNumber(longitude)}}
          zoom={15}
        >
          <Marker
            position={{lat: toNumber(latitude), lng: toNumber(longitude)}}
            onClick={()=> window.open(url)}
          />
        </GoogleMap>
    ) : (
        <Box
          style={{height: '30vh', width: '90%', borderColor: 'aliceblue'}}
          alignSelf={'center'}
          my={2}
          border={2}
          borderRadius={'4px'}
        />
    );
};
