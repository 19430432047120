import React from 'react';

import {useTranslation} from 'react-i18next';
import {MenuItem, Select} from '@mui/material';

export const LanguageSwitcher = () => {
  const {i18n} = useTranslation();
  return (
    <Select
      variant={'outlined'}
      sx={{'boxShadow': 'none', '.MuiOutlinedInput-notchedOutline': {border: 0}}}
      style={{fontSize: '20px', fontWeight: 'bolder'}}
      value={i18n.language || 'en'}
      onChange={(e) =>
        i18n.changeLanguage(e.target.value)
      }
    >
      <MenuItem value="ar" sx={{fontWeight: 'bolder', fontSize: '20px'}}>🇸🇦 عربي</MenuItem>
      <MenuItem value="en" sx={{fontWeight: 'bolder', fontSize: '20px'}}>🇬🇧 English</MenuItem>
    </Select>
  );
};
