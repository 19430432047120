import React, {useContext, useEffect, useState} from 'react';
import {DateTimePicker} from '../../components/input/DateTimePicker';
import {GameSizeSelect} from '../../components/game/GameSizeSelect';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import GooglePlacesAutocomplete, {geocodeByAddress} from 'react-google-places-autocomplete';
import axios from 'axios';
import {useCookies} from 'react-cookie';
import {GenderRadio} from '../../components/input/GenderRadio';
import {PriceRadio} from '../../components/input/PriceRadio';
import {useTheme} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';
import {useLocation, useNavigate} from 'react-router-dom';
import {getDefaultPlayers, getRandomGameCover, getReservedPlayers} from '../../components/game/util';
import {useSelector} from 'react-redux';
import {AlertSnackBar} from '../../components/game/AlertSnackBar';
import {fetchAccountLink, fetchConnectedAccount, uploadGameCoverPicture,} from '../../helpers';
import {GameCoverPicture} from '../../components/game/GameCoverPicture';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import {LockRounded} from '@mui/icons-material';
import {fetchTimezoneFromCoordinates} from '../../util/timezoneUtils';
import {DomainContext} from '../../DomainContext';
import {PlayersContainer} from "../../components/game/PlayersContainer";
import {currencies} from "../../constants/Currency";

export const CreateGame = () => {
  const isLoggedIn = useSelector((state) => state.isLogged);
  const {country, language, region} = useContext(DomainContext);
  const prevLocation = useLocation();
  const navigate = useNavigate();

  const [dateValue, setDateValue] = useState(new Date);
  const [timeValue, setTimeValue] = useState(new Date);
  const [players, setPlayers] = useState(getDefaultPlayers(10));
  const [size, setSize] = useState(10);
  const [spotsLeft, setSpotsLeft] = useState(10);
  const [location, setLocation] = useState(null);
  const [price, setPrice] = useState('');
  const [paymentType, setPaymentType] = useState('cash');
  const [isPrivate, setIsPrivate] = useState(false);
  const [onboarded, setOnboarded] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [selectedCover, setSelectedCover] = useState(null);
  const defaultCover = getRandomGameCover(size);

  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
  const [invalidLocation, setInvalidLocation] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const authToken = cookies.auth;

  const updateOnboardedStatus = async () => {
    try {
      const account = await fetchConnectedAccount(cookies.auth);
      setOnboarded(account.charges_enabled);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(()=> {
    updateOnboardedStatus();
  }, [isLoggedIn]);

  const createNewGame = (body) => {
    axios
        .post(`${process.env.REACT_APP_API_URL}/games`, body, {headers: {'Authorization': authToken}} )
        .then((res) => {
          console.log(res);
          setErrorSnackBarOpen(false);
          return res.data.gameId;
        })
        .then((gameId) => {
          return selectedCover? uploadGameCoverPicture(gameId, selectedCover, authToken) : gameId;
        })
        .then((res) => navigate('/games'))
        .catch((err) => {
          console.error(err.message);
          setErrorSnackBarOpen(true);
        });
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!location) {
      setInvalidLocation(true);
      setErrorSnackBarOpen(true);
      return;
    }
    setErrorSnackBarOpen(false);
    const data = new FormData(event.currentTarget);
    const dateTime = new Date(dateValue);
    dateTime.setHours(timeValue.getHours());
    dateTime.setMinutes(timeValue.getMinutes());


    const reservedPlayers = [];
    for (let i = spotsLeft; i < size; i++) {
      reservedPlayers.push({
        userId: 1, // reserved for guests
        preferredPosition: 'any',
      });
    }

    const address = await geocodeByAddress(location.label);
    const {lat, lng} = address[0].geometry.location;
    const timezoneResult = await fetchTimezoneFromCoordinates(lat(), lng());
    const venue = {
      name: location.label,
      location: address[0].formatted_address,
      ianaTimezone: timezoneResult.timeZoneId,
    };

    const tzoffset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
    const dateTimeString = (new Date(dateTime - tzoffset)).toISOString().replace('T', ' ').substring(0, 16);
    const game = {
      size,
      dateTime: dateTimeString,
      price: paymentType === 'online' || 'cash'? data.get('price') : 0,
      paymentCurrency: paymentType === 'online' || 'cash'? data.get('currency') : null,
      title: data.get('title'),
      description: data.get('description'),
      gender: data.get('gender'),
      players: reservedPlayers,
      paymentMethod: paymentType,
      isPrivate: isPrivate,
      secret: isPrivate? data.get('secret') : null,
    };
    createNewGame({game, venue});
  };

  const onSpotsLeftChange = (event) => {
    const newSpotsLeft = event.target.value;
    setSpotsLeft(newSpotsLeft);
    updatePlayers(size, newSpotsLeft);
  };

  const updatePlayers = (newSize, newSpotsLeft) => {
    const newPlayers = [];
    const reservedPlayers = getReservedPlayers(newSize - newSpotsLeft);
    const defaultPlayers = getDefaultPlayers(newSpotsLeft);
    newPlayers.push(...reservedPlayers);
    newPlayers.push(...defaultPlayers);
    setPlayers(newPlayers);
  };

  const onGameSizeChange = (newSize) => {
    setSize(newSize);
    setSpotsLeft(newSize);
    updatePlayers(newSize, newSize);
  };

  const handleSetupAccount = async () => {
    try {
      const accountLink = await fetchAccountLink(cookies.auth);
      window.location.replace(accountLink);
    } catch (e) {
      console.error(e);
      setErrorSnackBarOpen(true);
    }
  };

  const checkDisabled = (value)=> {
    if (!onboarded && value === 'online') {
      setFormDisabled(true);
      return;
    }
    setFormDisabled(false);
  };

  const onSelectCover = (fileObj) => {
    if (!fileObj) {
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedCover(fileObj);
  };

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const useStyles = makeStyles({
    root: {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  });
  const classes = useStyles();


  return (
    <Container className={classes.root} component="main" maxWidth={'md'}>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} >
        <Typography variant={smallScreen?'h5': 'h4'} m={smallScreen? 2 : 6}>
        Create a Game
        </Typography>
        {/*<GameCoverPicture onFileChange={onSelectCover} previewFile={selectedCover} editable defaultCover={defaultCover}/>*/}
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'background.paper',
            borderColor: 'border.light',
            padding: '30px',
            width: '100%',
            boxSizing: 'border-box',
          }}
          borderRadius="6px"
          border={1}
        >

          <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}}>
            <Box marginTop={1}>
              <PriceRadio
                onChange={(event) => {
                  setPaymentType(event.target.value);
                  checkDisabled(event.target.value);
                }}
                value={paymentType}
              />
              {paymentType === 'online' && !onboarded && (
                <Box display={'flex'} flexDirection={'column'}>
                  <Typography variant={'subtitle1'} color={'error'}>To accept payments online, please set up your organiser account</Typography>
                  <Button onClick={handleSetupAccount} variant={'contained'} color={'success'}> Setup account</Button>
                </Box>
              )}
              {paymentType === 'online' && onboarded &&
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="price"
                  label="Price"
                  name="price"
                  type="number"
                  value={price}
                  onChange={(event)=> setPrice(event.target.value)}
                  disabled={formDisabled}
                />}
              {paymentType === 'cash' &&
                  <Box display={"flex"} alignItems={"center"}>
                    <TextField
                        sx={{mr: 2, mt:1}}
                        style={{minWidth: "85px"}}
                        id="currency"
                        name="currency"
                        select
                        defaultValue="LBP"
                    >
                      {currencies.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="price"
                        label="Price"
                        name="price"
                        type="number"
                        value={price}
                        onChange={(event)=> setPrice(event.target.value)}
                        disabled={formDisabled}
                        InputProps={{
                          inputProps: { min: 0 }
                        }}
                    />
                  </Box>

                  }
            </Box>
            <TextField
              margin="normal"
              required
              fullWidth
              id="title"
              label="Title"
              name="title"
              autoFocus
              disabled={formDisabled}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="description"
              label="Description"
              name="description"
              multiline
              rows={3}
              disabled={formDisabled}
            />

            <DateTimePicker
              onDateChange={(newValue) => setDateValue(newValue)}
              onTimeChange={(newValue) => setTimeValue(newValue)}
              dateValue={dateValue}
              timeValue={timeValue}
              disabled={formDisabled}
            />
            <GameSizeSelect
              onChange={onGameSizeChange}
              value={size}
              disabled={formDisabled}
            />
            {/* <Box sx={{minWidth: 120, mt: 3, mb: 3}}>*/}
            {/*  <FormControl fullWidth>*/}
            {/*    <InputLabel>Spots Left</InputLabel>*/}
            {/*    <Select*/}
            {/*      value={spotsLeft}*/}
            {/*      label="Spots Available"*/}
            {/*      onChange={onSpotsLeftChange}*/}
            {/*    >*/}
            {/*      {[...Array(size)].map((x, i) =>*/}
            {/*        <MenuItem key={i} value={i+1}>{i+1}</MenuItem>,*/}
            {/*      )}*/}
            {/*    </Select>*/}
            {/*  </FormControl>*/}
            {/* </Box>*/}
            <GenderRadio/>
            <Box display={'flex'} flexDirection={'column'}>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<LockOpenRoundedIcon color={'success'}/>}
                    checkedIcon={<LockRounded color={'primary'}/>}
                    checked={isPrivate}
                    onChange={()=> setIsPrivate(!isPrivate)}
                  />
                }
                label={isPrivate? 'Private' : 'Public'}
              />
              {isPrivate &&
                <TextField
                  margin="normal"
                  required
                  id="secret"
                  label="Secret"
                  name="secret"
                  autoFocus
                  disabled={formDisabled}
                />
              }
            </Box>
            <Box sx={{mt: 3}}>
              <Typography style={{marginLeft: '5px'}}> Venue: </Typography>
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                apiOptions={{language: language, region: region}}
                selectProps={{
                  location,
                  onChange: setLocation,
                }}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: [country],
                  },
                }}
              />
            </Box>

            <Button
              color={'secondary'}
              type="submit"
              fullWidth
              variant="contained"
              sx={{mt: 3, mb: 2}}
              disabled={formDisabled}
            >
            Create
            </Button>
          </Box>
        </Box>
        <Box
          flexGrow={1}
          flex={1}
          width={'100%'}
          alignItems={'center'}
          sx={{
            mt: 2,
            mb: 4,
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'background.paper',
            borderColor: 'border.light',
            padding: '25px',
            boxSizing: 'border-box',
          }}
          style={{
            height: '50%',
          }}
          borderRadius="6px"
          border={1}
        >
          <Typography variant="h6" alignSelf={'center'} sx={{mt: -2, mb: 2}}>
              Players
          </Typography>
          <PlayersContainer size={size} players={players}/>
        </Box>
        <AlertSnackBar
          message={invalidLocation ?
              'Please enter a valid venue location.' :
              'An error occurred. Please refresh the page or try again later.'}
          severity={'error'}
          open={errorSnackBarOpen}
          onClose={() => setErrorSnackBarOpen(false)}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          autoHide={false}
        />
      </Box>
    </Container>
  );
};
