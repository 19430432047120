import React from 'react';
import {Link} from 'react-router-dom';
import {Box} from '@mui/material';
import logo from '../../assets/blackRedLogo.png';

export const Logo = () => {
  return (
    <Box>
      <Link to={'/'} style={{textDecoration: 'none', color: 'inherit'}}>
        <Box
          component="img"
          sx={{
            // height: 50,
            width: 85,
            // maxHeight: { xs: 233, md: 167 },
            // maxWidth: { xs: 350, md: 250 },
          }}
          marginTop={2}
          alt="Forza90"
          src={logo}
        />
        {/* <Typography variant="h5">*/}
        {/*    FORZA 90*/}
        {/* </Typography>*/}
      </Link>
    </Box>
  );
};
