import React, {useEffect, useState} from 'react';
import {Avatar, Box, Button, Icon, Typography} from '@mui/material';
import {Link, useNavigate} from 'react-router-dom';
import CaptainIcon from '../../../assets/icons/captain.png';
import {formatDateCustom} from '../../game/util';
import {useTranslation} from 'react-i18next';
import {TeamCard} from '../../teams/TeamCard';
import {GameInfoBox} from '../../containers/GameInfoBox';
import {fetchPublicProfile, fetchTeam, fetchTeamPlayers} from '../../../helpers';

const emojis = ['🔥', '😮‍💨', '🎯', '🦾', '✨', '🌟', '😎', '🥶', '🫡',
  '😴', '💀', '☠️', '🦿', '👀', '🧠', '🥷', '💥', '⚡️', '💫', '🌪️', '🚀', '⏳', '❤️‍🔥', '🚫',
];

const statusOptions = {};

export const TournamentTeamCard = ({teamSize, id, status}) => {
  const [players, setPlayers] = useState([]);

  const updatePlayers = async () => {
    try {
      const players = await fetchTeamPlayers(id);
      setPlayers(players);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(()=> {
    updatePlayers();
  }, []);


  const getStatusColor = () => {
    switch (status) {
      case 'PENDING':
        return 'grey';
      case 'CONFIRMED':
        return 'green';
      case 'PAID':
        return 'green';
      case 'WAITING':
        return 'orange';
      case 'REMOVED':
        return 'red';
      default:
        return 'black';
    }
  };

  const getStatusPrompt = () => {
    switch (status) {
      case 'PENDING':
        return `Invite ${teamSize - players.length} more players to confirm your spot.`;
      case 'CONFIRMED':
        return '';
      case 'PAID':
        return '';
      case 'WAITING':
        return 'Your team is on the waiting list.';
      case 'REMOVED':
        return 'Your team has been removed.';
      default:
        return '';
    }
  };

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Box display={'flex'} alignItems={'center'}>
        <Typography variant={'h5'} fontWeight={'bold'}> My Team </Typography>
        <Typography variant={'h6'} ml={0.5} fontWeight={'bold'} color={getStatusColor()}>
                    ({status})
        </Typography>
      </Box>
      <Typography color={'#0e6bbc'}> {getStatusPrompt()}</Typography>
      <GameInfoBox style={{alignSelf: 'center'}}>
        <TeamCard id={id} />
      </GameInfoBox>
    </Box>
  );
};
