import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';

export const NonAuthenticatedRoute = ({children, redirectRoute = '/games'}) => {
  const isLoggedIn = useSelector((state) => state.isLogged);
  const location = useLocation();
  if (isLoggedIn) {
    const origin = location.state?.from?.pathname || redirectRoute;
    return <Navigate to={origin} replace />;
  }
  return children;
};
