export const logIn = () => {
  return {
    type: 'LOGIN',
  };
};
export const logOut = () => {
  return {
    type: 'LOGOUT',
  };
};

export const toggleTheme = () => {
  return {
    type: 'TOGGLETHEME',
  };
};
