import React, {useEffect, useState} from 'react';
import {Box, Button, Icon, Skeleton, Stack, Typography} from '@mui/material';
import {Link, useNavigate} from 'react-router-dom';
import CaptainIcon from '../../assets/icons/captain.png';
import {formatDateCustom} from '../game/util';
import {useTranslation} from 'react-i18next';
import {fetchPublicProfile, fetchTeam, fetchTeamPlayers} from '../../helpers';
import {useCookies} from 'react-cookie';
import {SmallPlayerAvatar} from './player/SmallPlayerAvatar';

const emojis = ['🔥', '😮‍💨', '🎯', '🦾', '✨', '🌟', '😎', '🥶', '🫡',
  '😴', '💀', '☠️', '🦿', '👀', '🧠', '🥷', '💥', '⚡️', '💫', '🌪️', '🚀', '⏳', '❤️‍🔥', '🚫',
];

export const TeamCard = ({id}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [team, setTeam] = useState(null);
  const [captain, setCaptain] = useState(null);
  const [players, setPlayers] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const authToken = cookies.auth;

  const name = team? `${emojis[team.id % emojis.length]} ${team.name}` : null;
  const captainName = captain? captain.firstname + ' ' + captain.surname : null;
  const date = team? formatDateCustom(team.createdAt, 'MMM YYYY') : null;


  const updateTeam = async () => {
    try {
      const response = await fetchTeam(id, authToken);
      setTeam(response);

      const players = await fetchTeamPlayers(id);
      setPlayers(players);

      const profile = await fetchPublicProfile(response.creatorId);
      setCaptain(profile);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(()=> {
    updateTeam();
  }, []);

  return (
    <Stack p={1}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box display={'flex'}>
          <Icon sx={{mr: 1}}>
            <img src={CaptainIcon} height={'20px'} alt={'captain'}/>
          </Icon>
          {captainName?
                  <Link
                    to={`/users/${captain.userId}/profile`}
                    style={{textDecoration: 'none', color: 'inherit'}}
                  >
                    <Typography variant={'subtitle2'} >
                      {captainName}
                    </Typography>
                  </Link> :
                  <Skeleton animation={'wave'} width={100}/>
          }
        </Box>
        <Typography variant={'subtitle2'} color={'#909090'}>
          {date?
                  t('teams.sinceDate', {date: date}) :
                  <Skeleton animation={'wave'} width={70}/>
          }
        </Typography>

      </Box>
      <Typography
        my={1}
        alignSelf={'center'}
        variant={'h4'}
        fontWeight={'bolder'}
        color={'primary'}
      >
        {name || <Skeleton width={200} animation={'wave'}/>}
      </Typography>

      <Box display={'flex'} justifyContent={'space-between'} >
        {players && players.length !== 0?
              <Box display={'flex'} flexDirection={'row'}>
                {players.map((userId) => (
                  <SmallPlayerAvatar userId={userId}/>
                ))}
              </Box> :
              <Skeleton animation={'wave'} variant={'rounded'} width={200}/>
        }

        <Button
          variant={'text'}
          onClick={()=> navigate(`/teams/${id}`)}
          color={'secondary'}
        >
          {t('teams.view')}
        </Button>
      </Box>
    </Stack>
  );
};
