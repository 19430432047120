import * as React from 'react';
import {AppBar, Box, Button, Container, Toolbar, Typography} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {AlertSnackBar} from '../../components/game/AlertSnackBar';
import {
  fetchConfirmedTournamentTeams,
  fetchPublicProfile,
  fetchTournament,
  fetchTournamentRounds,
  fetchUserTournamentTeam, fetchVenue,
  fetchWaitlistTournamentTeams,
}
  from '../../helpers';
import {TournamentDetailsView} from '../../components/tournament/TournamentDetailsView';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {trackEvent} from '../../analytics';

export const Tournament = () => {
  const {t} = useTranslation();
  const params = useParams();
  const id = params.id;
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const [tournament, setTournament] = useState(null);
  const [teams, setTeams] = useState([]);
  const [waitlistTeams, setWaitlistTeams] = useState([]);
  const [organiser, setOrganiser] = useState(null);
  const [rounds, setRounds] = useState(null);
  const [isFull, setIsFull] = useState(false);
  const [isOrganiser, setIsOrganiser] = useState(false);
  const [userTeam, setUserTeam] = useState(null);
  const [venue, setVenue] = useState(null);
  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [joinTournamentSnackBarOpen, setJoinTournamentSnackBarOpen] = useState(false);
  const tournamentUrl = `${process.env.REACT_APP_APP_URL}/tournaments/${id}`;

  const [inviteFriendsSnackBarOpen, setInviteFriendsSnackBarOpen] = useState(false);
  const authToken = cookies.auth;

  const isLoggedIn = useSelector((state) => state.isLogged);

  const updateTournament = async () => {
    try {
      const tournament = await fetchTournament(id, authToken);
      setTournament(tournament);
      setIsOrganiser(tournament.isOrganiser);

      const tournamentTeams = await fetchConfirmedTournamentTeams(id);
      setTeams(tournamentTeams);
      setIsFull(tournamentTeams? tournament.size === tournamentTeams.length : false);

      const waitlistTeams = await fetchWaitlistTournamentTeams(id);
      setWaitlistTeams(waitlistTeams);


      const organiser = await fetchPublicProfile(tournament.organiserId);
      setOrganiser(organiser);

      const rounds = await fetchTournamentRounds(id);
      setRounds(rounds);

      const venueResponse = await fetchVenue(tournament.venueId);
      setVenue(venueResponse);

      if (isLoggedIn) {
        const userTournamentTeam = await fetchUserTournamentTeam(authToken, id);
        setUserTeam(userTournamentTeam);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(()=> {
    updateTournament();
  }, [isLoggedIn]);

  const onJoinTournament = () => {
    trackEvent(
        'Join tournament attempt',
        {action: 'Join tournament', tournamentId: id, tournamentName: tournament.name},
    );
    navigate(`/tournaments/${id}/teams/create`);
  };

  return (
    <Container style={{padding: '0px'}}>
      {tournament && (
        <TournamentDetailsView
          tournament={tournament}
          teams={teams}
          waitlistTeams={waitlistTeams}
          rounds={rounds}
          userTeam={userTeam}
          organiser={organiser}
          isOrganiser={isOrganiser}
          venue={venue}
          onJoin={onJoinTournament}
          onInvite={()=> {
            navigator.clipboard.writeText(tournamentUrl);
            setInviteFriendsSnackBarOpen(true);
          }}
        />
      )}
      <AppBar position="fixed" color="white" sx={{top: 'auto', bottom: 0}}>
        <Toolbar
          sx={{
            alignItems: 'center',
            py: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box display={'flex'} flexDirection={'column'}>
            <Typography fontWeight={'bold'}>
              {t('tournament.registrationsClose')}
            </Typography>
            <Typography color={'secondary'} fontWeight={'bolder'}>
              {t('common.soon')}...
            </Typography>
          </Box>
          <Button
            variant={'contained'}
            sx={{
              borderRadius: '8px',
              bgcolor: 'secondary.dark',
              py: 1,
              px: 3,
              fontSize: '15px',
              fontWeight: 'bold',
              textTransform: 'none',
            }}
            onClick={onJoinTournament}
            disabled={userTeam != null}
          >
            {isFull? t('tournament.joinWaitlist') : t('tournament.joinNow')}
          </Button>
        </Toolbar>
      </AppBar>
      <AlertSnackBar
        message={t('alerts.linkCopied')}
        severity={'success'}
        open={inviteFriendsSnackBarOpen}
        onClose={() => setInviteFriendsSnackBarOpen(false)}
        autoHide
      />

      <AlertSnackBar
        message={t('errors.genericErrorMessage')}
        severity={'error'}
        open={errorSnackBarOpen}
        onClose={() => setErrorSnackBarOpen(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHide={false}
      />

      <AlertSnackBar
        message={t('alerts.joinedTournament')}
        severity={'success'}
        open={joinTournamentSnackBarOpen}
        onClose={() => setJoinTournamentSnackBarOpen(false)}
        autoHide
      />
    </Container>
  );
};
