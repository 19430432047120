import * as React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';
import {Autocomplete, TextField} from "@mui/material";
import {useEffect, useState} from "react";

const options = ["12", "14", "16", "18", "20", "22"];
export const GameSizeSelect = (props) => {
  const [currentValue, setCurrentValue] = useState(String(props.value));
  const [inputValue, setInputValue] = React.useState('');

  const onInputValueChange = (event, newInputValue) => {
      setInputValue(newInputValue);
      setCurrentValue(newInputValue);
      props.onChange(Number(newInputValue));
  }

  const onCurrentValueChange = (event, newValue) => {
      if (!newValue) return;
      setCurrentValue(newValue);
      props.onChange(Number(newValue));
  }

    useEffect(()=> {
        setCurrentValue(String(props.value));
    }, [props.value]);

  return (
    <Box sx={{minWidth: 120, mt: 3, mb: 3}}>
      <FormControl fullWidth>
        <Autocomplete
            value={currentValue}
            onChange={onCurrentValueChange}
            inputValue={inputValue}
            onInputChange={onInputValueChange}
            id="game-size-select"
            options={options}
            freeSolo
            renderInput={(params) => <TextField {...params} type={"number"} required label="Number of Players"/>}
        />
      </FormControl>
    </Box>
  );
};

GameSizeSelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
};
