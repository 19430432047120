import React from 'react';
import {Home} from '../pages/home/Home';
import {Games} from '../pages/games/Games';
import {Game} from '../pages/games/Game';
import {SignIn} from '../pages/register/SignIn';
import {SignUp} from '../pages/register/SignUp';
import {Teams} from '../pages/teams/Teams';
import {Tournaments} from '../pages/tournaments/Tournaments';
import {ForgotPassword} from '../pages/register/ForgotPassword';
import {CreateGame} from '../pages/games/CreateGame';
import {ResetPassword} from '../pages/register/ResetPassword';
import {EditGame} from '../pages/games/EditGame';
import {ManagePlayers} from '../pages/games/ManagePlayers';
import {About} from '../pages/About';
import {Profile} from '../pages/Profile';
import {JoinGameReturn} from '../pages/games/JoinGameReturn';
import {Team} from '../pages/teams/Team';
import {ManageTeamPlayers} from '../pages/teams/ManageTeamPlayers';
import {TeamCreateFlow} from '../pages/teams/TeamCreateFlow';
import {AcceptInvite} from '../pages/teams/AcceptInvite';
import {EditTeam} from '../pages/teams/EditTeam';
import {Tournament} from '../pages/tournaments/Tournament';
import {CreateTournament} from '../pages/tournaments/CreateTournament';
import {CreateTournamentRound} from '../pages/tournaments/round/CreateTournamentRound';
import {TournamentRound} from '../pages/tournaments/round/TournamentRound';
import {ManageRoundTeams} from '../pages/tournaments/round/ManageRoundTeams';
import {ManageTournamentTeams} from '../pages/tournaments/ManageTournamentTeams';
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsAndConditions from "../pages/TermsAndConditions";

// todo split them automatically
// const isProtected = (route) => protectedRoutes.some((protectedRoute) => route.includes(protectedRoute));

export const routes = [
  {path: '/about', element: <About />},
  {path: '/privacy', element: <PrivacyPolicy />},
  {path: '/terms', element: <TermsAndConditions />},
  {path: '/teams/signup', element: <TeamCreateFlow currentStep={0} />},
  {path: '/teams/:id', element: <Team />},
  {path: '/teams/:id/join', element: <AcceptInvite />},
  {path: '/tournaments/:id', element: <Tournament />},
  {path: '/tournaments/:tournamentId/rounds/:id', element: <TournamentRound />},
  {path: '/users/:id/profile', element: <Profile publicProfile/>},
  {path: '/games/:id', element: <Game />},
  {path: '/games/:id/join/return', element: <JoinGameReturn />},
];

/**
 * Routes that are rendered with the bottom navigation bar
 */
export const navRoutes = [
  {path: '/', element: <Home />},
  {path: '/teams', element: <Teams />},
  {path: '/tournaments', element: <Tournaments />},
  {path: '/games', element: <Games />},
];

export const authNavRoutes = [
  {path: '/myprofile', element: <Profile />},
];
/**
 * Routes that are wrapped with AuthenticatedRoute
 */
export const authRoutes = [
  {path: '/games/create', element: <CreateGame />},
  {path: '/teams/:id/manage', element: <ManageTeamPlayers />},
  {path: '/teams/:id/edit', element: <EditTeam />},
  {path: '/teams/:id/create/invite', element: <TeamCreateFlow currentStep={2} />},
  {path: '/teams/:id/create/join', element: <TeamCreateFlow currentStep={3}/>},
  {path: '/tournaments/:id/manage', element: <ManageTournamentTeams />},
  {path: '/tournaments/create', element: <CreateTournament />},
  {path: '/tournaments/:id/rounds/create', element: <CreateTournamentRound />},
  {path: '/tournaments/:tournamentId/teams/create', authRoute: '/teams/signin', element: <TeamCreateFlow currentStep={1} />},
  {path: '/tournaments/:tournamentId/rounds/:id/manage', element: <ManageRoundTeams />},
  {path: '/games/:id/edit', element: <EditGame />},
  {path: '/games/:id/players/edit', element: <ManagePlayers />},
];

/**
 * Routes that are wrapped with NonAuthenticatedRoute and have bottom nav bar
 */
export const nonAuthNavRoutes = [
  {path: '/signin', element: <SignIn />},
  {path: '/signin/forgot', element: <ForgotPassword />},
  {path: '/signup', element: <SignUp />},
];
/**
 * Routes that are wrapped with NonAuthenticatedRoute
 */
export const nonAuthRoutes = [
  {path: '/teams/signin', redirectRoute: '/teams/create', element: <TeamCreateFlow currentStep={0} />},
  {path: '/resetpassword/:token', element: <ResetPassword/>},
];
