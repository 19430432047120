import React, {useEffect, useState} from 'react';
import {Avatar, IconButton} from '@mui/material';
import Typography from '@mui/material/Typography';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import {Link} from 'react-router-dom';
import {fetchPublicProfile} from '../../helpers';

export const PlayerWithAvatar = (props) => {
  const [profilePictureUrl, setProfilePictureUrl] = useState(null);

  const updatePlayer = async () => {
    try {
      const profile = await fetchPublicProfile(props.userId);
      setProfilePictureUrl(profile.profilePictureUrl);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(()=> {
    if ('user' === props.type) {
      updatePlayer();
    }
  }, []);

  const getAvatar = () => {
    if (props.type === 'user' && profilePictureUrl != null) {
      return (
        <Link
          to={`/users/${props.userId}/profile`}
          style={{textDecoration: 'none'}}
        >
          <Avatar sx={{bgcolor: 'primary.dark'}} src={profilePictureUrl}/>
        </Link>
      );
    } else if (props.type === 'user') {
      return (
        <Link
          to={`/users/${props.userId}/profile`}
          style={{textDecoration: 'none'}}
        >
          <Avatar sx={{bgcolor: 'primary.dark'}}>
            <PersonIcon/>
          </Avatar>
        </Link>
      );
    }
    return (
      <Avatar sx={{bgcolor: 'primary.dark'}}>
        <PersonIcon/>
      </Avatar>
    );
  };
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: '10px',
      }}
      justifyContent={props.reverse? 'end': 'start'}
      minWidth={'150px'}
    >
      {props.reverse &&
      <Typography style={{margin: '7px'}}> {props.name} </Typography> }
      {props.type === 'reserved' ||
      props.type === 'user' || props.type === 'guest'? (
          getAvatar()
      ) : (
          <Avatar sx={{bgcolor: props.editable? 'rgba(10,182,10,0.78)' : 'infoIcon'}}>
            <IconButton
              onClick={props.onAddPlayer}
              aria-label={'add player'}
              disabled={!props.editable}
            >
              <AddIcon/>
            </IconButton>
          </Avatar>
      )}
      {!props.reverse &&
      <Typography style={{margin: '7px'}} >{props.name}</Typography> }
    </Box>
  );
};

PlayerWithAvatar.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  reverse: PropTypes.bool, // if true place name then avatar
  editable: PropTypes.bool,
  onAddPlayer: PropTypes.func,
  userId: PropTypes.number,
};
