import * as React from 'react';
import {Avatar, Badge, Box, IconButton} from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PropTypes from 'prop-types';

export const ProfilePicture = ({url, onFileChange, editable}) => {
  const fileInput = React.createRef();
  const handleClick = () => {
    // 👇️ open file input box on click of other element
    fileInput.current.click();
  };

  return (
    <Box display={'flex'} flexDirection={'row'}>
      <input
        style={{
          display: 'none',
        }}
        ref={fileInput}
        type="file"
        onChange={() => onFileChange(fileInput.current.files && fileInput.current.files[0])}
      />
      {editable? (
            <Badge
              overlap="circular"
              anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
              badgeContent={
                <IconButton onClick={handleClick} sx={{bgcolor: '#bdbdbd', borderColor: 'black', border: 2}}>
                  <AddAPhotoIcon sx={{fontSize: 15}}/>
                </IconButton>
              }
            >
              <Avatar
                alt="Profile Picture"
                src={url}
                style={{
                  border: '2px solid black',
                }}
                sx={{width: 100, height: 100}}
                color={'black'}
              />
            </Badge>
        ) : (
            <Avatar
              alt="Profile Picture"
              src={url}
              style={{
                border: '2px solid black',
              }}
              sx={{width: 100, height: 100}}
              color={'black'}
            />
        )}

    </Box>
  );
};
ProfilePicture.propTypes = {
  url: PropTypes.string,
  onFileChange: PropTypes.func,
  editable: PropTypes.bool,
};
