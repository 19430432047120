import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Box, Divider, Typography} from '@mui/material';
import moment from 'moment';
import Grid2 from '@mui/material/Unstable_Grid2';
import {useTranslation} from 'react-i18next';
export const GameCardsContainer = (props) => {
  const {t} = useTranslation();
  const [date, setDate] = React.useState(null);
  const today = moment().format('YYYY-MM-DD');
  const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
  useEffect(()=> {
    if (!props.featured) {
      if (props.date === today) {
        setDate('Today');
      } else if (props.date === tomorrow) {
        setDate('Tomorrow');
      } else {
        setDate(moment(props.date).format('dddd, MMM Do'));
      }
    }
  }, []);


  return (
    <Box
      style={{
        ...(props.style),
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box borderRadius="2px" m={1}>
        <Divider textAlign={'center'}>
          <Typography variant={'h5'} fontWeight={'bold'} >
            {props.featured? t('games.featured') : date}
          </Typography>
        </Divider>

      </Box>
      <Grid2 container spacing={4} p={4} columns={1}>
        {props.children}
      </Grid2>
    </Box>
  );
};

GameCardsContainer.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  date: PropTypes.string,
  spacing: PropTypes.number,
  featured: PropTypes.bool,
};
