import moment from 'moment';

import Cover1 from '../../../assets/gameCovers/1.jpeg';
import Cover2 from '../../../assets/gameCovers/2.jpg';
import Cover3 from '../../../assets/gameCovers/3.jpg';
import Cover4 from '../../../assets/gameCovers/4.jpg';
import Cover5 from '../../../assets/gameCovers/5.jpg';
import Cover6 from '../../../assets/gameCovers/6.jpg';
import Cover7 from '../../../assets/gameCovers/7.jpg';
import {BiCalendarEvent} from 'react-icons/bi';

const gameCovers = [Cover1, Cover2, Cover3, Cover4, Cover5, Cover6, Cover7];

export const getDefaultPlayer = (id) => {
  return {
    name: 'Open Spot',
    id,
    type: 'default',
  };
};

export const getReservedPlayer = (id) => {
  return {
    name: 'Reserved',
    id,
    type: 'reserved',
  };
};

export const getPlayerWithName = (player) => {
  return {
    name: player.name,
    id: player.id,
    type: player.type,
    profilePictureUrl: player.profilePictureUrl,
    userId: player.userId,
  };
};

export const getReservedPlayers = (number) => {
  const reservedPlayers = [];
  for (let i = 0; i < number; i++) {
    reservedPlayers.push(
        getReservedPlayer(Math.floor(Math.random() * (i + 1000))));
  }
  return reservedPlayers;
};

export const getPlayersWithNames = (players) => {
  const reservedPlayers = [];
  players.forEach((p) => reservedPlayers.push(getPlayerWithName(p)));
  return reservedPlayers;
};

export const getDefaultPlayers = (number) => {
  const defaultPlayers = [];
  for (let i = 0; i < number; i++) {
    defaultPlayers.push(
        getDefaultPlayer(Math.floor(Math.random() * (i + 1000))));
  }
  return defaultPlayers;
};

export const formatDate = (dateTime) => {
  return moment(dateTime, 'YYYY-MM-DD').format('YYYY-MM-DD');
};

export const formatDateCustom = (dateTime, format) => {
  return moment(dateTime, 'YYYY-MM-DD').format(format);
};

export const getShortDate = (dateTime) => {
  return moment(dateTime, 'YYYY-MM-DD').format('ddd, MMM D');
};

export const getSimpleDate = (date) => {
  const today = moment().format('YYYY-MM-DD');
  const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
  if (date === today) {
    return 'Today';
  } else if (date === tomorrow) {
    return 'Tomorrow';
  }
  return moment(date).format('dddd, MMMM Do');
};

export const formatTime = (dateTime) => {
  const date = new Date(dateTime.slice(0, -6));
  // console.log(date);
  const timeString = date.toLocaleTimeString(
      'en-sa',
      {hour: 'numeric', minute: 'numeric', hour12: true},
  );
  const displayTime = timeString.slice(0, -2);
  const period = timeString.substr(timeString.length - 2);
  return displayTime+period;
};

export const getRandomGameCover = (id) => {
  const index = id % gameCovers.length;
  return gameCovers[index];
};
