import React from 'react';
import PropTypes from 'prop-types';
import {Box, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {TournamentCard} from './TournamentCard';

export const TournamentCardsView = ({tournaments, joinable, onJoin}) => {
  const theme = useTheme();

  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        mt: 3,
        mb: 2,
        bgcolor: 'background.paper',
      }}
      display={'flex'}
      justifyContent={'center'}
      flexDirection={'column'}
      p={smallScreen? 0 : 3}
      alignSelf={smallScreen? 'center' : 'flex-start'}
      width={smallScreen? '100%' : '95%'}
    >
      {tournaments.map((tournament) =>
        <TournamentCard
          key={tournament.id}
          tournament={tournament}
          joinable={joinable}
          onJoin={onJoin}
        />,
      )}
    </Box>
  );
};

TournamentCardsView.propTypes = {
  tournaments: PropTypes.array,
};
