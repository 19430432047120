import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {Facebook, Instagram, Twitter} from '@mui/icons-material';
import {Box, Divider} from '@mui/material';

export default function Footer() {
  return (
    <Box
      // position={"absolute"}
      // bottom={0}
      // minWidth={'100%'}
      component="footer"
      sx={{
        backgroundColor: '#f5f5f7',
        p: 2,
        mt: 10,
      }}
    >
      <Box display={'flex'} justifyContent={'space-around'} my={1}>

        <Link href="https://www.facebook.com/forza90uk" color="inherit">
          <Facebook fontSize={'medium'}/>
        </Link>
        <Link
          href="https://www.instagram.com/forza90uk"
          color="inherit"
          sx={{px: 1}}
        >
          <Instagram fontSize={'medium'}/>
        </Link>
        <Link href="https://www.twitter.com/" color="inherit">
          <Twitter fontSize={'medium'}/>
        </Link>
      </Box>
      <Divider />
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-evenly'} mt={1}>
        <Typography
          variant={'h4'}
          onClick={() => {
            window.location.href = 'https://www.forza90.co.uk';
          }}
        >
                            🇬🇧
        </Typography>
        <Typography
          variant={'h4'}
          onClick={() => {
            window.location.href = 'https://www.forza90.com';
          }}
        >
                        🇸🇦
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography variant="body2" color="text.secondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href={`https://${process.env.REACT_APP_APP_URL}`}>
                            Forza90 Ltd
          </Link>
                        , All rights reserved
          {' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
    </Box>
  );
}
