import React, {useEffect, useState} from 'react';
import {
  Alert,
  Box, IconButton, Snackbar,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Button from '@mui/material/Button';
import validator from 'validator';
import {useTheme} from '@mui/material/styles';
import {AlertSnackBar} from '../game/AlertSnackBar';
import {
  cancelTeamInvite,
  fetchTeam,
  fetchTeamInvites,
  fetchTeamPlayers,
  inviteTeamPlayer,
  removeTeamPlayer,
} from '../../helpers';
import {useCookies} from 'react-cookie';
import {TeamPlayer} from './player/TeamPlayer';
import {RemoveTeamPlayerConfirmation} from './RemoveTeamPlayerConfirmation';
import {useTranslation} from 'react-i18next';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {TeamInvite} from './player/TeamInvite';

export const InvitePlayerComponent = ({teamId}) => {
  const {t} = useTranslation();
  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
  const [invitePlayerSnackBarOpen, setInvitePlayerSnackBarOpen] = useState(false);
  const [invalidInput, setInvalidInput] = useState(false);
  const [email, setEmail] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const authToken = cookies.auth;
  const [players, setPlayers] = useState([]);
  const [invites, setInvites] = useState([]);
  const [inviteUrl, setInviteUrl] = useState('');
  const [linkCopiedSnackBarOpen, setLinkCopiedSnackBarOpen] = useState(false);

  const [removePlayerConfirmationOpen, setRemovePlayerConfirmationOpen] = useState(false);
  const [removePlayerSnackBarOpen, setRemovePlayerSnackBarOpen] = useState(false);
  const [playerToRemove, setPlayerToRemove] = useState(null);

  const updateTeam = async () => {
    try {
      const team = await fetchTeam(teamId, authToken);
      setInviteUrl(`${process.env.REACT_APP_APP_URL}/teams/${teamId}/join?token=${team.token}`);
      const playerIds = await fetchTeamPlayers(teamId);
      setPlayers(playerIds);
      const teamInvites = await fetchTeamInvites(teamId, authToken);
      setInvites(teamInvites);
    } catch (e) {
      console.error(e);
    }
  };


  useEffect(()=> {
    updateTeam();
  }, []);

  const invitePlayer = async (player) => {
    try {
      const response = await inviteTeamPlayer(teamId, {email: player.email}, authToken);
      await updateTeam();
      setInvitePlayerSnackBarOpen(true);
      setEmail('');
      return response;
    } catch (e) {
      console.error(e);
      setErrorSnackBarOpen(true);
    }
  };

  const cancelInvite = async (inviteId) => {
    try {
      const response = await cancelTeamInvite(teamId, inviteId, authToken);
      await updateTeam();
      // setInvitePlayerSnackBarOpen(true);
      // setEmail('');
      return response;
    } catch (e) {
      console.error(e);
      setErrorSnackBarOpen(true);
    }
  };


  const removePlayer = async (userId) => {
    try {
      const response = await removeTeamPlayer(teamId, userId, authToken);
      setRemovePlayerConfirmationOpen(false);
      setRemovePlayerSnackBarOpen(true);
      setPlayerToRemove(null);
      await updateTeam();
      // setInvitePlayerSnackBarOpen(true);
      // setEmail('');
      return response;
    } catch (e) {
      console.error(e);
      setErrorSnackBarOpen(true);
    }
  };

  const reInvitePlayer = async (inviteId, email) => {
    try {
      await cancelInvite(inviteId);
      await invitePlayer({email: email});
      await updateTeam();
      setInvitePlayerSnackBarOpen(true);
    } catch (e) {
      console.error(e);
      setErrorSnackBarOpen(true);
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setInvalidInput(false);
    setErrorSnackBarOpen(false);
    if (!validateInput()) {
      setInvalidInput(true);
      setErrorSnackBarOpen(true);
      return;
    }
    const player = {
      email: email,
    };

    invitePlayer(player);
  };

  const validateInput = () => {
    if (!validator.isEmail(email)) {
      return false;
    }
    return true;
  };

  const onRemovePlayer = (userId, name) => {
    setPlayerToRemove({userId, username: name});
    setRemovePlayerConfirmationOpen(true);
  };

  const closeRemovePlayerConfirmation = () => {
    setRemovePlayerConfirmationOpen(false);
  };

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{mt: 1}}
      component={'form'}
      onSubmit={onSubmit}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'background.paper',
          borderColor: 'border.light',
          paddingY: '25px',
          px: '10px',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Box>
          <Typography variant={'h6'}>
            {t('teamCreateFlow.sendInviteHeader')}
          </Typography>
          <Typography variant={'body2'}>
            {t('teamCreateFlow.sendInviteBody')}
          </Typography>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('common.email')}
              name="email"
              type="text"
              error={invalidInput}
              value={email}
              onChange={(event)=> setEmail(event.target.value)}
              helperText={invalidInput ? t('errors.invalidEmail') : ''}

            />
            <Button
              ml={2}
              sx={{width: 120, height: 40, ml: 1}}
              autoFocus
              color={'success'}
              type={'submit'}
              variant={'contained'}
            >
              {t('common.invite')}
            </Button>
          </Box>
        </Box>
        <Box mt={2}>
          <Typography variant={'h6'}>{t('teamCreateFlow.shareLink')}</Typography>
          <Box display={'flex'} flexDirection={'row'}>
            <Box sx={{border: '2px dotted grey'}} p={0.5}>
              <Typography variant={'body2'}> {inviteUrl} </Typography>
            </Box>
            <IconButton
              onClick={()=> {
                setLinkCopiedSnackBarOpen(true);
                navigator.clipboard.writeText(inviteUrl);
              }}
            >
              <ContentCopyIcon/>
            </IconButton>

          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'background.paper',
          borderColor: 'border.light',
          padding: '30px',
          width: '100%',
          boxSizing: 'border-box',
        }}
        borderRadius="6px"
        borderTop={1}
      >
        <Typography
          variant={smallScreen?'h5': 'h4'}
          m={smallScreen? 2 : 6}
          alignSelf={'center'}
        >
          {t('teams.players')}
        </Typography>
        {players.map((playerUserId) =>
          <TeamPlayer
            key={playerUserId}
            editable={true}
            onDelete={onRemovePlayer}
            userId={playerUserId}
          />,
        )}
        {players.length === 0 && (
          <Typography>{t('teamCreateFlow.noPlayers')} </Typography>
        )}

        <Box mt={2}>
          <Typography variant={'h6'} mb={1}>
            {t('teamInvite.pending')}
          </Typography>
          {invites
              .filter((invite) => invite.status === 'pending')
              .map((invite) => (
                <TeamInvite
                  key={invite.id}
                  name={invite.email}
                  onDelete={() => cancelInvite(invite.id)}
                  onResend={() => reInvitePlayer(invite.id, invite.email)}
                />
              ))}

          {invites
              .filter((invite) =>
                invite.status === 'pending').length === 0 &&
          (
            <Typography>{t('teamCreateFlow.noInvites')}</Typography>
          )}
        </Box>
      </Box>
      <AlertSnackBar
        message={invalidInput ?
            t('errors.addressErrorsMessage'):
            t('errors.genericErrorMessage')}
        severity={'error'}
        open={errorSnackBarOpen}
        onClose={() => setErrorSnackBarOpen(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHide={false}
        sx={{marginTop: smallScreen? 0: '50px'}}
      />
      <AlertSnackBar
        message={t('alerts.inviteSent')}
        severity={'success'}
        open={invitePlayerSnackBarOpen}
        onClose={() => setInvitePlayerSnackBarOpen(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHide={false}
        sx={{marginTop: smallScreen? 0: '50px'}}
      />

      {playerToRemove && (
        <RemoveTeamPlayerConfirmation
          open={removePlayerConfirmationOpen}
          onClose={closeRemovePlayerConfirmation}
          onConfirm={removePlayer}
          playerToRemove={playerToRemove}
        />
      )}
      <Snackbar
        open={removePlayerSnackBarOpen}
        autoHideDuration={6000}
        onClose={() => setRemovePlayerSnackBarOpen(false)}
      >
        <Alert
          onClose={() => setRemovePlayerSnackBarOpen(false)}
          severity="warning"
          sx={{width: '100%'}}
        >
          {t('alerts.playerRemoved')}
        </Alert>
      </Snackbar>

      <AlertSnackBar
        message={t('alerts.linkCopied')}
        severity={'success'}
        open={linkCopiedSnackBarOpen}
        onClose={() => setLinkCopiedSnackBarOpen(false)}
        autoHide
      />
    </Box>
  );
};
