import React, {useEffect, useState} from 'react';
import {ListItem, ListItemText, Skeleton} from '@mui/material';
import {fetchTeam} from '../../../helpers';

const emojis = ['🔥', '😮‍💨', '🎯', '🦾', '✨', '🌟', '😎', '🥶', '🫡',
  '😴', '💀', '☠️', '🦿', '👀', '🧠', '🥷', '💥', '⚡️', '💫', '🌪️', '🚀', '⏳', '❤️‍🔥', '🚫',
];

export const ListItemTeam = ({id}) => {
  const [name, setName] = useState(null);

  const updateTeam = async () => {
    try {
      const team = await fetchTeam(id, null);
      setName(team.name);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(()=> {
    updateTeam();
  }, []);

  return (
    <ListItem disablePadding>
      {name?
                <ListItemText
                  primaryTypographyProps={{fontSize: '18px'}}
                  primary={`${emojis[id % emojis.length]} ${name}`}
                /> :
                <Skeleton width={20}/>
      }

    </ListItem>
  );
};
