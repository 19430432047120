import React from 'react';
import {ImageComponent} from '../ImageComponent';
import {Typography, useMediaQuery} from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import Background1 from '../../assets/backgrounds/background1.jpeg';
import Background2 from '../../assets/backgrounds/background2.jpeg';
import Background3 from '../../assets/backgrounds/background3.jpeg';
import Background4 from '../../assets/backgrounds/background4.png';
import {useTheme} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';

const images = [Background1, Background2, Background3, Background4];

export const GamesPageCarousel = () => {
  return (
    <Carousel indicators={false} duration={1000}>
      {images.map((image, index) => (
        <ImageComponent
          key={index}
          image={image}
          style={{
            minHeight: '30vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
        </ImageComponent>
      ))}
    </Carousel>
  );
};
