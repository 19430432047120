import React, {useState} from 'react';
import {TextField} from '@mui/material';
import PropTypes from 'prop-types';
import validator from 'validator';
import {useTranslation} from 'react-i18next';

export const EmailTextField = ({onChange, value, emailExists, margin}) => {
  const {t} = useTranslation();
  const [valid, setValid] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);

  const onEmailChange = (event) => {
    const newValue = event.target.value;
    validateEmail(newValue);
    setEmailChanged(true);
    onChange(newValue);
  };

  const validateEmail = (email) => {
    setValid(validator.isEmail(email));
  };

  return (
    <TextField
      margin={margin || 'none'}
      required
      fullWidth
      id="email"
      label={t('common.emailAddress')}
      name="email"
      autoComplete="email"
      variant="outlined"
      onChange={onEmailChange}
      value={value}
      error={emailChanged && !valid || emailExists}
      helperText={emailExists? t('errors.emailExists') :
                emailChanged && !valid? t('errors.incorrectEmail'): ''}
    />
  );
};

EmailTextField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  emailExists: PropTypes.bool,
  margin: PropTypes.string,
};
