import React from 'react';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';

export const ImageComponent = (props)=> {
  return (
    <Box
      sx={{
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${props.image})`,
        borderColor: 'border.light',
      }}
      style={{...props.style}}
      borderRadius="6px"
      border={1}
    >
      {props.children}
    </Box>
  );
};
ImageComponent.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  image: PropTypes.string,
};
